import React, {useContext, useEffect, useState} from 'react'
import {IBatch} from '../../../api/IRecipe'
import {useParams} from 'react-router'
import {deleteBatch, getBatch} from '../../../api/api'
import IngredientList from '../../ingredient/list/IngredientList'
import UserContext from '../../../hooks/UserContext'
import ActionBar from "../../../components/ActionBar";
import {MY_BATCHES} from "../../../routes";
import BatchWithRecipes from "../components/BatchWithRecipes";
import ExecutionCard from "../execution/ExecutionCard";
import {Box, Container, Tabs, Tab, Grid} from "@mui/material";
import {PageHeader} from "../../../components/PageHeader";
import {useNavigate} from "react-router-dom";

const BatchDisplayPage = (): JSX.Element => {
  const {user} = useContext(UserContext)
  const { batchId } = useParams();
  const [batch, setBatch] = useState<IBatch>()
  const [error, setError] = useState(null)
  const days = batch && batch.recipesByDay ? Object.keys(batch.recipesByDay) : null
  const batchLabel = batch && batch.recipesByDay ? `Batch du ${batch?.recipesByDay[days[0]].day} - ${batch?.recipesByDay[days[days.length -1]].day}` : ''
  const navigate = useNavigate()
  const [tabIndex, setTabIndex] = useState(0);
  
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };
  
  
  const breads = [
    {
      href: MY_BATCHES,
      children: "Mes batchs"
    },
    {
      href: '',
      children: batchLabel
    }
  ]
  
  const onDelete = async (id: string) => {
    try {
      await deleteBatch(id)
    } catch (err) {
      setError(err)
    }
  }
  
  useEffect( () => {
    const fetch = async() => {
      try {
        const fetchedRecipe = await getBatch(batchId)
        setBatch(fetchedRecipe)
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      } catch (err) {
        setError(err)
      }
    }
    fetch()
  }, [batchId]);

  return (
    <Container sx={{marginBottom: '2rem'}}>
        <ActionBar
          breadcrumbs={breads}
          error={error}
          action={
            (user.roles.includes('creator') || user.roles.includes('apprentice')) ? (
              <div className={"ms-auto text-end"}>
                <PageHeader.Actions>
                  <PageHeader.ActionItem
                    variant="contained"
                    onClick={() => {window.print()}}>
                    Imprimer
                  </PageHeader.ActionItem>
                </PageHeader.Actions>
              </div>) : (
              <div className={"ms-auto text-end"}>
              </div>
            )
          }
        />
        <Box marginTop={1} maxWidth={'lg'}>
          {!error && (
            <>
              <Box>
                <Tabs value={tabIndex} onChange={handleTabChange}>
                  <Tab label="Le menu" />
                  <Tab label="La feuille de marché" />
                  <Tab label="Exécution" />
                </Tabs>
              </Box>
              <Box sx={{ padding: 2 }}>
                {tabIndex === 0 && (
                  <BatchWithRecipes batch={batch} />
                )}
                {tabIndex === 1 && (
                  <Box>
                    {batch && batch.ingredients && <IngredientList ingredients={batch.ingredients} />}
                  </Box>
                )}
                {tabIndex === 2 && (
                  <Box>
                    {batch && batch.id && <ExecutionCard batchId={batch.id} setError={setError}/>}
                  </Box>
                )}
              </Box>
            </>
          )}
        </Box>
      </Container>
  )
}

export default BatchDisplayPage
