import React from 'react'
import Course from '../../images/hero/courses-emul.png'
import Batch from '../../images/hero/batch-emul.png'
import Recette from '../../images/hero/recette-emul.png'
import {useNavigate} from "react-router-dom";
import {START_BATCH} from "../../routes";
import Section from "./Section";
import {Box, Typography, useTheme} from "@mui/material";
import Button from "@mui/material/Button";
import {IBatch} from "../../api/IRecipe";


const Services = ({batch}:{batch: IBatch}): JSX.Element => {
  const navigate = useNavigate()
  const theme = useTheme()
  return (
    <Section
      title={"Comment ça marche?"}
      subtitle={"Simple comme trois clicks"}
     // containerStyle={{backgroundColor: `${theme.palette.mode === "light" ? lighten(theme.palette.secondary.light, 0.7) : darken(theme.palette.secondary.dark, 0.7)}`}}
    >
      <Box className={"m-auto h-100 row align-items-center justify-content-end"}>
        <div className={"col-12 col-lg-7 align-self-baseline mb-4 d-flex d-lg-block"}>
          <p className="step-number">1</p>
          <Typography sx={{fontSize: '1rem'}} className="mt-lg-5">
            Je crée un batch soit en choisissant le batch suggéré par le chef Cédric soit en customisant mon batch.
            Chaque semaine, Cédric propose un batch de saison. <br/><br/>
            Je peux opter pour ce batch et si une recette ne me plait pas, je peux toujours changer cette recette.<br/><br/>
            J'ajoute autant de recettes que je veux.<br/><br/>
            Je peux aussi crée mon batch à partir de la liste des recettes. C'est moi le chef!
          </Typography>

        </div>
        <div className={"col-12 col-lg-5 align-self-end mt-lg-5 d-flex"}>
          <img className="m-auto ms-lg-auto" src={Batch}/>
        </div>
      </Box>

      <Box className={" m-auto h-100 row align-items-center"}>
        <div className={"order-2 order-lg-1 col-12 col-lg-5 align-self-end mt-lg-5 d-flex"}>
          <img className="m-auto me-lg-auto" src={Recette}/>
        </div>
        <div className={"order-1 order-lg-2 col-12 col-lg-7 align-self-baseline my-4  d-flex d-lg-block"}>
          <p className="step-number">2</p>
          <Typography sx={{fontSize: '1rem'}} className="mt-lg-5">{`J'adapte mon menu en choisissant parmi un large choix de recettes. Je plannifie ma date de début de batch.`}</Typography>
        </div>
      </Box>

      <Box className={" m-auto h-100 row align-items-center justify-content-end"}>
        <div className={"col-12 col-lg-7 align-self-baseline my-4 d-flex d-lg-block"}>
          <p className="step-number">3</p>
          <Typography sx={{fontSize: '1rem'}} className="mt-lg-5">
            {`Cuis!nons me propose une feuille de marché et je suis prét.e pour "batcher". 1, 2, 3... GO!`}
          </Typography>
          <div className={"d-none d-lg-block"}>
            <Button
              sx={{ height: 'fit-content', marginTop: '2rem'}}
              color="warning"
              size="large"
              variant={"contained"}
              onClick={() => {navigate(START_BATCH, {state: {suggestedBatch: batch}})}}
            >Je&nbsp;commence&nbsp;le&nbsp;batch!
            </Button>
          </div>
        </div>
        <div className={"col-12 col-lg-5 align-self-end mt-lg-5 d-flex"}>
          <img className="m-auto ms-lg-auto" src={Course}/>
        </div>
      </Box>

    </Section>
  )
}
export default Services;
