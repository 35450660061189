import {useNavigate} from "react-router-dom";
import {CREATE_SUGGESTED_BATCH, START_BATCH} from "../../../routes";
import {Button, Card, CardActions, CardContent, CardHeader, Container, Typography} from "@mui/material";
import React from "react";

export const EmptySuggestedBatch = () => {
  const navigate = useNavigate()
  const navigateToWizard = () => {navigate(CREATE_SUGGESTED_BATCH, {state:{isSuggestedBatch: true}})}

  return (
    <Container sx={{marginBottom: '2rem', marginTop:'1rem',textAlign: 'center'}}>
      <Card >
        <CardHeader title={"Cédric! Au boulot!"} subheader={"Il n'y a pas de suggestion de Batch"} />
        <CardContent>
          <Typography>
            {"Le chef est en réflexion."}
            <br/>
            {"Il réfléchit à de nouveaux batchs."}
          </Typography>
        
        </CardContent>
        <CardActions sx={{textAlign: 'center', justifyContent: 'space-evenly'}}>
          <Button onClick={navigateToWizard} variant="contained">1, 2, 3... Go!</Button>
        </CardActions>
      </Card>
    </Container>
  )
}
