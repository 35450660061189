import {IRecipe} from '../../../api/IRecipe'
import createUUID from '../../../utils/uuid'

const emptyRecipe = {
  "name": "",
  "image": [],
  "author": {
    "name": "Cédric Duranti",
  },
  "description": "",
  "prepTime": "PT20M",
  "cookTime": "PT10M",
  "totalTime": "PT30M",
  "recipeYield": 4,
  "conservationTime": "PT3D",
  "recipeCategory": "plat chaud",
  "recipeCuisine": "française",
  "keywords": "",
  "educationalLevel" : {
    "name": "débutant",
  },
  "tool": [{
    "id": createUUID(),
    "name": "",
  }],
  "recipeIngredients": [{
    "id": createUUID(),
    "name": "",
  }],
  "recipeInstructions": [{
    "id": createUUID(),
    "name": "",
    "meta": [],
    "when": "batch"
  }]
} as unknown as IRecipe

export default emptyRecipe
