import unitOptions from './Unit'
import React, {useEffect} from 'react'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import {INameQuantified} from '../../../api/IRecipe'

interface IIngredientDropdown {
  idx: number
  ingredient: INameQuantified
  optionsIngredient: {label: string, value: string, defaultUnit: string}[]
  handleChangeIngredient: (idx: number, newValue: {label: string, value: string}) => void
  handleCreateIngredient: (idx: number, v: string) => void
  onSelectionChange: (idx: number, v: {value: string, label: string}) => void
}
const IngredientDropdown = ({
  idx,
  ingredient,
  optionsIngredient,
  handleChangeIngredient,
  handleCreateIngredient,
  onSelectionChange
}: IIngredientDropdown): JSX.Element => {
  
  useEffect(() => {
    const unitDefaulted = optionsIngredient.find(o => o.label === ingredient.name)?.defaultUnit
    if (!ingredient.unit || ingredient.unit === "") {
      onSelectionChange(idx, {value: unitDefaulted, label: unitDefaulted})
    }
    
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
   [ingredient.name])
  
  return (<>
    <div className="me-2 w-75 text-muted">
      <CreatableSelect
        isDisabled={!!ingredient.baseRecipe}
        placeholder={"Ingrédient..."}
        onChange={(v) => handleChangeIngredient(idx, v)}
        onCreateOption={(v) => handleCreateIngredient(idx, v)}
        options={optionsIngredient}
        formatCreateLabel={(v) => `Créer ${v}`}
        value={
          {
            label: ingredient.name,
            value: ingredient.name.toLowerCase().replace(/\W/g, ''),
          }
        }
      />
    </div>
    <div className="me-2 w-50 text-muted">
      <Select
        isDisabled={!!ingredient.baseRecipe}
        placeholder={"Unité..."}
        value={
          {
            label: ingredient.unit,
            value: ingredient.unit
          }
        }
        onChange={(v) => onSelectionChange(idx, v)}
        options={unitOptions}
      />
    </div>
  </>)
}
export default IngredientDropdown
