import { TextFieldProps } from '@mui/material'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import FormHelperText from '@mui/material/FormHelperText'
import TextField from '@mui/material/TextField'
import { Form as FormikForm, Formik, useField } from 'formik'

import { IFormWrapperProps } from './types'

const FormikWrapper = ({ children }: IFormWrapperProps) => {
  return (
    <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
      {children}
    </Box>
  )
}

const FormikTextField = (props: TextFieldProps) => {
  const [field, meta] = useField(props.id as string)
  return (
    <TextField
      fullWidth
      {...field}
      {...props}
      error={meta.touched && meta.error !== undefined}
      helperText={meta.touched && meta.error ? meta.error : undefined}
    />
  )
}

const FormikCheckbox = (props: any) => {
  const [field, meta] = useField(props)
  return (
    <FormGroup>
      <FormControlLabel control={<Checkbox {...field} />} name={field.name} label={props.label} />
      {meta.touched && meta.error ? <FormHelperText error>{meta.error}</FormHelperText> : null}
    </FormGroup>
  )
}

FormikWrapper.displayName = 'FormWrapper'
FormikTextField.displayName = 'TextField'
FormikCheckbox.displayName = 'Checkbox'

const Form = {
  Context: Formik,
  Form: FormikForm,
  Wrapper: FormikWrapper,
  TextField: FormikTextField,
  Checkbox: FormikCheckbox,
}

export { Form }
