import React from "react";
import {IBatch} from "../../../api/IRecipe";

const BatchWithRecipes = ({batch}:{batch:IBatch}) => {
  return(
    <span>
      {batch && batch.recipesByDay && Object.keys(batch.recipesByDay).map((d,i) => {
        return (
          <span key={i}>
              {batch && batch.recipesByDay[d].recipeIds?.map((r, idx) => {
                return (
                  <span key={`${batch.recipes[r].id}-${idx}`}>
                    <span>{batch.recipes[r].name}, </span>
                  </span>
                )
              })}
          </span>
        )})}
    </span>
  )
}
export default BatchWithRecipes
