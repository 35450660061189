import React, {useContext, useEffect, useState} from 'react'
import {IRecipe} from '../../../api/IRecipe'
import {useParams} from 'react-router'
import {getRecipe, getBaseRecipe, publishRecipe, unPublishRecipe} from '../../../api/api'
import RecipeView from './RecipeView'
import UserContext from '../../../hooks/UserContext'
import {useLocation, useNavigate} from "react-router-dom";
import {BASE_RECIPES, RECIPES} from "../../../routes";
import {Box, capitalize, Container} from "@mui/material";
import ActionBar from "../../../components/ActionBar";
import {PageHeader} from "../../../components/PageHeader";

interface IRecipeViewPageProps {
  base?: boolean
}
const RecipeViewPage = ({base = false}: IRecipeViewPageProps): JSX.Element => {
  const { recipeId } = useParams();
  const navigate = useNavigate();
  const [recipe, setRecipe] = useState<IRecipe>()
  const [error, setError] = useState(null)
  const {user} = useContext(UserContext)
  const loc = useLocation()
  const onPublish = async (r: IRecipe) => {
    const status = r.status === 'published' ? unPublishRecipe : publishRecipe
    try {
      await status(r.id)
      setRecipe({...recipe, status: r?.status === "published" ? "draft":"published"})
    } catch (err) {
      setError(err)
    }
  }
  const getFn = base ? getBaseRecipe : getRecipe
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, [])
  useEffect( () => {
      const fetch = async() => {
        try {
          const fetchedRecipe = await getFn(recipeId)
          setRecipe(fetchedRecipe)
        } catch (err) {
          setError(err)
        }
      }
      fetch()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [recipeId, base]);
  
  if (!recipe && error) {
    return (
      <Container className="mt-4">
        {/*<Alert variant={"danger"}>*/}
        Il y a une erreur 🤭: {error?.message}.
        {/*</Alert>*/}
      </Container>)
  }
  const RECIPE_URL = base ? BASE_RECIPES : RECIPES
  let breads = [
    {
      href: RECIPE_URL,
      children: base ? 'Les recettes de base' : 'Les recettes'
    },
    {
      href: '',
      children: recipe?.name
    }
  ];
  if (loc.state && loc.state['breadcrumbs']) {
    breads = loc.state['breadcrumbs']
  }
  
  return (
    <Container>
      <ActionBar
        breadcrumbs={breads}
        error={error}
        isLoading={!recipe}
        title={recipe?.name && capitalize(recipe?.name)}
        subtitle={recipe?.author?.name}
        action={
          (user.roles.includes('creator') || user.roles.includes('apprentice')) ? (
            <Box sx={{marginLeft: 'auto', textAlign: 'right'}}>
              <PageHeader.Actions>
                <PageHeader.ActionItem
                  onClick={() => {window.print()}}
                  variant="text">
                  Imprimer
                </PageHeader.ActionItem>
                {!base && <PageHeader.ActionItem
                  onClick={() => onPublish(recipe)}
                  variant="outlined">
                  {recipe?.status === "published" ? "Mettre en draft" : "Publier"}
                </PageHeader.ActionItem>}
                <PageHeader.ActionItem
                  variant="contained"
                  onClick={() => navigate(`${RECIPE_URL}/${recipe?.id}/edit`)}>
                  Editer
                </PageHeader.ActionItem>
              </PageHeader.Actions>
            </Box>
          ) : (
            <Box sx={{marginLeft: 'auto', textAlign: 'right'}}>
              <PageHeader.Actions><PageHeader.ActionItem
                onClick={() => {window.print()}}
                variant="text">
                Imprimer
              </PageHeader.ActionItem></PageHeader.Actions>
            </Box>
          )
        }
      />
      <RecipeView recipe={recipe}/>
    </Container>
  );
}

export default RecipeViewPage
