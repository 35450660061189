import React, {ChangeEvent, useEffect, useState} from 'react'
import {Box, Button, Grid, IconButton, TextField} from "@mui/material";
import {FieldArray, FormikValues, FormikErrors} from "formik";
import YieldComponent from "./YieldComponent";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";


import {Form, FormControl, FormGroup} from 'react-bootstrap'

import {INameQuantified, IRecipe} from '../../../api/IRecipe'
import CreatableSelect from 'react-select/creatable'
import {getIngredient} from '../../../api/api'
import IngredientDropdown from './IngredientDropdown'

interface IRecipeFormIngredientProps {
  values: FormikValues
  errors: FormikErrors<any>
  base: boolean
  setFieldValue: any
}
const RecipeFormIngredient = (
  {values, base, errors, setFieldValue}:IRecipeFormIngredientProps
): JSX.Element => {
  
  const onYieldChange = (values, add: boolean) => {
    let increment = 1
    if(!add) {
      increment = -1
    }
    setFieldValue('recipeYield', values.recipeYield + increment)
  }
  
  
  const createOption = (label: string) => ({
    label,
    value: label.toLowerCase().replace(/\W/g, ''),
  });
  const createOptionIngredientList = (label: string) => ({
    label,
    value: label.toLowerCase().replace(/\W/g, ''),
    defaultUnit: ''
  });
  
  const defaultOptionsFor = [
    {value: 'n/a', label: 'n/a'}
  ];
  
  const initial = (s: INameQuantified[]): INameQuantified[] => {
    if (!s || s.length === 0) {
      return [{
        'name': '',
        'unit': '',
        'quantity': 0,
        'for' : 'n/a',
        'notForNutrient': false
      } as INameQuantified]
    }
    return s
  }
  
  const [ingredients, setIngredients] = useState<INameQuantified[]>(initial(values.recipeIngredients))
  const [optionsFor, setOptionsFor] = useState(defaultOptionsFor)
  const [previousOption, setPreviousOption] = useState({
    label: 'n/a',
    value: 'n/a'
  })
  
  const setIngredientsWrapper = (ingredients: INameQuantified[]) => {
    setIngredients(ingredients)
    setFieldValue('recipeIngredients', ingredients)
  }
  const [optionsIngredient, setOptionsIngredient] = useState(ingredients.map(i => {
    return {
      label:i.name,
      value: i.name.toLowerCase().replace(/\W/g, ''),
      defaultUnit: ''
    }
  }))
  useEffect(() => {
    const fetchIngredients = async() => {
      try {
        const ingredientsList = await getIngredient()
        console.log(`there are ${ingredientsList?.length} ingredients from server`)
        const options = ingredientsList.map(i => {
          return {
            label:i.name,
            value: i.name.toLowerCase().replace(/\W/g, ''),
            defaultUnit: i.defaultUnit
          }
        })
        setOptionsIngredient(options)
      } catch (error) {
        console.log(`an error ${error}`)
      }
    }
    fetchIngredients()
  }, [])
  
  useEffect(() => {
    const forIng = [...new Set(values.recipeIngredients.filter(i => i.for && i.for !== 'n/a' && i.for !== '').map(i => i.for))]
    const initialOptions = forIng.map(i => createOption(i as string))
    initialOptions.push({label: 'n/a', value: 'n/a'})
    setOptionsFor(initialOptions)
  }, [values.recipeIngredients])
  
  const handleChange = (idx: number, newValue: {label: string, value: string}) => {
    ingredients[idx].for = newValue.label
    setIngredientsWrapper([...ingredients.slice(0, idx), ingredients[idx], ...ingredients.slice(idx+1)])
    setPreviousOption({label: newValue.label, value: newValue.label})
  }
  const handleCreate = (idx: number, inputValue: string) => {
    const newOption = createOption(inputValue);
    setOptionsFor([...optionsFor, newOption])
    ingredients[idx].for = inputValue
    const newV = [...ingredients.slice(0, idx), ingredients[idx], ...ingredients.slice(idx+1)]
    setIngredientsWrapper(newV)
    setPreviousOption({...newOption})
  }
  
  const handleChangeIngredient = (idx: number, newValue: {label: string, value: string}) => {
    ingredients[idx].name = newValue.label
    ingredients[idx].for = previousOption?.label
    setIngredientsWrapper([...ingredients.slice(0, idx), ingredients[idx], ...ingredients.slice(idx+1)])
  }
  const handleCreateIngredient = (idx: number, inputValue: string) => {
    const newOption = createOptionIngredientList(inputValue);
    setOptionsIngredient([...optionsIngredient, newOption])
    ingredients[idx].name = inputValue
    setIngredientsWrapper([...ingredients.slice(0, idx), ingredients[idx], ...ingredients.slice(idx+1)])
  }
  
  const onSelectionChange = (idx, v) => {
    ingredients[idx].unit = v.label
    setIngredientsWrapper([...ingredients.slice(0, idx), ingredients[idx], ...ingredients.slice(idx+1)])
  }
  const onNotForNutrientChange = (idx) => {
    ingredients[idx].notForNutrient = !ingredients[idx].notForNutrient
    setIngredientsWrapper([...ingredients.slice(0, idx), ingredients[idx], ...ingredients.slice(idx+1)])
  }
  const onQuantityChange = (idx, v: string) => {
    ingredients[idx].quantity = v
    setIngredientsWrapper([...ingredients.slice(0, idx), ingredients[idx], ...ingredients.slice(idx+1)])
  }
  const onAdd = () => {
    setIngredientsWrapper([...ingredients, {name: '', unit: '', quantity: 0}])
  }
  const onDelete = (idx) => {
    setIngredientsWrapper([...ingredients.slice(0, idx), ...ingredients.slice(idx+1)] || [{
      'name': '',
      'unit': '',
      for: 'n/a',
      quantity: 0
    } as INameQuantified])
  }

  return (
    <>
      {ingredients && ingredients.length > 0 && (<FieldArray
      name="recipeIngredients"
      render={arrayHelpers => (
        <Grid item>
          <YieldComponent
            count={values.recipeYield}
            label="personnes"
            onAdd={() => onYieldChange(values,true)}
            onDelete={() => onYieldChange(values,false)} />
          {ingredients.map((ingredient, idx) => (
            <FormGroup key={`ingredient-${idx}`} className="mt-2 d-flex w-100" controlId="suggestion">
              <div className={"d-flex w-100 flex-column"}>
                <div className="d-flex mb-2 w-100 align-items-center" >
                  <IngredientDropdown
                    idx={idx}
                    ingredient={ingredient}
                    optionsIngredient={optionsIngredient}
                    handleChangeIngredient={handleChangeIngredient}
                    handleCreateIngredient={handleCreateIngredient}
                    onSelectionChange={onSelectionChange} />
                  <FormControl
                    className={"me-2 w-25"}
                    value={ingredient.quantity}
                    disabled={!!ingredient.baseRecipe}
                    name="quantity"
                    onChange={(props:ChangeEvent<HTMLInputElement>) => {
                      onQuantityChange(idx, props.target.value)
                    }}
                  />
                </div>
                <div className="d-flex mb-2 w-100 align-items-center" >
                  <Form.Check
                    disabled={!!ingredient.baseRecipe}
                    className={"ms-auto"}
                    checked={ingredients[idx]?.notForNutrient}
                    onClick={() => onNotForNutrientChange(idx)}
                    inline
                    label="ignoré"
                    type="checkbox"
                    id={`ignore-${idx}`} />
                  <div className="me-2 w-50 text-muted">
                    <CreatableSelect
                      isDisabled={!!ingredient.baseRecipe}
                      id={idx}
                      placeholder={"Pour..."}
                      onChange={(v) => handleChange(idx, v)}
                      onCreateOption={(v) => handleCreate(idx, v)}
                      options={optionsFor}
                      formatCreateLabel={(v) => `Créer une catégorie ${v}`}
                      value={
                        ingredient.for ? createOption(ingredient.for) : (ingredient.name ? createOption('n/a') : {...previousOption})
                      }
                    />
                  </div>
        
                </div>
              </div>
              
              <IconButton sx={{maxHeight: '35px'}} color="primary" onClick={() => onDelete(idx)} >
                <DeleteOutlineIcon sx={{fontSize: '1.2rem', height: '20px'}}/>
              </IconButton>
              <IconButton sx={{maxHeight: '35px'}}  color="primary" onClick={onAdd}>
                <AddCircleOutlineIcon sx={{fontSize: '1.2rem'}}/>
              </IconButton>
            </FormGroup>
          ))}
        </Grid>
      )}
    />)}
      {!ingredients || ingredients.length === 0 && (
        <Box sx={{marginLeft: 'auto', textAlign: 'right', marginRight: '40px'}}>
          <Button
            variant="outlined"
            startIcon={<AddCircleOutlineIcon />}
            onClick={onAdd}
            color="primary" aria-label="add">
            Ajouter un ingrédient
          </Button>
        </Box>
      )}
    </>
  )
}
export default RecipeFormIngredient
