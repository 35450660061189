import { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';

import {Box} from '@mui/material';
import BaseLayoutHeaderMenu from "../SidebarLayout/Header/BaseLayoutHeaderMenu";
import Footer from "../../components/footer/Footer";

interface BaseLayoutProps {
  children?: ReactNode;
}

const BaseLayout: FC<BaseLayoutProps> = ({ children }) => {
  return (
    <>
      <Box>
        <BaseLayoutHeaderMenu />
        <Box
          sx={{
            position: 'relative',
            zIndex: 5,
            display: 'block',
            flex: 1,
          }}
        >
          <Box display="block">
            {children || <Outlet />}
          </Box>
          <Footer />
        </Box>
      </Box>
    </>
  );
};

BaseLayout.propTypes = {
  children: PropTypes.node
};

export default BaseLayout;
