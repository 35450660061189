import React, {useContext} from "react";
import UserContext from "../../hooks/UserContext";
import {useNavigate} from "react-router-dom";
import {Alert, AlertTitle, Button, Grid} from "@mui/material";
import {LOGIN} from "../../routes";

export const NotLoggedInWarning = () => {
  const {user} = useContext(UserContext)
  const navigate =useNavigate()
  return (<>
    {!user || user.roles.includes("public") && (
      <Grid container sx={{marginY: '2rem'}}>
        <Alert severity="error">
          <AlertTitle>Cher utilisateur</AlertTitle>
          Afin de voir toutes les instructions de la recette, veuillez vous connecter.
          Notre service est <strong>gratuit</strong> mais vous avez besoin d'être identifié.
          <br/>
          <Button sx={{marginY: '2rem'}} color="primary" variant={"contained"} onClick={() => navigate(LOGIN)}>Se Connecter</Button>
        </Alert>
      </Grid>
    )}
  </>)
}
