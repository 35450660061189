import { Box } from '@mui/material';

const Logo = (props) => {
  return (
    <Box component={'a'} href="/">
      <img
        alt="Logo cuisinons"
        src={props.light?"/static/images/logo/cuisinons.png":"/static/images/logo/cuisinons_121212.png"}
      />
    </Box>
  );
}

export default Logo;
