import {Grid, Skeleton} from "@mui/material";
import React from "react";

export const RecipeViewSkeleton = () => {
  let skeletons = []
  for(let i=0;i<20;i=i+1) {
    skeletons.push(<Skeleton variant="text" key={i} sx={{ fontSize: '1rem' }} width={(i % 3) ? ((i % 2) ?"30vw" : "20vw"):"15vw"}/>)
  }
  let skeletonsIngredients = []
  for(let i=0;i<20;i=i+1) {
    skeletonsIngredients.push(<Skeleton key={i} variant="text" sx={{ fontSize: '1rem' }} width={(i % 3) ? ((i % 2) ?"10vw" : "7vw"):"5vw"} />)
  }
  return (
    <>
      <Grid container spacing={{sm:2}} marginTop={{xs:1, sm:0}}>
        <Grid item xs={12} sm={6}>
          <Skeleton variant="rectangular" width="100%" height={"40vh"} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Skeleton variant="rectangular" width="100%" height={"40vh"} />
        </Grid>
      </Grid>
      <Grid container sx={{marginY: '2rem'}}>
        <Grid item sm={3}>
          {skeletonsIngredients.map(s=>s)}
        </Grid>
        <Grid item sm={9}>
          {skeletons.map(s=>s)}
        </Grid>
      </Grid>
    </>
  )
}
