import React, {Fragment, useEffect, useState} from 'react'
import TeamSection, {IMember} from './team/TeamSection'
import Services from './Services'
import StarBatchView from "../our-suggestions/StarBatchView";
import {IBatch} from "../../api/IRecipe";
import {getStarBatch} from "../../api/api";
import {delay} from "../../utils/delay";
const teamMembers: IMember[] = [
  {
    name: "Cédric Duranti",
    role: "Le Chef",
    image: "team-1"
  },
  {
    name: "Fabrice Matrat",
    role: "Sys Admin",
    image: "team-3"
  },
  {
    name: "Émile Matrat",
    role: "UX Designer",
    image: "team-6"
  },
  {
    name: "Lucas",
    role: "Marketing",
    image: "team-5"
  },
  {
    name: "Sandrine Magnan",
    role: "Second de cuisine",
    image: "team-4"
  },
  {
    name: "Olivier",
    role: "Commis",
    image: "team-7"
  },
  {
    name: "Corinne Krych",
    role: "Développeur",
    image: "team-2"
  },
  {
    name: "Ludovic Matrat",
    role: "Photographe",
    image: "team-8"
  },
]
const LandPage = (): JSX.Element => {
  const [batch, setBatch] = useState<IBatch>()
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  useEffect( () => {
    const fetch = async() => {
      try {
        const b = await getStarBatch()
        //await delay(1000)
        setBatch(b)
        setIsLoading(false)
      } catch (err) {
        setError(err)
        setIsLoading(false)
      }
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
    fetch()
  }, []);
  
  return(
    <Fragment>
      <StarBatchView batch={batch}/>
      <Services batch={batch}/>
      <TeamSection members={teamMembers}/>
    </Fragment>
  )}
export default LandPage
