import {useEffect, useState} from "react";
import {createStrictContext} from "./createStrictContext";
import {useLocation, useSearchParams} from "react-router-dom";

export const [SearchContextProvider, useSearchContext] = createStrictContext<ISearchContext>()
export interface ISearchContext {
  search?: string
}
/*
  Take search from query
  Set search value in query
 */
export const useSearch = () => {
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams();

  const get = (urlParam) => {
    const e =  urlParam.toString()
    if (e && e.split('=').length > 1) {
      return e.split('=')[1]
    }
    return ''
  }
  const [value, setValue] = useState(get(searchParams))

  const setSearch = (searchKey, searchValue) =>{
    setValue(searchValue)
    setSearchParams({[searchKey]: searchValue}, {state: location.state})
  }

  return {
    searchValue: value,
    setSearch
  }
}
