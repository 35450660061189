import React from 'react'
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import RoundIconButton from "../../../components/RoundIconButton";

interface IYieldComponentProps {
  count: number
  label?: string
  onAdd?: (e) => void
  onDelete?: (e) => void
}

const YieldComponent = ({ count, label, onAdd, onDelete}: IYieldComponentProps) : JSX.Element => {
  return (
    <div>
      {count === 0 && <RoundIconButton onClick={onAdd}>
        <AddIcon sx={{fontSize: '0.9rem', width: '0.8rem'}}/>
      </RoundIconButton>}
      {count !== 0 && <>
        <RoundIconButton onClick={onDelete}>
          <RemoveIcon sx={{fontSize: '0.9rem', width: '0.8rem'}}/>
        </RoundIconButton>
        <span>{count}</span>
        <RoundIconButton onClick={onAdd}>
          <AddIcon sx={{fontSize: '0.9rem', width: '0.8rem'}}/>
        </RoundIconButton>
        <span>{label}</span>
      </>}
    </div>
  )
}

export default YieldComponent
