import React, {Fragment} from 'react'
import {INutrient} from '../../../api/IRecipe'
import {Box, capitalize, Grid, Table, TableBody, TableCell, Tooltip,TableContainer, TableHead,TableRow, Paper, Typography} from "@mui/material";

import {mapping, dietMapping} from "./NutrientsMapping"
import {PieChart} from 'react-minimal-pie-chart'
import {Img} from "../../../components/RecipeCard/CardOverlayContent";

interface INutrientsTableProps {
  nutrients: INutrient[],
  allergens: string[]
  suitableForDiet: string[]
}

const NutrientsTable = ({nutrients, allergens, suitableForDiet }: INutrientsTableProps): JSX.Element => {
  const protein = nutrients.filter((m) => m.name === 'protéines')
  const glucids = nutrients.filter((m) => m.name === 'glucides')
  const lipids = nutrients.filter((m) => m.name === 'matières grasses')
  let data = null
  
  if (protein.length > 0 && lipids.length > 0 && glucids.length > 0){
    data = [
      { title: 'protéines', value: protein[0].value, color: '#FF6384' },
      { title: 'glucides', value: glucids[0].value, color: '#36A2EB' },
      { title: 'lipides', value: lipids[0].value, color: '#FFCE56' },
    ];
  }
  
  return (
    <>
      {data && (
        <TableContainer component={Paper}>
          <Table size="small" aria-label="table de nutriments">
            <TableHead>
              <TableRow>
                <TableCell>Nutrition</TableCell>
                <TableCell>Valeurs par personne</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {nutrients.map((m, idx) => (
                <TableRow
                  key={idx}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <div>{capitalize(m.name)}</div>
                    {m.with && <div>dont {capitalize(m.with.name)}</div>}
                  </TableCell>
                  <TableCell>
                    <div>{`${m.value} ${m.unit}`}</div>
                    {m.with && <div>{`${m.with.value} ${m.with.unit}`}</div>}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Grid container marginTop={1}>
        <Grid item xs={12} sm={2}>
          {allergens && allergens.length > 1 && <Typography variant={"h5"}>Allergénes</Typography>}
          <Box display="flex" marginTop={1}>
            {allergens && allergens.length > 1 && allergens.map((a: string, idx: number) => (
              <Fragment key={idx}>
                {mapping.map((elt,i)=> {
                  if (elt.name === a) {
                    return (
                      <Tooltip key={i} title={a} arrow>
                        <Typography color="text.secondary">
                          <Img height="24" src={elt.image} alt={a} />
                        </Typography>
                      </Tooltip>
                    )
                  }
                  return <></>
                })}
              </Fragment>)
            )}
          </Box>
          {suitableForDiet && suitableForDiet.length > 1 && <Typography variant="h5">Convient</Typography>}
          <Box display="flex" marginTop={1}>
            {suitableForDiet && suitableForDiet.length > 1 && suitableForDiet.map((a: string, idx: number) => (
              <Fragment key={idx}>
                {dietMapping.map((elt, i)=> {
                  if (elt.name === a) {
                    return (
                      <Tooltip key={i} title={a} arrow>
                        <Typography color="text.secondary">
                          <Img height="24" src={elt.image} alt={a} />
                        </Typography>
                      </Tooltip>
                    )
                  }
                  return <></>
                })}
              </Fragment>)
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={10}>
          <PieChart
            label={({ dataEntry }) => `${dataEntry.title}`}
            data={data} style={{ maxHeight: '114px' }}
            labelStyle={{
              fill: '#fff',
              opacity: 0.75,
              fontSize: 10,
              pointerEvents: 'none',
            }}/>
        </Grid>
      </Grid>
    
    </>)
}
export default NutrientsTable
