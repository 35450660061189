import React, {useEffect, useState} from 'react'

import {login, loginFacebook} from '../../api/api'
import FacebookLogin from 'react-facebook-login'

import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {Alert, AlertTitle, Container, Divider, Snackbar} from "@mui/material";

import {MY_SPACE, REGISTERED} from "../../routes";
import {AnimatedBackground} from "./AnimatedBackgroung";
import LogoSign from "../../components/LogoSign";
import LoginForm from "./LoginForm";
import {IUser} from "../../api/IUser";
import useAuth from "../../hooks/useAuth";
import {Copyright} from "./Copyright";
import {ILoginFormValues, IRegistrationFormValues} from "./types";
import RegistrationForm from "./RegistrationForm";

export const SITE_KEY = '6Ldg55oaAAAAAM9O8-NYTFKy6fWbre2-O0GTF2pO'

const LoginPage = (): JSX.Element => {
  const [key, setKey] = useState('login');
  const { loginUser, registerUser, error } = useAuth();

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    }

    // load the script by passing the URL
    loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, function () {
      console.log("Script loaded!");
    });
  }, []);

  const handleSubmit = async (loginData: ILoginFormValues) => {
    if (loginData.email) {
      // @ts-ignore
      window.grecaptcha.ready(() => {
        // @ts-ignore
        window.grecaptcha.execute(SITE_KEY, { action: 'submit' })
          .then(captchaToken => {
             loginUser({email: loginData.email, password: loginData.password, captchaToken} as IUser)
          })
      })
    }
  }

  const registerMe = async (data: IRegistrationFormValues) => {
    if (data.email) {
      // @ts-ignore
      window.grecaptcha.ready(() => {
        // @ts-ignore
        window.grecaptcha.execute(SITE_KEY, { action: 'submit' })
          .then(captchaToken => {
            registerUser({firstname: data.firstname, lastname: data.lastname, email: data.email, password: data.password, captchaToken})
          })
      })
    }
  }

  const responseFacebook = async (response) => {
    if (response.accessToken) {
      await loginFacebook({accessToken: response.accessToken})
      window.location.href = '/';
    }
  }

  // if (error?.status_text === "redirect") {
  //   return <Navigate to={REGISTERED} />
  // }

  return (
    <Container component="main" maxWidth="xs">
      <AnimatedBackground />
      <Box
        sx={(theme) => {return {
          marginTop: "5%",
          padding: 3,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: theme.palette.background.default
        }}}
      >
        <LogoSign light sx={{marginBottom: '2rem'}} />
        <Typography sx={{marginBottom: '2rem'}} component="h3" variant="h3">
          {key === 'login' && 'Connexion' }
          {key === 'register' && 'Inscription' }
        </Typography>

        {error && (
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open
            onClose={() => {}}
          >
            <Alert severity="error" >
              <AlertTitle>Erreur</AlertTitle>
              Il y a une erreur 🤭!: <strong>{error.message}</strong>
            </Alert>
          </Snackbar>
          )}

        <FacebookLogin
          appId="1069546556994319"
          autoLoad={false}
          fields="name,email,picture"
          scope="email"
          callback={responseFacebook}
          isMobile={false}
          icon="fa-facebook"
          size="small"
          textButton="avec Facebook"
        />
        <Divider sx={{marginTop: '2rem', width: '100%'}} variant="fullWidth" light>ou</Divider>
         {key === 'login' && <>
          <LoginForm handleSubmit={handleSubmit}/>
          <Button
            onClick={() => setKey('register')}
            >{"Vous n'avez pas un compte? Créer un compte"}</Button>
        </>}
        {key === 'register' && <>
          <RegistrationForm handleSubmit={registerMe}/>
          <Button
            onClick={() => setKey('login')}
          >{"Vous avez déjà un compte? Se connecter"}</Button>
        </>}
        <Divider sx={{marginY: '1rem', width: '100%'}} light/>
        <Copyright />
      </Box>
    </Container>);
}
export default LoginPage
