import arachides from "../../../images/allergens/arachides.svg";
import crustaces from "../../../images/allergens/crustaces.svg";
import celeri from "../../../images/allergens/celeri.svg";
import fruitsCoques from "../../../images/allergens/fruits-a-coques.svg";
import gluten from "../../../images/allergens/gluten.svg";
import grainesSesame from "../../../images/allergens/graines-de-sesame.svg";
import lait from "../../../images/allergens/lait.svg";
import lupin from "../../../images/allergens/lupin.svg";
import mollusques from "../../../images/allergens/mollusques.svg";
import poissons from "../../../images/allergens/poissons.svg";
import oeufs from "../../../images/allergens/oeufs.svg";
import soja from "../../../images/allergens/soja.svg";
import sulfites from "../../../images/allergens/sulfites.svg";
import moutarde from "../../../images/allergens/moutarde.svg";
import vegan from "../../../images/allergens/vegan.svg";
import vegetarian from "../../../images/allergens/vegetarian.svg";
import noMilk from "../../../images/allergens/no-milk.svg";
import noPork from "../../../images/allergens/no-pork.svg";
import noGluten from "../../../images/allergens/no-gluten.svg";

export const mapping = [
  {
    name: 'arachides',
    image: arachides
  },
  {
    name: 'crustacés',
    image: crustaces
  },
  {
    name: 'céleri',
    image: celeri
  },    {
    name: 'fruits à coques',
    image: fruitsCoques
  },    {
    name: 'gluten',
    image: gluten
  },    {
    name: 'graines de sésame',
    image: grainesSesame
  },    {
    name: 'lait',
    image: lait
  },    {
    name: 'lupin',
    image: lupin
  },    {
    name: 'mollusques',
    image: mollusques
  },    {
    name: 'poissons',
    image: poissons
  },    {
    name: 'œufs',
    image: oeufs
  },    {
    name: 'soja',
    image: soja
  },    {
    name: 'sulfites',
    image: sulfites
  },    {
    name: 'moutarde',
    image: moutarde
  },
]

export const dietMapping = [
  {
    name: 'végan',
    image: vegan
  },
  {
    name: 'végétarien',
    image: vegetarian
  },
  {
    name: 'sans porc',
    image: noPork
  },
  {
    name: 'sans lactose',
    image: noMilk
  },
  {
    name: 'sans gluten',
    image: noGluten
  }
]
