import React, {ChangeEvent, Fragment, useState} from 'react'
import {isNumber} from '../../../utils/Text'
import {Badge, Button, FormControl} from 'react-bootstrap'
import Select from 'react-select'
import {IMeta, INameQuantified, IRecipe, IRecipeInstruction} from '../../../api/IRecipe'
import MetaIngredient from './MetaIngredient'
import RoundIconButton from "../../../components/RoundIconButton";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {IconButton} from "@mui/material";

interface IMetaProps {
  onInstructionMetaChange?: (id: string, v: IMeta[]) => void
  onInstructionDependenciesChange?: (id: string, v: string[]) => void
  onInstructionMetaIngredientChange: (id: string, ing: INameQuantified[]) => void
  categories: string[]
  resources: string[]
  recipe: IRecipe
  instruction?: IRecipeInstruction
}
const MetaInstruction = ({
                           onInstructionMetaChange,
                           onInstructionMetaIngredientChange,
                           onInstructionDependenciesChange,
                           categories,
                           resources,
                           recipe,
                           instruction // used for recipe
                         }: IMetaProps):JSX.Element => {
  
  const metaData = instruction?.meta;
  const [showIngredients, setShowIngredients] = useState(false);
  
  const onAdd = () => {
    const newMeta = {
      category: null,
      resource: null,
      duration: 0
    }
    if (instruction && onInstructionMetaChange) {
      onInstructionMetaChange(instruction.id, [...metaData, newMeta])
    }
  }
  
  const onDelete = (index) => {
    const newMeta = [...metaData.slice(0, index), ...metaData.slice(index+1)]
    
    if (instruction && onInstructionMetaChange) {
      onInstructionMetaChange(instruction.id, newMeta)
    }
  }
  
  const displayMetaResources = (instruct, e, index) => (
    <Fragment key={index}>
      <div className={"d-flex p-0"}>
        <Select
          isDisabled={instruct?.baseRecipe}
          className={"w-100"}
          placeholder={"Catégorie"}
          value={
            e.category?
              {
                label:e.category,
                value:e.category?.toLowerCase().replace(/\W/g, '')
              }:undefined
          }
          onChange={(v) => {
            let newMeta
            if (metaData?.length > 0) {
              newMeta = [...metaData.slice(0, index), {...metaData[index], category: v.label}, ...metaData.slice(index+1)]
            } else {
              newMeta = [{...metaData, category: v.label}]
            }
            if (instruct && onInstructionMetaChange)
              onInstructionMetaChange(instruct.id, newMeta)
          }}
          options={categories?.map(i => {
            return {
              label:i,
              value: i?.toLowerCase().replace(/\W/g, '')
            }
          })}
        />
        <Select
          isDisabled={instruct?.baseRecipe}
          placeholder={"Resource"}
          value={
            e.resource?
              {
                label:e.resource,
                value:e.resource?.toLowerCase().replace(/\W/g, '')
              }: undefined
          }
          className={"w-100"}
          onChange={(v) => {
            let newMeta
            if (metaData?.length > 0) {
              newMeta = [...metaData.slice(0, index), {...metaData[index], resource: v.label}, ...metaData.slice(index+1)]
            } else {
              newMeta = [{...metaData, resource: v.label}]
            }
            
            if (instruct && onInstructionMetaChange)
              onInstructionMetaChange(instruct.id, newMeta)
          }}
          options={resources?.map(i => {
            return {
              label:i,
              value: i?.toLowerCase().replace(/\W/g, '')
            }
          })}
        />
        <FormControl
          disabled={!!instruct?.baseRecipe}
          placeholder={"Duration"}
          as="input"
          value={e.duration?e.duration+"":"0"}
          name="duration"
          onChange={
            (props:ChangeEvent<HTMLInputElement>) => {
              let v = 0
              if(isNumber(props.target.value)) {
                v = +props.target.value
                if (v<0) {
                  v = 0
                }
              }
              let newMeta
              if (metaData?.length > 0) {
                newMeta = [...metaData.slice(0, index), {...metaData[index], duration: v}, ...metaData.slice(index+1)]
              } else {
                newMeta = [{...metaData, duration: v}]
              }
              
              if (instruct && onInstructionMetaChange) onInstructionMetaChange(instruct.id, newMeta)
            }
          }
        />
  
        <IconButton color="primary" onClick={!!instruct?.baseRecipe?null:() => onDelete(index)}>
          <DeleteOutlineIcon sx={{fontSize: '1.2rem'}}/>
        </IconButton>
        <IconButton color="primary" onClick={!!instruct?.baseRecipe?null:onAdd}>
          <AddCircleOutlineIcon sx={{fontSize: '1.2rem'}}/>
        </IconButton>
      </div>
    </Fragment>
  )
  
  return (
    <div className="row" style={{marginTop: '8px', marginLeft: '40px', marginRight: '40px'}}>
      {metaData?.length === 0 && (displayMetaResources(instruction, {}, 0))}
      {metaData?.map((e, index) => displayMetaResources(instruction, e, index))}
      
      {instruction && (
        <>
          <MetaIngredient
            onInstructionMetaIngredientChange={(v) => onInstructionMetaIngredientChange(instruction.id, v)}
            instruction={instruction}
            ingredients={recipe.recipeIngredients} show={showIngredients} setShow={setShowIngredients} />
          <div className="d-flex p-0 mt-1" >
            <Select
              isDisabled={instruction?.baseRecipe}
              isMulti
              placeholder={"Dépendances"}
              value={
                !instruction || instruction?.dependencies === null ? "" : instruction?.dependencies?.map(v => {return {
                  label: recipe.recipeInstructions?.find(q => q.id === v)?.name,
                  value: v
                }})
              }
              className={"w-100"}
              onChange={
                (v) => {
                  onInstructionDependenciesChange(instruction.id, v)
                }
              }
              options={recipe.recipeInstructions?.filter(e=>e.id!==instruction.id).map(i => {
                return {
                  label:i.name,
                  value: i.id
                }
              })}
            />
            <Button
              disabled={!!instruction?.baseRecipe}
              variant="outline-primary"
              size="sm"
              onClick={() => setShowIngredients(true)}
              className="h-100 mx-2">Ingrédients</Button>
          </div>
          <div className="d-flex flex-wrap mt-2">
            {instruction && instruction?.metaIngredients?.map((ingredient, idx) => (
              <Badge key={idx} pill className="my-2 badge-primary-outline me-1" bg={"light"}>{ingredient.name}</Badge>
            ))}
          </div>
        </>
      )}
    </div>
  )
}

export default MetaInstruction;
