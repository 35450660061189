import React, {useEffect, useState} from 'react'
import {IRecipe, IRecipeInstruction} from '../../../api/IRecipe'
import {Box, Button, Grid, TextField} from "@mui/material";
import {FieldArray, FormikValues, FormikErrors} from "formik";
import TitleTypography from "../../../components/TitleTypography";
import {Number} from "../Number";
import SubInstructions from "./SubInstructions";
import SplitButton from "../../../components/SplitButton/SplitButton";
import MetaInstruction from "./MetaInstruction";
import {getMetaDataTypes} from "../../../api/api";
import createUUID from '../../../utils/uuid'
import {IOption, MoreMenu} from "../../../components/MoreMenu";

interface IRecipeFormIngredientProps {
  recipe: IRecipe
  values: FormikValues
  base: boolean
  errors: FormikErrors<any>
  handleChange: (v:any) => void
  setFieldValue: any
  setShowBaseRecipeModal: (b: boolean) => void
}

const RecipeFormIngredient = (
  {recipe, base, values, errors, handleChange, setFieldValue, setShowBaseRecipeModal}:IRecipeFormIngredientProps
): JSX.Element => {
  const [instructions, setInstructions] = useState<IRecipeInstruction[]>(values.recipeInstructions)
  const [categories, setCategories] = useState<string[]>()
  const [resources, setResources] = useState<string[]>()

  useEffect(() => {
      const fetch = async() => {
        let result
        try {
          result = await getMetaDataTypes()
          setCategories(result.categories)
          setResources(result.resources)
        } catch(e) {
          // todo
        }
      }
      fetch()
    },
    [])

  useEffect(() => {
    setInstructions(values.recipeInstructions)
  }, [values.recipeInstructions])

  const onInstructionMetaIngredientChange = (id, list) => {
    const idx = instructions.findIndex(e=>e.id===id)
    if (idx===-1) {
      console.log("ERROR1 meta ingredient: instruction id not find")
      return
    }
    instructions[idx].metaIngredients = list
    setFieldValue(`recipeInstructions`, [...instructions.slice(0, idx), instructions[idx], ...instructions.slice(idx+1)])
  }
  const onInstructionMetaChange = (id, v) => {
    const idx = instructions.findIndex(e=>e.id===id)
    if (idx===-1) {
      console.log("ERROR2 meta: instruction id not find")
      return
    }
    instructions[idx].meta = v
    setFieldValue(`recipeInstructions`, [...instructions.slice(0, idx), instructions[idx], ...instructions.slice(idx+1)])

  }
  const onInstructionDependenciesChange = (id, v) => {
    const idx = instructions.findIndex(e=>e.id===id)
    if (idx===-1) {
      console.log("ERROR3 dependencies: instruction id not find")
      return
    }
    instructions[idx].dependencies = v.map(e=>e.value)
    setFieldValue(`recipeInstructions`, [...instructions.slice(0, idx), instructions[idx], ...instructions.slice(idx+1)])

  }
  return (
    <FieldArray
      name="recipeInstructions"
      render={arrayHelpers => (
        <Grid item xs={12}>
          <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '1rem', marginBottom: '1rem'}}>
            <TitleTypography>Les instructions</TitleTypography>
          </Box>

          {instructions?.map((i: IRecipeInstruction, idx: number) => {
            const options: IOption[] = [
              {
                name: "Supprimer l'instruction",
                action: () => {
                  let newArray = [...instructions.slice(0, idx), ...instructions.slice(idx+1)]
                  let toDelete = instructions[idx]
                  if (toDelete.baseRecipe) { // delete all instructions related to this base recipe
                    const filteredInstructions = instructions.filter(i => i.baseRecipe !== toDelete.baseRecipe)
                    newArray = [ ...filteredInstructions || [{
                      'name': '',
                      'when': ''
                    } as IRecipeInstruction]]
                  }
                  console.log(`DELETE INSTRUCTION`, newArray)
                  setInstructions(newArray)
                  setFieldValue(`recipeInstructions`, newArray)
                }
              },
              {
                name: "Ajouter une instruction",
                action: () => {
                  const newArray =  [...instructions?.slice(0, idx+1), {name: '', id: createUUID(), meta: [], when: 'batch', recipeInstructions: []}, ...instructions?.slice(idx+1)]
                  console.log(`ADD INSTRUCTION`, newArray)
                  setInstructions(newArray)
                  setFieldValue(`recipeInstructions`, newArray)
                }
              },
              {
                name: instructions[idx].when === "batch" ? "Tagger jour du service":"Tagger batch",
                action: () => {
                  let newArray =  [...instructions.slice(0, idx), {...instructions[idx], when:instructions[idx].when === "batch"?"serving":"batch"}, ...instructions.slice(idx+1)]
                  setInstructions(newArray)
                  setFieldValue(`recipeInstructions`, newArray)
                }
              }
            ]
            return (
              <Grid item key={idx} sx={(theme) => ({
                padding: '1rem',
                border: '1px solid #dee2e6',
                marginBottom: '1rem',
                backgroundColor: instructions[idx].when === "serving" ? `${theme.colors.primary.lighter}`:"inherit"})}>
                <Number>
                  {idx + 1}
                </Number>
                <Box marginBottom={2}>
                  <Box display={"flex"}>
                    <TextField
                      sx={{margin: '4px 4px 0 0',  '& legend': { display: 'none' }, '& fieldset': { top: 0 }}}
                      fullWidth
                      multiline
                      minRows={2}
                      size="small"
                      name={`recipeInstructions.${idx}.name`}
                      disabled={!!i.baseRecipe}
                      value={ !!i.baseRecipe ? `${values?.meta[i.baseRecipe]?.name}: ${i.name}` : i.name }
                      onChange={(e) => {
                        console.log(`==> ${JSON.stringify(instructions[idx])}`)
                        const newInstructions = [...instructions?.slice(0, idx), {...values?.recipeInstructions[idx],name: e.target.value}, ...instructions?.slice(idx+1)]
                        setInstructions(newInstructions)
                        console.log(`~~~~ newInstructions ${JSON.stringify(newInstructions)}`)
                      }}
                      onBlur={(e) => {
                        setFieldValue(`recipeInstructions`, instructions)
                      }}
                    />
                    <MoreMenu options={options} />
                  </Box>
  
  
                  { instructions[idx].when !== "serving" && <MetaInstruction
                    onInstructionMetaIngredientChange={onInstructionMetaIngredientChange}
                    recipe={values as IRecipe}
                    resources={resources}
                    categories={categories}
                    instruction={i}
                    onInstructionMetaChange={onInstructionMetaChange}
                    onInstructionDependenciesChange={onInstructionDependenciesChange}
                  />}

                  <SubInstructions idx={idx} instructions={instructions} setFieldValue={setFieldValue}/>
                </Box>
              </Grid>
            )
          })}
          <Grid item>
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '1rem', marginBottom: '1rem'}}>
              <TitleTypography></TitleTypography>
              {!base && <SplitButton
                primaryOption="Ajouter une instruction"
                handleClickOnPrimaryAction={() => {
                  const newArray =  [...instructions, {name: '', id: createUUID(), meta: [], when: 'batch'}]
                  console.log(`ADD INSTRUCTION`, newArray)
                  setInstructions(newArray)
                  setFieldValue(`recipeInstructions`, newArray)
                }}
                options={[
                  {
                    label: "Insérer une recette de base",
                    action: () => {setShowBaseRecipeModal(true)}
                  }
                ]}
              />}
              {base && <Button variant="contained" onClick={() => {
                const newArray =  structuredClone([...instructions, {name: '', id: createUUID(), meta: [], when: 'batch'}])
                console.log(`ADD INSTRUCTION`, newArray)
                setInstructions(newArray)
                setFieldValue(`recipeInstructions`, newArray)
              }}>Ajouter une instruction</Button>}
            </Box>
          </Grid>
        </Grid>
      )}
    />
  )
}
export default RecipeFormIngredient

