import { useContext } from 'react';

import {
  Box,
  alpha,
  Stack,
  lighten,
  Divider,
  IconButton,
  TextField,
  InputAdornment,
  Tooltip,
  styled,
  useTheme, Button, ListItem, List, Toolbar
} from '@mui/material';
import HeaderUserbox from './Userbox';
import {OUR_SUGGESTIONS, RECIPES} from "../../../routes";
import {NavLink as RouterLink, useNavigate} from "react-router-dom";
import BrunchDiningIcon from "@mui/icons-material/BrunchDining";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {ThemeToggle} from "../../../theme/ThemeToggle";

export const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 6;
        background-color: ${alpha(theme.header.background, 0.4)};
        backdrop-filter: blur(3px);
        position: fixed;
        justify-content: space-between;
        width: 100%;
`
);

function BaseLayoutHeaderMenu() {
  return (
    <HeaderWrapper
      display="flex"
      alignItems="center"
    >
      <Toolbar>

        <Box component="div">
          <Button
            disableRipple
            component={RouterLink}
            to={RECIPES}
            startIcon={<BrunchDiningIcon />}
          >
            Recettes
          </Button>
        </Box>
        <Box component="div">
          <Button
            disableRipple
            component={RouterLink}
            to={OUR_SUGGESTIONS}
            startIcon={<CalendarMonthIcon />}
          >
            Batchs
          </Button>
        </Box>

      </Toolbar>
      <Box display="flex" alignItems="center">
        <HeaderUserbox />
        <ThemeToggle />
      </Box>
    </HeaderWrapper>
  );
}

export default BaseLayoutHeaderMenu;
