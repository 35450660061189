import {styled} from "@mui/material/styles";

export const InnerNumber = styled('div')(
  ({theme}) => `
  -webkit-box-align: center;
  align-items: center;
  border-radius: 50%;
  display: flex;
  float: left;
  font-size: 1rem;
  height: 20px;
  -webkit-box-pack: center;
  justify-content: center;
  color: ${theme.palette.text.primary};
  padding: 9px;
  width: 12px;
  background: ${theme.palette.background.paper};
  border: 1px solid ${theme.colors.primary.main};
  margin: 0 8px 0 0;
`
);
