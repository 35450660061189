import axios from 'axios'
import {IRecipe, IRecipes, IBatch, IIngredient, INameQuantified, IResource, IMetaDataTypes} from './IRecipe'
import {IUser, IUserPreferences} from './IUser'
import {convertIngredientsQuantity} from "../utils/convertPayload";
export const baseURL = 'https://api.cuisinons.org'; // PRODUCTION
//export const baseURL = 'https://cuisinons-test.org';  // DEV
axios.defaults.baseURL = baseURL;
axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.post["Access-Control-Allow-Methods"] = "GET, POST, PUT, DELETE, PATCH, OPTIONS"
axios.defaults.withCredentials = true

export const version = '30 avril 2023 - 14h'

export const login = async ({email, password, captchaToken}: IUser): Promise<void> => {
  const apiUrl = '/v1/login'
  return axios.post(apiUrl,
    {
      email,
      password,
      captchaToken
    }
  )
}

export const loginFacebook = async ({accessToken}: any): Promise<void> => {
  const apiUrl = '/v1/loginfb'
  return axios.post(apiUrl,
    {
      accessToken
    }, {withCredentials: true}
  )
}

export const resendVerify = async (): Promise<void> => {
  const apiUrl = '/v1/reverify'
  return axios.get(apiUrl)
}

export const register = async ({firstname, lastname, email, password, captchaToken}:IUser): Promise<void> => {
  const apiUrl = '/v1/user'
  return axios.post(apiUrl,
    {
      firstname,
      lastname,
      email,
      password,
      captchaToken
    }
  )
}

export const updateUserPreferences = async ({preferences}:IUserPreferences): Promise<IUserPreferences> => {
  const apiUrl = '/v1/user'
  return axios.put(apiUrl,
    {
      preferences
    }
  )
}

export const publishRecipe = async (id: string): Promise<void> => {
  const apiUrl = `/v1/recipe/${id}/publish`
  return axios.put(apiUrl)
}
export const unPublishRecipe = async (id: string): Promise<void> => {
  const apiUrl = `/v1/recipe/${id}/unpublish`
  return axios.put(apiUrl)
}

export const logout = async (): Promise<void> => {
  const apiUrl = '/v1/logout'
  return axios.post(apiUrl)
}

export const getUser = async (): Promise<IUser> => {
  const apiUrl = '/v1/user'
  return axios.get(apiUrl).then(p => p.data)
}

export const getBaseRecipes = async (): Promise<IRecipe[]> => {
  const apiUrl = `/v1/base`
  return  axios.get(apiUrl).then(list => list?.data?.recipes)
}

export const searchBaseRecipes = async (keyword: string): Promise<IRecipe[]> => {
  const apiUrl = `/v1/searchBase?keyword=${keyword}`
  return axios.get(apiUrl).then(list => list?.data?.recipes)
}

export const saveBaseRecipe = async (recipe: IRecipe): Promise<IRecipe> => {
  try {
    const apiUrl = `/v1/base`
    const ingredients = convertIngredientsQuantity(recipe.recipeIngredients)
    const recipeId = await axios.put(apiUrl, {...recipe, recipeIngredients: ingredients})
    return recipeId.data
  } catch (e) {
    throw new Error(e.response.data.message)
  }
}

export const getBaseRecipe = async (recipeId: string): Promise<IRecipe> => {
  const apiUrl = `/v1/base/${recipeId}`
  return axios.get(apiUrl).then(recipe => recipe.data)
}

export const deleteBaseRecipe = async (recipeId: string): Promise<IRecipe> => {
  const apiUrl = `/v1/base/${recipeId}`
  return axios.delete(apiUrl).then(recipe => recipe.data)
}

export const getRecipes = async (next: string, limit: number, publishedOnly: boolean): Promise<IRecipes> => {
  const apiUrl = `/v1/recipe?next=${next}&limit=${limit}&published=${publishedOnly}`
  const list = await axios.get(apiUrl)
  let newNext = null
  if (list.data.next != "") {
    newNext = list.data.next
  }
  return <IRecipes>{
    recipes: list.data.recipes,
    next: newNext
  }
}

export const searchRecipes = async (keyword: string): Promise<IRecipe[]> => {
  const apiUrl = `/v1/search?keyword=${keyword}&published=false`
  return axios.get(apiUrl).then(list => list.data.recipes)
}

export const getIngredient = async (): Promise<IIngredient[]> => {
  const apiUrl = '/v1/ingredient'
  return axios.get(apiUrl).then(list => list.data.ingredients)
}

export const getTool = async (): Promise<INameQuantified[]> => {
  const apiUrl = '/v1/tool'
  return axios.get(apiUrl).then(list => list.data.tools as INameQuantified[])
}

export const deleteRecipe = async (recipeId: string): Promise<IRecipe> => {
  const apiUrl = `/v1/recipe/${recipeId}`
  return axios.delete(apiUrl).then(recipe => recipe.data)
}

export const getRecipe = async (recipeId: string): Promise<IRecipe> => {
  const apiUrl = `/v1/recipe/${recipeId}?published=false`
  return axios.get(apiUrl).then(recipe => recipe.data)
}

export const saveRecipe = async (recipe: IRecipe): Promise<IRecipe> => {
  try {
    const apiUrl = `/v1/recipe`

    const ingredients = convertIngredientsQuantity(recipe.recipeIngredients)
    const recipeId = await axios.put(apiUrl, {...recipe, recipeIngredients: ingredients})
    return recipeId.data
  } catch (e) {
    throw new Error(e.response.data.message)
  }
}

export const upload = (
  file: File,
  urlName: string,
  recipeID: string,
  format: string,
  ext: string,
  height: number,
  width: number,
  size: string): Promise<{data: never}> => {
  const apiUrl = '/v1/images'
  const formData = new FormData();
  formData.append('imagePath', JSON.stringify({
    recipeID,
    urlName,
    format,
    ext,
    height,
    width,
    size
  }))
  formData.append('file', file)
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  }
  return axios.post(apiUrl, formData, config)
}

export const saveBatch = async (batch: IBatch): Promise<IRecipe> => {
  try {
    const apiUrl = `/v1/batch`
    const resultedBatch = await axios.put(apiUrl, batch)
    return resultedBatch.data
  } catch (e) {
    throw new Error(e.response.data.message)
  }
}

export const saveSuggestedBatch = async (batch: IBatch): Promise<IRecipe> => {
  try {
    const apiUrl = `/v1/suggestedBatch`
    const resultedBatch = await axios.put(apiUrl, batch)
    return resultedBatch.data
  } catch (e) {
    throw new Error(e.response.data.message)
  }
}

export const getBatches = async (): Promise<IBatch[]> => {
  const apiUrl = '/v1/batch';
  return axios.get(apiUrl).then(list => list.data.batch)
}

export const getSuggestedBatches = async (publishedOnly= true): Promise<IBatch[]> => {
  const apiUrl = `/v1/suggestedBatch?published=${publishedOnly}`;
  return axios.get(apiUrl).then(list => list.data.batch)
}

export const getStarBatch = async (): Promise<IBatch> => {
  const apiUrl = `/v1/starBatch`;
  return axios.get(apiUrl).then(b => b.data)
}

export const deleteSuggestedBatch = async (batchId: string): Promise<IBatch> => {
  const apiUrl = `/v1/suggestedBatch/${batchId}`
  return axios.delete(apiUrl).then(batch => batch.data)
}

export const deleteBatch = async (batchId: string): Promise<IBatch> => {
  const apiUrl = `/v1/batch/${batchId}`
  return axios.delete(apiUrl).then(batch => batch.data)
}

export const getBatch = async (batchId: string): Promise<IBatch> => {
  const apiUrl = `/v1/batch/${batchId}`
  return axios.get(apiUrl).then(batch => batch.data)
}

export const getCurrentBatch = async (): Promise<IBatch> => {
  const apiUrl = `/v1/batch/current`
  return axios.get(apiUrl).then(batch => batch.data)
}

export const getSuggestedBatch = async (batchId: string): Promise<IBatch> => {
  const apiUrl = `/v1/suggestedBatch/${batchId}?published=false`
  return axios.get(apiUrl).then(batch => batch.data)
}

export const getExecutionBatch = async (batchId: string): Promise<IResource> => {
  const apiUrl = `/v1/batch/${batchId}/execute`
  return  axios.get(apiUrl).then(batch => batch.data.cooker)
}
export const getExecutionSuggestedBatch = async (batchId: string): Promise<IResource> => {
  const apiUrl = `/v1/suggestedBatch/${batchId}/execute?published=false`
  return  axios.get(apiUrl).then(batch => batch.data.cooker)
}
export const getMetaDataTypes = async (): Promise<IMetaDataTypes> => {
  const apiUrl = `/v1/category`
  return axios.get(apiUrl).then(batch => batch.data)
}
