import React from 'react';
import './Footer.scss';
import Logo from '../../images/cuisinons_121212.png'
import {useLocation} from 'react-router'
import {LOGIN, REGISTERED} from "../../routes";

import FavoriteIcon from '@mui/icons-material/Favorite';
const Footer = (): JSX.Element => {
  const l = useLocation()
  if (l?.pathname == LOGIN || l?.pathname == REGISTERED) {
    return (<></>)
  }

  return (
    <footer style={{overflowX: 'hidden'}} className="footer set-bg d-print-none" data-setbg="img/footer-bg.jpg">
      <div className="container text-center">
        <div className="row">
          <div className="col-12">
            <div className="footer__about">
              <div className="footer__logo">
                <a href="#"><img src={Logo} alt="cuisinons" /></a>
              </div>
              <p>
                {"La platforme collaborative qui nous aide à cuisiner pour toute la famille en optimisant notre temps."}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="footer__about">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <p className="text-white">
                Copyright ©2023. {"Tous droits réservés cuisinong.org."}<br/> Ce site est fait avec
                <FavoriteIcon sx={{color: '#F08632'}} /> par Corinne.
              </p>
            </div>
            <div className="col-lg-5">
              <div className="footer__about">
                <ul>
                  <li><a href="/cgu">{"Conditions générales d'utilisation"}</a></li>
                  <li><a href="/pdc">{"Politique de confidentialité"}</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
export default Footer;
