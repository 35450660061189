import {styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";

export const TypographyClamp = (line, color = false) => styled(Typography)(
  ({theme}) => `
    display: -webkit-box;
    -webkit-line-clamp: ${line};
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: ${color===true ? theme.palette.primary.main : 'inherit'};
`);
export const TypographyClamp3 = TypographyClamp(3)
export const TypographyClamp1 = TypographyClamp(1, true)
