import RecipeCard from "../../recipe/list/RecipeCard";
import {RECIPE} from "../../../routes";
import React from "react";
import {IBatch} from "../../../api/IRecipe";
import {Box} from "@mui/material";

const convertDay = (day: string): string => {
  if (day == "Jour 1") return "Lundi"
  if (day == "Jour 2") return "Mardi"
  if (day == "Jour 3") return "Mercredi"
  if (day == "Jour 4") return "Jeudi"
  if (day == "Jour 5") return "Vendredi"
}
const BatchWithRecipes = ({batch}:{batch:IBatch}) => {
  const isSuggested = !batch?.startDate
  const displayDay = isSuggested ? convertDay : (day) => {return day}
  return(
    <div className="d-flex overflow-scroll">
      {batch && batch.recipesByDay && Object.keys(batch.recipesByDay).map((d,i) => {
        return (
          <div key={i} className="mt-4 column">
            {batch && batch.recipesByDay[d].recipeIds?.length > 0 && <div className="title text-center">{displayDay(batch.recipesByDay[d].day)}</div>}
            <div className='recipes-list'>
              {batch && batch.recipesByDay[d].recipeIds?.map((r, idx) => {
                return (
                  <Box sx={{margin: '8px'}} key={`${batch.recipes[r].id}-${idx}`}>
                    <RecipeCard
                      withoutMenu
                      count={0}
                      onClick={() => window.open(`${RECIPE}${batch.recipes[r].id}`)}
                      recipe={batch.recipes[r]} />
                  </Box>
                )
              })}
            </div>
          </div>
        )})}
    </div>
  )
}
export default BatchWithRecipes
