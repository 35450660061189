import React from 'react'
import {Card} from 'react-bootstrap'
import {INameQuantified} from '../../../api/IRecipe'
import {ingredientDisplay} from '../../recipe/utils/Convertion'
import './IngredientList.scss'
import {accentsTidy} from '../../../utils/Text'
import Masonry from 'react-masonry-css'

interface IIngredientListProps {
  ingredients: INameQuantified[]
}


const breakpointColumnsObj = {
  default: 2,
  500: 1
};

const IngredientList = ({ingredients}: IIngredientListProps): JSX.Element => {
  
  return (
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column">
        {ingredients && Object.keys(ingredients).map(category => (
          <div key={accentsTidy(category)}>
            <Card className={`recipe-card border-${accentsTidy(category)}`} >
              <Card.Header className={`bg-${accentsTidy(category)}`}>{category}</Card.Header>
              <Card.Body>
                {ingredients[category].map((ing: INameQuantified, ii:number) => {
                  return (
                    <div key={`ingredient-${accentsTidy(category)}-${ii}`}>
                      {ingredientDisplay(ing)}
                    </div>
                  )})}
              </Card.Body>
            </Card>
          </div>
        ))}
      </Masonry>

  )
}
export default IngredientList
