import {IBatch} from "../../../api/IRecipe";
import {IOption, MoreMenu} from "../../../components/MoreMenu";
import React from "react";
import {Card, CardActions, CardContent, CardHeader, Collapse, darken, IconButton, IconButtonProps} from "@mui/material";
import BatchWithRecipesLinear from "./BatchWithRecipesLinear";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BatchWithRecipes from "./BatchWithRecipes";
import {styled} from "@mui/styles";
import {TypographyClamp3} from "../../../utils/Typography";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}
const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto!important'
}));

export const SuggestedBatchCard = ({batch, options}:{batch:IBatch, options: IOption[]}) => {
  const [expanded, setExpanded] = React.useState(false);
  const batchName = batch.startDate ? `Batch du ${new Date(batch.startDate).toLocaleDateString()}` : batch.name
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  
  return (
    <Card key={batch.id} sx={{marginTop: '2rem', width: '100%'}}>
      <CardHeader
        sx={theme => ({backgroundColor: `${theme.palette.mode === "light" ? 'whitesmoke' : darken(theme.palette.secondary.dark, 0.7)}`})}
        action={
          <MoreMenu options={options}/>
        }
        title={batchName}
        subheader={<TypographyClamp3>{batch.description}</TypographyClamp3>}
      />
      <CardContent>
        <BatchWithRecipesLinear batch={batch}/>
      </CardContent>
      <CardActions disableSpacing>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon/>
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <BatchWithRecipes batch={batch}/>
        </CardContent>
      </Collapse>
    </Card>
  )
}
