import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import {Box, capitalize, CardActionArea, lighten, useTheme} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {baseURL} from "../../api/api";
import {IRecipe} from "../../api/IRecipe";
import Ribbon from "../../components/Ribbon";
import {TypographyClamp3} from "../../utils/Typography";

interface IRecipeCardGalleryProps {
  recipe: IRecipe
  onClick?: () => void
}

const RecipeCardGallery = ({recipe, onClick}: IRecipeCardGalleryProps): JSX.Element => {
  
  let imageURL = "https://via.placeholder.com/800x600"
  if (recipe?.image?.length > 0) {
    if (recipe.image[0].thumbnail?.length > 0) {
      imageURL = recipe.image[0].thumbnail[0]
    } else {
      imageURL = recipe.image[0].contentUrl
    }
    imageURL = `${baseURL}/v1/images/${imageURL}`
  }
  const theme = useTheme()
  return (
    <>
    <Card sx={{ margin: '1rem', boxShadow: theme.palette.mode === 'light' ? '1px 1px 20px 0 rgb(185 185 185 / 84%)': 'none',position:'relative'}}>
      {recipe?.status === "draft" || !recipe?.status && <Ribbon>Draft</Ribbon>}

      <CardActionArea
        onClick={() => onClick && onClick()}>
      <Box sx={{position: 'relative'}}>
        <CardMedia
          component="img"
          image={imageURL}
          alt={recipe.name}
        />
      </Box>
        <CardHeader
          sx={{minHeight: '5rem'}}
          title={<TypographyClamp3>{capitalize(recipe?.name || "recette en chargement")}</TypographyClamp3>}
          // subheader={<TypographyClamp3>{capitalize(recipe.description)}</TypographyClamp3>}
        />
      </CardActionArea>
    </Card>
    </>
  );
}

export default RecipeCardGallery;
