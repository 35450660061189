import {INameQuantified} from '../../../api/IRecipe'
import {capitalizeAndPunctuation} from '../../../utils/Text'
import React from 'react'

const getQuantity = (q: number|string): string => {
  if (q === 0.5) {
    return `½`
  }
  if (q === 0.25) {
    return `¼`
  }
  if (q === 0.75) {
    return `¾`
  }
  
  return `${q}`
}
export const ingredientDisplay = (ing: INameQuantified): JSX.Element => {
  let value = `${capitalizeAndPunctuation(ing.name, ':')} ${getQuantity(ing?.quantity ?? 0)} ${ing?.unit || ''}`
  if (ing?.unit?.toLowerCase() === 'pce') {
    value = `${capitalizeAndPunctuation(ing.name, ':')} ${getQuantity(ing?.quantity)}`
  }
  if (ing?.unit?.toLowerCase() === 'cc') {
    value = `${capitalizeAndPunctuation(ing.name, ':')} ${getQuantity(ing?.quantity)} c. à café`
  }
  if (ing?.unit?.toLowerCase() === 'cs') {
    value = `${capitalizeAndPunctuation(ing.name, ':')} ${getQuantity(ing?.quantity)} c. à soupe`
  }
  if (ing?.unit?.toLowerCase() === 'qs' || ing?.unit?.toLowerCase() === 'pm') {
    value = `${capitalizeAndPunctuation(ing.name, ':')}  quantité suffisante`
  }
  
  if ( (ing?.unit?.toLowerCase() === 'gousse' ||
    ing?.unit?.toLowerCase() === 'boîte' ||
    ing?.unit?.toLowerCase() === 'brin' ||
    ing?.unit?.toLowerCase() === 'pincée' ||
    ing?.unit?.toLowerCase() === 'verre' ||
    ing?.unit?.toLowerCase() === 'branche' ||
    ing?.unit?.toLowerCase() === 'botte' ||
    ing?.unit?.toLowerCase() === 'dose' ||
    ing?.unit?.toLowerCase() === 'sachet' ||
    ing?.unit?.toLowerCase() === 'tranche') &&
    ing?.quantity > 1
  ) {
    value = `${capitalizeAndPunctuation(ing.name, ':')}  ${getQuantity(ing?.quantity)} ${ing?.unit}s`
  }
  if (ing?.unit?.toLowerCase() === 'verre à liqueur' && ing?.quantity > 1) {
    value = `${capitalizeAndPunctuation(ing.name, ':')}  ${getQuantity(ing?.quantity)} verres à liqueur`
  }
  if (ing?.unit?.toLowerCase() === 'l' && ing?.quantity > 1) {
    value = `${capitalizeAndPunctuation(ing.name, ':')}  ${getQuantity(ing?.quantity)} litres`
  }
  if (ing?.unit?.toLowerCase() === 'l' && ing?.quantity === 1) {
    value = `${capitalizeAndPunctuation(ing.name, ':')}  ${getQuantity(ing?.quantity)} litre`
  }
  if (ing?.notForNutrient) {
    value = `${value} (ignoré)`
  }
  
  return (<span>{value}</span>)
}

export const ingredientDisplayStrong = (ing: INameQuantified): JSX.Element => {
  let value = <><strong>{capitalizeAndPunctuation(ing.name, ':')}</strong>{getQuantity(ing?.quantity ?? 0)}{ing?.unit || ''} </>
  if (ing?.unit?.toLowerCase() === 'pce') {
    value = <><strong>{capitalizeAndPunctuation(ing.name, ':')}</strong>{getQuantity(ing?.quantity)} </>
  }
  if (ing?.unit?.toLowerCase() === 'cc') {
    value = <><strong>{capitalizeAndPunctuation(ing.name, ':')}</strong>{getQuantity(ing?.quantity)} c. à café </>
  }
  if (ing?.unit?.toLowerCase() === 'cs') {
    value = <><strong>{capitalizeAndPunctuation(ing.name, ':')}</strong>{getQuantity(ing?.quantity)} c. à soupe </>
  }
  if (ing?.unit?.toLowerCase() === 'qs' || ing?.unit?.toLowerCase() === 'pm') {
    value = <><strong>{capitalizeAndPunctuation(ing.name, ':')}</strong>quantité suffisante </>
  }
  
  if ( (ing?.unit?.toLowerCase() === 'gousse' ||
      ing?.unit?.toLowerCase() === 'boîte' ||
      ing?.unit?.toLowerCase() === 'brin' ||
      ing?.unit?.toLowerCase() === 'pincée' ||
      ing?.unit?.toLowerCase() === 'verre' ||
      ing?.unit?.toLowerCase() === 'branche' ||
      ing?.unit?.toLowerCase() === 'botte' ||
      ing?.unit?.toLowerCase() === 'dose' ||
      ing?.unit?.toLowerCase() === 'sachet' ||
      ing?.unit?.toLowerCase() === 'tranche') &&
    ing?.quantity > 1
  ) {
    value = <><strong>{capitalizeAndPunctuation(ing.name, ':')}</strong>{getQuantity(ing?.quantity)} {ing?.unit}s </>
  }
  if (ing?.unit?.toLowerCase() === 'verre à liqueur' && ing?.quantity > 1) {
    value = <><strong>{capitalizeAndPunctuation(ing.name, ':')}</strong> {getQuantity(ing?.quantity)} verres à liqueur </>
  }
  if (ing?.unit?.toLowerCase() === 'l' && ing?.quantity > 1) {
    value = <><strong>{capitalizeAndPunctuation(ing.name, ':')}</strong> {getQuantity(ing?.quantity)} litres </>
  }
  if (ing?.unit?.toLowerCase() === 'l' && ing?.quantity === 1) {
    value = <><strong>{capitalizeAndPunctuation(ing.name, ':')}</strong> {getQuantity(ing?.quantity)} litre </>
  }
  if (ing?.notForNutrient) {
    value = <><strong>{value}</strong>(ignoré)</>
  }
  
  return value
}
