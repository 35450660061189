import CardActions from "@mui/material/CardActions";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {styled} from "@mui/material/styles";
import Chef from '../../images/chef.svg'
import {Button, Tooltip} from "@mui/material";
import AcUnitIcon from '@mui/icons-material/AcUnit';

export const Img = styled('img')(
  () => `
  margin-left: 1rem;
`
);
export const CardOverlayContent = ({recipe, fullWidth= true}) => {
  const cookingTime = recipe?.prepTime.match(/\d+/g)?.join('')

  return(
    <CardActions sx={{display: 'flex',flexWrap: 'wrap', alignItems: 'center'}}>
      <AccessTimeIcon sx={{fontSize:'.9rem'}} color="disabled"/>
      <Typography color="text.secondary" sx={{fontSize:'.7rem'}} >{cookingTime} mn</Typography>
      {recipe.estimatedCost && recipe.estimatedCost.value < 5 && (
        <Tooltip title="Bon marché" arrow><Typography sx={{fontSize:'.9rem'}} color="text.secondary">€</Typography></Tooltip>
      )}
      {recipe.estimatedCost && recipe.estimatedCost.value >= 6 && recipe.estimatedCost.value < 12 && (
        <Tooltip title="Coût moyen" arrow><Typography sx={{fontSize:'.9rem'}} color="text.secondary">€€</Typography></Tooltip>
      )}
      {recipe.estimatedCost && recipe.estimatedCost.value >= 12 && (
        <Tooltip title="Coûteux" arrow><Typography sx={{fontSize:'.9rem'}} color="text.secondary">€€€</Typography></Tooltip>
      )}
      {recipe.nutriScore && recipe.nutriScore==='A' && <Img height="24" src="/static/images/Nutri-score-A.svg" alt="A" />}
      {recipe.nutriScore && recipe.nutriScore==='B' && <Img height="24" src="/static/images/Nutri-score-B.svg" alt="B" />}
      {recipe.nutriScore && recipe.nutriScore==='C' && <Img height="24" src="/static/images/Nutri-score-C.svg" alt="C" />}
      {recipe.nutriScore && recipe.nutriScore==='D' && <Img height="24" src="/static/images/Nutri-score-D.svg" alt="D" />}
      {recipe.nutriScore && recipe.nutriScore==='E' && <Img height="24" src="/static/images/Nutri-score-E.svg" alt="E" />}
      {fullWidth && (
        <>
          {recipe.educationalLevel && recipe.educationalLevel.name === "débutant" && (
            <Tooltip title="débutant" arrow><Img height="15" src={Chef} /></Tooltip>
          )}
          {recipe.educationalLevel && recipe.educationalLevel.name === "intermédiaire" && (
            <Tooltip title="intermédiaire" arrow><><Img height="0.9rem" src={Chef} /><Img height="0.9rem" src={Chef} /></></Tooltip>
          )}
          {recipe.educationalLevel && recipe.educationalLevel.name === "expert" && (
            <Tooltip title="expert" arrow><><Img height="20" src={Chef} /><Img height="0.9rem" src={Chef} /><Img height="0.9rem" src={Chef} /></></Tooltip>
          )}
          {recipe.conservationTime && recipe.conservationTime === 'PT1D' && (
            <Tooltip title="À consommer rapidement" arrow><AcUnitIcon sx={{fontSize:'.9rem'}} color="disabled" /></Tooltip>
          )}
          {recipe.conservationTime && recipe.conservationTime === 'PT2D' && (
            <Tooltip title="Conservation moyenne" arrow><><AcUnitIcon sx={{fontSize:'.9rem'}} color="disabled" /><AcUnitIcon sx={{fontSize:'.9rem'}} color="disabled" /></></Tooltip>
          )}
          {recipe.conservationTime && recipe.conservationTime === 'PT3D' && (
            <Tooltip title="Bonne conservation" arrow><><AcUnitIcon sx={{fontSize:'.9rem'}} color="disabled" /><AcUnitIcon sx={{fontSize:'.9rem'}} color="disabled" /><AcUnitIcon sx={{fontSize:'.9rem'}} color="disabled" /></></Tooltip>
          )}
        </>
      )}


    </CardActions>
  )
}
