import { useState } from 'react'

export function useLocalStorage<T>(key: string, initialValue: T) {
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (typeof window === 'undefined') {
      return initialValue
    }

    const item = window.localStorage.getItem(key)

    try {
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      return item || initialValue
    }
  })

  const setValue = (value: T | ((val: T) => T)) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value
      const isString = typeof valueToStore === 'string'
      const valueToLocalStorage = isString ? valueToStore : JSON.stringify(valueToStore)

      setStoredValue(valueToStore)
      window.localStorage.setItem(key, valueToLocalStorage)
    } catch (error) {
      console.log(error)
    }
  }

  return [storedValue, setValue] as const
}
