import React, {useState, Fragment} from 'react'
import {IRecipe, IRecipeInstruction} from '../../../api/IRecipe'
import {Box, Button, capitalize, Grid} from "@mui/material";
import {capitalizeAndPunctuation} from "../../../utils/Text";
import TitleTypography from "../../../components/TitleTypography";
import {InnerNumber} from "../InnerNumber";
import {Number} from "../Number";
import ViewIngredients from "../../batch/execution/ViewMetaIngredient";

export interface IViewInstructionProps {
  title: string
  recipe: IRecipe
  recipeInstructions: IRecipeInstruction[]
}
export const ViewInstructions = ({title, recipe, recipeInstructions}: IViewInstructionProps): JSX.Element => {
  const [showModal, setShowModal] = useState<boolean>(false)
  
  return (
    <Grid>
      <Grid item>
        <TitleTypography>
          {title}
        </TitleTypography>
      </Grid>
      <Grid item>
        {recipeInstructions?.map((i: IRecipeInstruction, idx: number) => {
          let videoUrl
          if (i?.video && i?.video[0]) {
            const endUrl = i?.video[0].contentUrl?.split('/')[3]
            videoUrl = endUrl ? `https://www.youtube.com/embed/${endUrl}` : undefined
          }
          
          return (
            <Grid item key={idx}>
              <Grid item>
                <Number>
                  {idx + 1}
                </Number>
                <Box marginBottom={2}>
                  {!i.recipeInstructions && (
                    <div className="recipe-sub-title">
                      <Box sx={{marginBottom: 2}}>{!!i.baseRecipe ? `${capitalize(recipe?.meta[i.baseRecipe]?.name)}: ${i.name}` : capitalizeAndPunctuation(i.name)}</Box>
                      {i.metaIngredients?.length>0 && (
                        <Box sx={{marginBottom: '1rem'}}>
                          <ViewIngredients ingredients={i.metaIngredients} />
                        </Box>
                      )}
                    </div>)}
                  
                  
                  {i.recipeInstructions && (
                    <>
                      <div className="d-flex">
                        <Box sx={{marginBottom: 2}}>{capitalizeAndPunctuation(i.name, ':')}</Box>
                      </div>
                      
                      {i.metaIngredients?.length>0 && (
                        <Box sx={{marginBottom: '1rem'}}>
                          <ViewIngredients ingredients={i.metaIngredients} />
                        </Box>
                      )}
                      
                      {i.recipeInstructions.map((ii: IRecipeInstruction, idxx: number) => {
                        return (
                          <Fragment key={idxx}>
                            <div>
                              <InnerNumber>
                                {String.fromCharCode('a'.charCodeAt(null) + idxx)}
                              </InnerNumber>
                              <div>{capitalizeAndPunctuation(ii.name)}</div>
                            </div>
                            {ii?.recipeInstructions?.map((iii: IRecipeInstruction, idxxx: number) => {
                              return <div key={idxxx}>{capitalizeAndPunctuation(iii.name)}</div>
                            })
                            }
                          </Fragment>
                        )
                      })}
                    </>
                  )}
                </Box>
              </Grid>
            </Grid>
          )
        })}
      </Grid>
    </Grid>
  );
}

