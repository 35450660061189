import {styled} from "@mui/material/styles";
import Chip from "@mui/material/Chip";

export const ChipCalorie = styled(Chip)(
  ({theme}) => `
  position: absolute;
  background-color: white !important;
  top: 1rem;
  right: 1rem;
  z-index: 0;
  font-size: 1rem !important;
  color: ${theme.palette.warning.main};
  border: 2px solid ${theme.palette.warning.main};
  border-radius: 11rem;
`
);
