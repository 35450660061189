import React, { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import UserContext from './UserContext'
import {LOGIN} from '../routes'
import {LoadingPage} from "../components/Status/LoadingPage";

const PrivateRoute = ({children}): JSX.Element => {
  const { user, isLoading } = useContext(UserContext);
  
  if (isLoading) {
    return (<LoadingPage />)
  }
  
  if (user) {
   return children
  } else {
    return <Navigate replace to={LOGIN}/>
  }
  
}
export default PrivateRoute




