import {styled} from "@mui/material/styles";

const Ribbon = styled('span')(({theme}) => ({
    background: theme.palette.warning.main,
    height: '45px',
    width: '44px',
    left: '70%',
    zIndex: '10',
    position: 'absolute',
    textAlign: 'center',
    color: '#fff',
    lineHeight: '27px',
    border: '0',
    [':after']: {
      content: `""`,
      position: 'absolute',
      bottom: '-25px',
      left: '0',
      height: '0',
      width: '0',
      border: `solid 22px ${theme.palette.warning.main}`,
      borderBottomColor: 'transparent',
    },
    [theme.breakpoints.down('sm')]: {
      left: '40%',
    },
  }
))

export default Ribbon
