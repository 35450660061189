import {IBatch} from "../../../api/IRecipe";
import React from "react";
import {Box, Card, Chip, Button, CardContent, CardHeader, darken} from "@mui/material";
import BatchWithRecipes from "./BatchWithRecipes";
import {IOption, MoreMenu} from "../../../components/MoreMenu";
import {TypographyClamp3} from "../../../utils/Typography";

export const BatchCard = ({batch, onClick, options}:{batch:IBatch, onClick?: () => void, options?: IOption[]}) => {
  const batchName = batch.startDate ? `Batch du ${new Date(batch.startDate).toLocaleDateString()}` : batch.name
  return (
    <Card key={batch.id} sx={{marginTop: '2rem', width: '100%'}}>
      <CardHeader
        sx={theme => ({backgroundColor: `${theme.palette.mode === "light" ? 'whitesmoke' : darken(theme.palette.secondary.dark, 0.7)}`})}
        action={
          <>
            {onClick && <Button onClick={onClick}>Je commence</Button>}
            {options && <MoreMenu options={options}/>}
          </>
        }
        title={
          <Box>
            {batchName}
            {batch.status === "week" && <Chip sx={{marginLeft: '2rem'}} label="Batch en cours" color="primary" variant="outlined" />}
          </Box>
        }
            subheader={<TypographyClamp3>{batch.description}</TypographyClamp3>}
            />
            <CardContent>
              <BatchWithRecipes batch={batch}/>
            </CardContent>
          </Card>
        
  );
}
