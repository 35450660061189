import React, {useContext, useEffect, useState} from 'react'
import {Button, Tab, Tabs} from 'react-bootstrap'
import {IBatch} from '../../../api/IRecipe'
import {useParams} from 'react-router'
import {getSuggestedBatch} from '../../../api/api'
import IngredientList from '../../ingredient/list/IngredientList'
import UserContext from '../../../hooks/UserContext'
import {CHEF_SUGGESTED_BATCHES, START_SUGGESTED_BATCH} from '../../../routes'
import ActionBar from "../../../components/ActionBar";
import {useNavigate} from "react-router-dom";
import BatchWithRecipes from "../components/BatchWithRecipes";
import ExecutionCard from "../execution/ExecutionCard";

const SuggestedBatchPage = (): JSX.Element => {
  const navigate = useNavigate()
  const {user} = useContext(UserContext)
  const { batchId } = useParams();
  console.log(`==> Suggested batchId == ${batchId}`)
  const [batch, setBatch] = useState<IBatch>()
  const [error, setError] = useState(null)

  const batchLabel = batch && batch.name
  const breads = [
    {
      href: CHEF_SUGGESTED_BATCHES,
      children: 'Les Batchs'
    },
    {
      href: '',
      children: batchLabel
    }
  ]

  useEffect( () => {
    const fetch = async() => {
      try {
        console.log(`==> Fetch suggested batchId == ${batchId}`)
        const fetchedRecipe = await getSuggestedBatch(batchId)
        setBatch(fetchedRecipe)
      } catch (err) {
        setError(err)
      }
    }
    fetch()
  }, [batchId]);
  const PossibleActions = ({userRoles}: {userRoles: string[]}) => (
    <>
      {userRoles.includes('creator') && <Button className="ms-auto mx-2" variant="outline-primary" onClick={() => {window.print()}}>{'Imprimer'}</Button>}
      {userRoles.includes('creator') && <Button className="mx-2" onClick={() => {navigate(`${CHEF_SUGGESTED_BATCHES}/create`, {state: {isSuggestedBatch: true, suggestedBatch: batch}})}}>{'Editer'}</Button>}
    </>
  )
  
  return (
    <>
      <ActionBar
        breadcrumbs={breads}
        error={error}
        action={<PossibleActions userRoles={user.roles} />}
      />
      <div className="container mb-5 min-vh-100">
        {!error && (
          <Tabs className="mt-3 d-print-none" defaultActiveKey="menu" id="batch-display">
            <Tab eventKey="menu" title="Le menu">
              <BatchWithRecipes batch={batch} />
            </Tab>
            <Tab eventKey="shopping" title="La feuille de marché">
              <div className="container d-print-block">
                {batch && batch.ingredients && <IngredientList ingredients={batch.ingredients} />}
              </div>
            </Tab>
            <Tab eventKey="execution" title="Exécution" as={"div"}>
              <div className="p-2 white-background d-print-block">
                {batchId && <ExecutionCard isSuggested={true} batchId={batchId} setError={setError}/>}
              </div>
            </Tab>
          </Tabs>
        )}
      </div>
    </>
  )
}

export default SuggestedBatchPage
