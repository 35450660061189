import React from 'react'
import {INameQuantified, IRecipe} from '../../../api/IRecipe'
import {ingredientDisplay} from '../utils/Convertion'
import {Box, capitalize, Typography} from "@mui/material";
import {capitalizeAndPunctuation} from "../../../utils/Text";
import TitleTypography from "../../../components/TitleTypography";

interface IViewIngredientsAndToolsProps {
  recipe: IRecipe
}
const ViewIngredientsAndTools = ({recipe}: IViewIngredientsAndToolsProps): JSX.Element => {
  const groups = [...new Set(recipe.recipeIngredients.filter(i => i.for !== 'n/a' && i.for !== '').map(i => i.for))]
  
  return (
    <>
      <TitleTypography>Les ingrédients</TitleTypography>
      <Typography variant="subtitle1">
        pour {recipe.recipeYield} personnes
      </Typography>
      <Typography variant="subtitle2">
        {recipe.recipeIngredients.map((ing: INameQuantified, i: number) => {
          return (
            <div key={i}>
              {(ing.for === '' || ing.for === 'n/a') && ingredientDisplay(ing)}
            </div>
          )
        })}
      </Typography>
      <Typography variant="subtitle2">
        {groups.map((g, i) => (
          <div key={i}>
            <Typography sx={{fontWeight: '900'}}>{capitalizeAndPunctuation(g, ':')}</Typography>
            {recipe.recipeIngredients.map((ing: INameQuantified, ii: number) => {
              return (
                <div key={ii}>
                  {ing.for === g && ingredientDisplay(ing)}
                </div>
              )
            })}
          </div>
        ))}
      </Typography>
      {recipe.tool && recipe.tool.length > 0 && <TitleTypography marginTop={1}>Les ustensiles</TitleTypography>}
      {recipe.tool && recipe.tool.length > 0 && <Typography variant="subtitle2">
        {recipe.tool && recipe.tool.map((tool: INameQuantified, idx: number) => {
          return (
            <Box key={idx}>{tool.quantity !== 1 && tool.quantity} {capitalize(tool.name)}</Box>
          )
        })}
      </Typography>}
    </>
  )
}

export default ViewIngredientsAndTools
