import React from "react";
import './styles.scss';

const ANIMATED_OBJECT_NUMBER = 10;
export const AnimatedBackground = () => {
  const animatedObjects = [];
  for (let i = 0; i < ANIMATED_OBJECT_NUMBER; i++) {
    animatedObjects.push(<span key={i} className="banner-hex-animation"/>  );
  }
  
  return (
    <div className="animated-background">
      {animatedObjects}
    </div>
  )
}
