import React, {useContext, useEffect, useState, useRef} from 'react'
import {IRecipe} from '../../../api/IRecipe'
import { getRecipes, searchRecipes} from '../../../api/api'
import UserContext from '../../../hooks/UserContext'
import {Container, Box, Grid, IconButton} from "@mui/material";
import RecipeList from "./RecipeList";
import ActionBar from "../../../components/ActionBar";
import {PageHeader} from "../../../components/PageHeader";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {CREATE_RECIPE} from "../../../routes";
import {useDebounce} from "../../../utils/use-debounce";
import TableRowsIcon from '@mui/icons-material/TableRows';
import GridViewIcon from '@mui/icons-material/GridView';
import {useLocalStorage} from "../../../hooks/useLocalStorage";
interface IRecipeListPageProps {
  view?: string
  base?: boolean
}
const RecipeListPage = ({view, base}:IRecipeListPageProps): JSX.Element => {
  const loc = useLocation()
  const RECIPE_VIEW = "recipe_view"
  const [viewType, setViewType] = useLocalStorage<string>(
    RECIPE_VIEW,
    "RecipeCard",
  )
  const queryParameters = new URLSearchParams(loc.search)
  const [search, setSearch] = useSearchParams(queryParameters);
  const {user} = useContext(UserContext)
  const [recipes, setRecipes] = useState<{list:IRecipe[], next: string}>({list: [], next: ""})
  const [error, setError] = useState(null)
  const [page, setPage] = useState(1)
  const [refresh, setRefresh] = useState(false)
  const loader = useRef(null);
  const fetching = useRef(false);
  const navigate = useNavigate()
  
  const fetch = async(searchTerms: string) => {
    try {
      fetching.current = true
      if (searchTerms !== '') {
        const foundRecipes = await searchRecipes(searchTerms.trim())
        setRecipes(() => {
          return {list: foundRecipes, next: null}
        })
      } else {
        if(recipes.next == null) {
          return
        }
        let limit = 26
        const mediaQueryMedium = window.matchMedia("(max-width: 768px)")
        if (mediaQueryMedium.matches) {
          limit = 12
        }
        const mediaQueryXS = window.matchMedia("(max-width: 576px)")
        if (mediaQueryXS.matches) {
          limit = 12
        }
        const nextRecipes = await getRecipes(recipes.next, limit, false)
        const newList = recipes.list.concat(nextRecipes.recipes)
        setRecipes(() => {
          return {list: newList, next: nextRecipes.next}
        })
      }
    } catch (err) {
      setError(err)
    } finally {
      fetching.current = false
    }
  }
  
  // here we handle what happens when user scrolls to Load More div
  // in this case we just update page variable
  const handleObserver = (entities) => {
    const target = entities[0];
    if (target.isIntersecting) {
      setPage((p) => p + 1)
    }
  }
  const debounced = useDebounce(search.toString(), 700)
  useEffect( () => {
      if (!search || debounced.endsWith('=')) {
        setRecipes(() => {
          return {list: [], next: ""}
        })
        setPage(() => {return 1})
        return
      }
      if (debounced) {
        const words = debounced.split('=')
        let searchWord
        if (words?.length > 1) {
          searchWord = words[1]
        }
        fetch(searchWord)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debounced]);
  
  
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "20px",
      threshold: 1.0
    };
    // initialize IntersectionObserver
    // and attaching to Load More div
    const observer = new IntersectionObserver(handleObserver, options);
    if (loader.current) {
      observer.observe(loader.current)
    }
  }, []);
  
  useEffect(() => {
      setRecipes(() => {
        return {list: [], next: ""}
      })
      setPage(() => {return 1})
      setRefresh(false)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refresh])
  
  useEffect(() => {
      if (fetching.current) {
        return
      }
      fetch("")
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page])
  
  if (!recipes && !error) {
    return <></>
  }
  
  return (
    <Container>
      <ActionBar
        error={error}
        action={
          (user.roles.includes('creator') || user.roles.includes('apprentice')) ? (
            <div className={"ms-auto text-end"}>
              <PageHeader.Actions >
                <IconButton
                  onClick={() => {
                    if (viewType === "RecipeCard") {
                      setViewType("RecipeTable")
                    } else {
                      setViewType("RecipeCard")
                    }
                  }}>
                  {viewType === "RecipeCard" && (<GridViewIcon />)}
                  {viewType === "RecipeTable" && (<TableRowsIcon />)}
                </IconButton>
                <PageHeader.ActionItem
                  variant="contained"
                  onClick={() => navigate(`${CREATE_RECIPE}`)}>
                  Ajouter une recette
                </PageHeader.ActionItem>
              </PageHeader.Actions>
            </div>) : (
            <div className={"ms-auto text-end"}>
            </div>
          )
        }
      />
      <Box marginTop={1} maxWidth={'lg'}>
        {!error && (
          <>
            <Grid container spacing={{xs: 1}}>
              <RecipeList
                recipes={recipes.list}
                view={viewType}
                setError={setError}
                setRefresh={setRefresh}
              />
            </Grid>
            <div className="loading" ref={loader}>
              {recipes.next != null  && (<h3>Plus de recettes...</h3>)}
            </div>
          </>
        )}
      </Box>
    </Container>
  )
}
export default RecipeListPage
