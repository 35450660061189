import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import UserContext from './UserContext';
import {login, getUser, register} from '../api/api'
import {IUser} from '../api/IUser'
import {RECIPES} from "../routes";

interface IAuth {
  registerUser: (user: IUser) => void
  loginUser: (user: IUser) => void
  loginFacebook: (user: IUser) => void
  error: IError
}
interface IError {
  status_text?: string
  message?: string
}
export default function useAuth(): IAuth {
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);
  const [error, setError] = useState<IError>(null);

  //set user
  const setUserContext = async () => {
    try {
      const me = await getUser()
      setUser(me);
      navigate('/');
    } catch (err) {
      setError(err.response.data);
    }
  }

  //register user
  const registerUser = async ({firstname, lastname, email, password, captchaToken}: IUser) => {
    try {
      await register({
        firstname,
        lastname,
        email: email,
        password: password,
        captchaToken
      })
      navigate('/registered');
    } catch (err) {
      setError(err.response.data);
    }
  };

  // login user
  const loginUser = async ({email, password, captchaToken}: IUser) => {
    try {
      await login({email, password, captchaToken})
      await setUserContext();
    } catch (err) {
      setError(err.response.data);
    }
  }

  const loginFacebook = async ({accessToken}: IUser) => {
    try {
      await loginFacebook({accessToken})
      await setUserContext();
    } catch (err) {
      setError(err.response.data);
    }
  }

  return {
    registerUser,
    loginUser,
    loginFacebook,
    error
  }
}
