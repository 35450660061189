import Brightness4 from '@mui/icons-material/Brightness4'
import Brightness7 from '@mui/icons-material/Brightness7'
import IconButton from '@mui/material/IconButton'
import { useTheme } from '@mui/material/styles'
import {ThemeContext} from "../ThemeProvider";
import {useContext} from "react";



const rootComponentName = 'theme-toggle'

export const ThemeToggle = () => {
  const { palette } = useTheme()
  const setThemeName = useContext(ThemeContext)
  
  const toggle = () => {
    console.log(`palette.mode ${palette.mode}`)
    setThemeName(palette.mode === 'light' ? "NebularDarkTheme":"CuisinonsLightTheme")
  }
  
  return (
    <IconButton onClick={toggle} >
      {palette.mode === 'dark' ? (
        <Brightness7 htmlColor={palette.grey[50]}  />
      ) : (
        <Brightness4 htmlColor={palette.grey[500]}  />
      )}
    </IconButton>
  )
}
