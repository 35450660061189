import {INameQuantified} from "../api/IRecipe";
import {isNumber} from "./Text";

export const convertIngredientsQuantity = (ingredients: INameQuantified[]) => {
  return ingredients.map(ing => {
    let temp = ing.quantity
    if (typeof ing.quantity === 'string') {
      const q = ing.quantity.replace(',', '.')
      if(isNumber(q)) {
        temp = +q
      } else {
        temp = 0
      }
    }
    return {...ing, quantity: temp}
  })
}
