import React, {useState} from 'react'
import {IBatch, IRecipe} from '../../../api/IRecipe'
import {saveBatch, saveSuggestedBatch} from '../../../api/api'
import StepOne from './StepOne'
import StepTwo from './StepTwo'
import StepThree from './StepThree'
import {useLocation} from 'react-router'
import Wizard from "../../../components/wizard/Wizard";
import {useNavigate} from "react-router-dom";
import {CHEF_SUGGESTED_BATCHES, MY_SPACE, OUR_SUGGESTED_BATCHES} from "../../../routes";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';

const BatchWizardPage = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation()
  const isSuggestedBatch = (location.state as any)?.isSuggestedBatch

  const suggestedBatch = (location.state as any)?.suggestedBatch
  let breads = [
    {
      url: MY_SPACE,
      label: 'Mon espace'
    },
    {
      url: '',
      label: 'Mon Batch'
    }
  ]
  if (isSuggestedBatch) {
    breads = [
      {
        url: CHEF_SUGGESTED_BATCHES,
        label: 'Les Batchs'
      },
      {
        url: '',
        label: 'Le Batch'
      }]
  }

  const [saving, setSaving] = useState<boolean|undefined>();

  let initialBatch
  if (isSuggestedBatch && suggestedBatch) {
    initialBatch = suggestedBatch // edition of suggestedBatch
  } else {
    if (suggestedBatch) {
      initialBatch = {...suggestedBatch, startDate: undefined} //start a user batch from suggestesd batch
    }
    // initialBatch says undefined when starting batch from scratch
  }

  const [selectedRecipes, setSelectedRecipes] = useState(suggestedBatch ? Object.values(suggestedBatch.recipes) as IRecipe[] : []) // starts a user batch from a suggested batch
  const [batch, setBatch] = useState<IBatch>(initialBatch)//isSuggestedBatch && suggestedBatch ? suggestedBatch : undefined) // edition of suggestedBatch
  const [batchId, setBatchId] = useState<string>(isSuggestedBatch && suggestedBatch ? suggestedBatch.id : undefined)
  const [error, setError] = useState()
  const onSave = async (status: string, isSuggested = false) => {
    let result
    try {
      if (isSuggested) {
        result = await saveSuggestedBatch({
          ...batch,
          id:batchId,
          status,
          recipeYield: 4})
        setBatchId(result.id)
        setBatch({
          ...result
        })
      } else {
        result = await saveBatch({
          ...batch,
          id:batchId,
          recipeYield: 4})
        setBatchId(result.id)
        setBatch({
          ...result
        })
      }
      return result
    } catch (e) {
      console.log(`ERROR: ${e.message}`)
      setError(e.message)
    }
  }
  const navigateToBatchList = () => {
    if (isSuggestedBatch) {
      navigate(CHEF_SUGGESTED_BATCHES)
    } else {
      navigate(MY_SPACE)
    }
  }

  const steps = [
    {
      component: <StepOne selection={selectedRecipes} setSelection={setSelectedRecipes}/>,
      nextButtonLabel: <KeyboardDoubleArrowRightIcon />,
      previousButtonLabel: null,
    },
    { component: <StepTwo suggestedBatch={isSuggestedBatch} selectedRecipes={selectedRecipes} batch={batch} setBatch={setBatch} />,
      nextButtonLabel: <KeyboardDoubleArrowRightIcon />,
      onNext: async () => {
        setSaving(true)
        const b = await onSave('draft', isSuggestedBatch)
        if (b.id) {
          setSaving(false)
        }
      },
      previousButtonLabel: <KeyboardDoubleArrowLeftIcon />
    },
    { component: <StepThree batch={batch}/>,
      nextButtonLabel: 'Go',
      onNext: async () => {
        await onSave('final', isSuggestedBatch)
        navigateToBatchList()
      },
      previousButtonLabel: <KeyboardDoubleArrowLeftIcon />
    }
  ]

  return (<Wizard steps={steps} breadcrumbs={breads} saving={saving} error={error}/>)
}

export default BatchWizardPage
