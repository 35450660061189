import React from 'react'
import {IRecipe} from '../../../api/IRecipe'
import ViewIngredientsAndTools from './ViewIngredientsAndTools'
import {
  Box,
  Card,
  Grid,
  Typography
} from "@mui/material";
import {ViewInstructions} from "./ViewInstructions";
import {useLocation} from "react-router";
import CardMedia from "@mui/material/CardMedia";
import {ChipCalorie} from "../ChipCalorie";
import {baseURL} from "../../../api/api";
import NutrientsTable from "./NutrientsTable";
import {RecipeViewSkeleton} from "./RecipeViewSkeleton";
import {CardOverlay} from "../../../components/RecipeCard/CardOverlay";
import {CardOverlayContent} from "../../../components/RecipeCard/CardOverlayContent";
import {BASE_RECIPE, LOGIN} from "../../../routes";
import {NotLoggedInWarning} from "../../../components/notloggedin/NotLoggedInWarning";
import TitleTypography from "../../../components/TitleTypography";
import Ribbon from "../../../components/Ribbon";

interface IRecipeViewProps {
  recipe: IRecipe
}

const RecipeView = ({recipe} : IRecipeViewProps): JSX.Element => {
  const location = useLocation();

  if (!recipe) {
    return <RecipeViewSkeleton />
  }

  const imageURL = (recipe?.image?.length > 0) ? `${baseURL}/v1/images/${recipe?.image[0]?.contentUrl}` : "https://via.placeholder.com/800x600"
  const nutrients = recipe?.nutrients
  const cal = nutrients?.find(m => m.name === 'énergie' && m.unit==='kcal').value

  let baseRecipe = false;
  if (location?.pathname?.includes(BASE_RECIPE)) {
    baseRecipe = true;
  }

  return (
    <>
      <Grid container spacing={{sm: 2}} marginTop={{xs: 1, sm: 0}}>
        <Grid item xs={12} sm={6}>
          <Card sx={{display: 'inline-flex', displayPrint: 'none', position: 'relative'}}>
            {recipe.status === "draft" || !recipe.status && <Ribbon>Draft</Ribbon>}
            <CardMedia
              component="img"
              image={imageURL}
              alt={recipe?.image[0]?.caption}
            />
            <CardOverlay>
              <CardOverlayContent recipe={recipe} fullWidth/>
            </CardOverlay>
            {nutrients && <ChipCalorie label={`${cal} kcal`}/>}
          </Card>
          {recipe?.description && <Typography marginTop={1}>
            {recipe?.description}
          </Typography>}
          {recipe?.suggestion && recipe?.suggestion.length > 0 && (
            <Box sx={{marginTop: '16px'}}><TitleTypography>Les suggestions du chef</TitleTypography></Box>
          )}
          {recipe?.suggestion?.map((s, idx) => (
            <Typography key={idx} marginTop={1}>
              {s.value}
            </Typography>
          ))}
        </Grid>
        <Grid item xs={12} sm={6} sx={{ displayPrint: 'none'}}>
          {nutrients && (<NutrientsTable
            nutrients={nutrients}
            allergens={recipe?.allergens}
            suitableForDiet={recipe?.suitableForDiet}/>)}
        </Grid>
      </Grid>

      <Grid container sx={{marginY: '2rem'}}>
        <Grid item sm={3}>
          <ViewIngredientsAndTools recipe={recipe}/>
        </Grid>
        <Grid item sm={9}>
          <ViewInstructions
            recipe={recipe}
            title={baseRecipe ? "Les instructions de la recette de base" : "Les instructions du lot"}
            recipeInstructions={recipe?.recipeInstructions.filter(r => r.when !== 'serving')}/>
          {!baseRecipe &&
            recipe?.recipeInstructions.filter(r => r.when === 'serving').length !== 0 && (
              <ViewInstructions
                recipe={recipe}
                title={"Le jour du service"}
                recipeInstructions={recipe?.recipeInstructions.filter(r => r.when === 'serving')}/>
            )}
          <NotLoggedInWarning />
        </Grid>
      </Grid>
    </>
  );
}

export default RecipeView
