import { useNavigate } from 'react-router-dom';
import {logout} from '../api/api'
import {useContext} from 'react'
import UserContext from './UserContext'

interface ILougout {
  logoutUser: () => void
}

const useLogout = (): ILougout => {
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);

  const logoutUser = async () => {
    try {
      console.log(`here logout`)
      await logout()
      if(setUser) {
        setUser(null)
      }
      navigate('/', {replace: true});
      location.reload();
    } catch(err) {
      console.log(err);
    }
  }

  return {
    logoutUser
  }
}
export default useLogout
