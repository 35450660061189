import React, {useEffect} from 'react'
import {Button, Card, Col, Container, Row} from 'react-bootstrap'
import {resendVerify} from '../../api/api'

const RegisteredPage = (): JSX.Element => {
  useEffect(() => {
    document.body.classList.add('login-background')
    return () => {
      document.body.classList.remove('login-background');
    }
  })
  return (
    <Container>
      <Row className={"vh-100"}>
        <Col className="my-auto" lg={{offset: 3, span: 6}}>
          <Card className={"recipe-card"}>
            <Container className={"login-tab-mh"}>
              <Row className={"p-5 border-top"}>
                <Col className={"h-100"}>
                  <h2 className="mb-5">Bienvenue chez Cuisinons</h2>
                  <p>{"Vous venez de vous enregister, votre demande est presque complete, il ne vous reste plus qu'à confirmer votre adresse email. Nous vous avons envoyé un mail, vérifiez votre boîte mail."}</p>
                  <p className={"mt-3"}>{"Si vous n'avez pas reçu d'email dans les prochaines minutes, vous pouvez cliquer le bouton ci-dessous."}</p>
                  <Row className={"justify-content-center"}><Button className="mt-5" variant={"primary"} onClick={() => {
                    resendVerify()
                  }}>Renvoyez moi le mail! </Button></Row>
                </Col>
              </Row>
            </Container>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}
export default RegisteredPage
