import React, {useState, ChangeEvent, useEffect} from 'react'
import {FormControl, FormGroup, FormLabel, Row} from 'react-bootstrap'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { DateRangePicker, FocusedInputShape } from 'react-dates'
import {IBatch, IRecipe} from '../../../api/IRecipe'
import MenuList from './grid/MenuList'

interface IStepTwoProps {
  selectedRecipes: IRecipe[]
  batch?:IBatch
  setBatch: (b: IBatch) => void,
  suggestedBatch?: boolean
}

import * as Moment from 'moment';
import mo from 'moment'
import { extendMoment } from 'moment-range';
import {capitalize} from "@mui/material";

const moment = extendMoment(Moment);
moment.locale('fr-fr')
const MAX_NB_OF_DAYS = 5

const StepTwo = ({selectedRecipes, batch, setBatch, suggestedBatch = false}: IStepTwoProps): JSX.Element => {
  const fillWeekDays = (sDate: Moment.Moment, days: number) => {
    const weekDaysArr = []
    for (let i = 0; i< days; i++) {
      if (suggestedBatch) {
        weekDaysArr.push(`Jour ${i + 1}`)
      } else {
        const d = mo(sDate).add(i, 'days')//new Date(sDate.getTime() + i*(24 * 60 * 60 * 1000))
        const dayString = capitalize(d.format('dd-DD-MM'));//toDate().toLocaleDateString("fr-FR", { weekday: 'short', month: 'short', day: 'numeric' }))
        weekDaysArr.push(dayString)
      }
    }
    return weekDaysArr
  }
  const now = mo()
  const [batchDate, setBatchDate] = useState({
    s:now,
    e:mo(now).add(MAX_NB_OF_DAYS-1, 'days'),
    weekDays: fillWeekDays(mo(), batch ? Object.keys(batch.recipesByDay).length : MAX_NB_OF_DAYS)
  })

  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(
    null
  )
  
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, [])
  
  const handlendDatesChange = (arg: {
    startDate: moment.Moment | null
    endDate: moment.Moment | null
  }) => {
    console.log(`>>>>>>>>> ARG Start ${arg.startDate?.format('dd-DD-MM')}`)
    console.log(`>>>>>>>>>>>ARG End ${arg.endDate?.format('dd-DD-MM')}`)
    console.log(`>>START: ${arg.startDate?.format('dd-DD-MM')} END ${arg.endDate?.format('dd-DD-MM')} DIFF${arg.startDate?.diff(arg.endDate, 'days')}`)
    const numberOfDays = arg.startDate && arg.endDate ? Math.abs(arg.startDate?.diff(arg.endDate, 'days')) + 1 : MAX_NB_OF_DAYS
    const weekDaysArr = fillWeekDays(arg.startDate, numberOfDays)
    console.log(`>>numberOfDays: ${numberOfDays} weekDaysArr: ${JSON.stringify(weekDaysArr)}`)
  
    setBatchDate({
      s: arg.startDate,
      e: arg.endDate,
      weekDays: weekDaysArr
    })
  }
  
  const handleFocusChange = (arg: FocusedInputShape | null) => {
    setFocusedInput(arg)
  }
  
  const [batchName, setBatchName] = useState(batch && batch.name ? batch.name : '')
  const [batchDescription, setBatchDescription] = useState(batch && batch.description ? batch.description : '')
  const onBatchNameChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const value = target.value;
    setBatchName(value);
    setBatch({...batch, name: value, description: batchDescription})
  }
  const onBatchDescriptionChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const value = target.value;
    setBatchDescription(value);
    setBatch({...batch, name: batchName, description: value})
  }
  const setBatchWrapper = (b: IBatch) => {
    setBatch({...b, name: batchName, description: batchDescription})
  }
  
    return (
    <div className="mt-lg-5 container">
      {!suggestedBatch && (
        <Row className="m-lg-5 m-2">
          <DateRangePicker
            readOnly
            startDate={batchDate.s}
            startDateId="your_unique_start_date_id"
            endDate={batchDate.e}
            endDateId="your_unique_end_date_id"
            onDatesChange={handlendDatesChange}
            focusedInput={focusedInput}
            onFocusChange={handleFocusChange}
            numberOfMonths={1}
            // isOutsideRange={(day: Moment.Moment) => {
            //   console.log(`day ${day.format("dd-DD-MM-YYYY")}`)
            //   const range = moment.range(batchDate.s, mo(batchDate.s).add(5, 'days'));
            //   return !day.within(range)
            // }}
          />

        </Row>)}
      {suggestedBatch && (
        <div className="card m-2 recipe-card p-5">
          <div>
            <FormGroup className="mb-2" controlId="batchName">
              <FormLabel>Nom du batch</FormLabel>
              <FormControl
                className="form-control w-50"
                name="batchName"
                value={batchName}
                onChange={onBatchNameChange}
              />
            </FormGroup>
          </div>
          <div>
            <FormGroup className="mb-2" controlId="batchDescription">
              <FormLabel>Description du batch</FormLabel>
              <FormControl
                className="form-control"
                as="textarea"
                name="batchDescription"
                value={batchDescription}
                onChange={onBatchDescriptionChange}
              />
            </FormGroup>
          </div>
        </div>
      )}
      {selectedRecipes && selectedRecipes.length > 0 && batchDate.s && batchDate.e && batchDate.weekDays && batchDate.weekDays.length > 0  && (
        <MenuList
          selectedRecipes={selectedRecipes}
          days={batchDate.weekDays}
          startDate={batchDate.s.toDate().toUTCString()}
          batch={batch}
          setBatch={setBatchWrapper}/>)}
    </div>
  )
}
export default StepTwo
