import { DragDropContext } from 'react-beautiful-dnd';
import React, {useEffect, useState} from 'react'
import {IRecipe, IBatch, IRecipesByDay} from '../../../../api/IRecipe'
import Column from './Column'

interface IMenuListProps {
  selectedRecipes: IRecipe[]
  days: string[]
  startDate: string
  batch?: IBatch
  setBatch: (b: IBatch) => void
}
const convertArrayToObject = (array, key) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item,
    };
  }, initialValue)
}

const proposedMenuForRecipeSelection = (idsList: string[], numberDays: number): string[][] => {
  let nbOfRecipesPerDay =  Math.floor(idsList.length / numberDays)
  const modulo = idsList.length % numberDays
  if (modulo > 0) {
    nbOfRecipesPerDay = nbOfRecipesPerDay + 1
  }
  const recipesPerDays: string[][] = []
  for (let j = 0; j < numberDays; j++) {
    recipesPerDays.push([])
  }
  for (let i = 0; i < nbOfRecipesPerDay; i++) {
    for (let j = 0; j < numberDays; j++) {
      if(idsList.length !== 0) {
        recipesPerDays[j].push(idsList.pop())
      }
    }
  }
  return recipesPerDays
}

const areArraysEqualsWithoutOrder = (array1: string[], array2: string[]): boolean => {
  const array2Sorted = array2.slice().sort();
  return array1.length === array2.length && array1.slice().sort().every(function(value, index) {
    return value === array2Sorted[index];
  })
}

const MenuList = ({selectedRecipes, days, startDate, batch, setBatch}: IMenuListProps):JSX.Element => {
  const [state, setState] = useState<IBatch>({
    recipeYield: batch?.recipeYield,
    status: batch?.status,
    startDate,
    recipesByDay: batch?.recipesByDay,
    recipes: batch?.recipes || convertArrayToObject(selectedRecipes, 'id')})
  
  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result
    
    if (!destination) {
      return
    }
    
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return
    }
    
    const start = state.recipesByDay[source.droppableId]
    const finish = state.recipesByDay[destination.droppableId]
    
    if (start === finish) {
      const newRecipeIds = Array.from(start.recipeIds)
      newRecipeIds.splice(source.index, 1)
      newRecipeIds.splice(destination.index, 0, draggableId)
      
      const newColumn = {
        ...start,
        recipeIds: newRecipeIds,
      }
      const newState = {
        ...state,
        recipesByDay: {
          ...state.recipesByDay,
          [newColumn.dayRank]: newColumn,
        }
      }
      
      setState(newState)
      setBatch({recipeYield: 4, status: 'draft', startDate: startDate, recipesByDay: newState.recipesByDay})
      return
    }
    
    // Moving from one list to another
    const startRecipeIds = Array.from(start.recipeIds);
    startRecipeIds.splice(source.index, 1);
    const newStart = {
      ...start,
      recipeIds: startRecipeIds,
    }
    
    const finishRecipeIds = Array.from(finish?.recipeIds || []);
    finishRecipeIds.splice(destination.index, 0, draggableId);
    const newFinish = {
      ...finish,
      recipeIds: finishRecipeIds,
    }
    
    const newState = {
      ...state,
      recipesByDay: {
        ...state.recipesByDay,
        [newStart.dayRank]: newStart,
        [newFinish.dayRank]: newFinish,
      }
    }
    setState(newState)
    setBatch({recipeYield: 4, status: 'draft', startDate, recipesByDay: newState.recipesByDay})
  }
  
  const isEmpty = (recipesByDay) => {
    if (recipesByDay) {
      const rr: IRecipesByDay[] = Object.values(recipesByDay)
      for (const k in rr) {
        if (rr[k].recipeIds && rr[k].recipeIds.length > 0) {
          return false
        }
      }
    }
    
    return true
  }
  
  useEffect(() => {
       const recipesPerDays = proposedMenuForRecipeSelection([...(selectedRecipes.map(r=>r.id))], days.length || 0)
      const colArray = days.map((d, i) => {
        if (!state || !state.recipesByDay) { // initial batch - not yet stored in backend
          return {
            dayRank: `day-${i}`,
            day: d,
            recipeIds: recipesPerDays[i]
          }
        }
        
        // Reshuffle if number of days changed
        if(days.length !== Object.keys(state.recipesByDay).length) {
          return {
            dayRank: `day-${i}`,
            day: d,
            recipeIds: recipesPerDays[i]
          }
        }
        
        // Reshuffle if selection changed
        let ids = []
        if (state.recipesByDay) {
          const rr: IRecipesByDay[] = Object.values(state.recipesByDay)
          for (const k in rr) {
            ids = ids.concat(rr[k].recipeIds)
          }
          
          const flatten = selectedRecipes.map(r=>r.id)
          const idsWithoutNull = ids.filter(n=>n)
          if (!areArraysEqualsWithoutOrder(flatten, idsWithoutNull)) {
            return {
              dayRank: `day-${i}`,
              day: d,
              recipeIds: recipesPerDays[i]
            }
          }
        }
        
        // keep
        return {
          dayRank: `day-${i}`,
          day: d,
          recipeIds: state.recipesByDay && !isEmpty(state.recipesByDay) && state.recipesByDay[`day-${i}`].recipeIds
        }
      })
      
      const recipesByDayObj = convertArrayToObject(colArray, 'dayRank')
      const recipes = convertArrayToObject(selectedRecipes, 'id')
      
      const newState = {
        ...state,
        recipeYield: 4, status: 'draft',
        recipes,
        recipesByDay: recipesByDayObj
      }
      setState(newState)
      setBatch({recipeYield: 4, status: 'draft', startDate, recipesByDay: newState.recipesByDay})
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [days, selectedRecipes])
  
  if (!days || days.length === 0 || !selectedRecipes) {
    return <></>
  }
  
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="d-flex overflow-scroll">
        {state && state.recipesByDay && Object.keys(state.recipesByDay).map(columnId => {
          return <Column
            key={`column-${columnId}`} recipesByDay={state.recipesByDay[columnId]}
            recipes={state.recipesByDay[columnId]?.recipeIds?.map(
              rId => state.recipes[rId],
            ).filter(r=> r!==undefined)} />;
        })}
      </div>
    </DragDropContext>
  );
}

export default MenuList
