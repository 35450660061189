export const LOGIN = '/login'
export const REGISTERED = '/registered'
export const PREFERENCES = '/preferences'

export const MY_SPACE = '/mon_espace'
export const MY_BATCHES = '/mes_batchs'
export const START_BATCH = '/mes_batchs/create'

export const OUR_SUGGESTED_BATCH = '/nos_suggestions/mon_batch/'
export const OUR_SUGGESTIONS = '/nos_suggestions'
export const OUR_SUGGESTED_BATCHES = '/nos_suggestions/batches'


//export const START_BATCH = '/creer_batch'
export const START_SUGGESTED_BATCH = '/nos_suggestions/créer_batch'

export const CHEF_SUGGESTED_BATCHES = '/chef/batches'
export const CREATE_SUGGESTED_BATCH = '/chef/batches/create'

export const BASE_RECIPE_EDITION = '/chef/éditer_technique/'

// les recettes
export const RECIPES = '/les_recettes'
export const RECIPE = '/les_recettes/'
export const CREATE_RECIPE = '/les_recettes/create'

// Recettes de base
export const BASE_RECIPES = '/chef/les_techniques'
export const BASE_RECIPE = '/chef/les_techniques/'
export const CREATE_BASE_RECIPE = '/chef/les_techniques/create'

