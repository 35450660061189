import { Droppable } from 'react-beautiful-dnd'
import React from 'react'
import {IRecipe} from '../../../../api/IRecipe'
import ColumnItem from './ColumnItem'
import cn from 'classnames'

interface IRecipesByDay {
  dayRank: number
  day: string
  recipeIds: string[]
}
interface IRecipesByDayProps {
  recipesByDay: IRecipesByDay
  recipes: IRecipe[]
}
const Column = ({recipesByDay, recipes}: IRecipesByDayProps): JSX.Element => {
  return (
    <div className="mt-4 column recipe-card">
      <div className="title text-center text-muted food-font">{recipesByDay.day}</div>
      <Droppable droppableId={recipesByDay.dayRank}>
        {(provided, snapshot) => (
          <div className={cn('recipes-list', {'recipes-list-dragging': snapshot.isDraggingOver})}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {recipes?.map((r, index) => (
              <ColumnItem key={r.id} recipe={r} index={index} />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
}

export default Column
