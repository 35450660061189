import React from 'react'

/**
 * Creates a strictly typed context and returns a provider along with a hook that checks for existence.
 *
 * @param options - An object with options for the context
 * @param options.errorMessage - The error message that is thrown when using the hook without a provider
 * @param options.name - The name of the context, used as displayName
 * @returns A tuple containing a context provider and strict useContext hook
 */
export function createStrictContext<T>(options: { errorMessage?: string; name?: string } = {}) {
  const Context = React.createContext<T | undefined>(undefined)
  Context.displayName = options.name

  function useContext() {
    const context = React.useContext(Context)
    if (context === undefined) {
      throw new Error(options.errorMessage || `${options.name || ''} Context Provider is missing`)
    }
    return context
  }

  return [Context.Provider, useContext] as [React.Provider<T>, () => T]
}
