import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";

export const CardOverlay = styled(Box)(
  () => `
  position: absolute;
  background-color: white !important;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3rem;
  opacity: .9;
  text-align: end;
  display: inline-flex;
  align-items: center;
`
);
