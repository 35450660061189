import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import React from "react";

export const Copyright = (props: any) => {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link  href="https://cuisinons.org">
        cuisinons.org
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
