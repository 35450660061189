import {IRecipe} from '../../../api/IRecipe'
import {Col, Container, Dropdown, Table} from 'react-bootstrap'
import React, {useState} from 'react'
import {deleteBaseRecipe, deleteRecipe, publishRecipe, unPublishRecipe} from '../../../api/api'
import {BASE_RECIPE, BASE_RECIPE_EDITION, RECIPE, RECIPES} from "../../../routes";
import {useNavigate} from "react-router-dom";
import {ConfirmationModal} from "../../../components/ConfirmationModal";
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import {IOption, MoreMenu} from "../../../components/MoreMenu";

interface IRecipeTableProps {
  recipes: IRecipe[]
  setError: (error: string) => void
  setRefresh?: (n:boolean) => void
  base?: boolean
}
const RecipeTable = ({recipes, setError, setRefresh, base = false}: IRecipeTableProps): JSX.Element => {
  const [showDeleteModal, setShowDeleteModal] = useState<IRecipe>(null)
  const navigate = useNavigate()
  const RECIPE_URL = base ? BASE_RECIPE : RECIPE;
  
  const onDelete = async () => {
    const fn = base ? deleteBaseRecipe : deleteRecipe
    try {
      await fn(showDeleteModal.id)
      if (setRefresh) {setRefresh(true)}
    } catch (err) {
      setError(err)
    }
  }
  
  const onPublish = async (r: IRecipe) => {
    const status = r.status === "published" ? unPublishRecipe : publishRecipe
    try {
      await status(r.id)
      if (setRefresh) {setRefresh(true)}
    } catch (err) {
      setError(err)
    }
  }
  
  return (
    <Container style={{fontSize: '14px'}}>
      <Col className="mt-md-3 table-responsive-lg" xs={12}>
        <ConfirmationModal
          show={showDeleteModal?true:false}
          onHide={() => setShowDeleteModal(null)}
          recipe={showDeleteModal}
          onClick={() => {
            onDelete();
            setShowDeleteModal(null)
          }}/>
        <Table size={'sm'} striped hover>
          <thead>
          <tr>
            <td style={{width: 200}}>Nom</td>
            {!base && <td style={{width: 300}}>Description</td>}
            <td style={{width: 200}}>Ingrédients, Calories/personne</td>
            <td style={{width: 200}}>Coût/plat</td>
            <td style={{width: 200}}>Ustensiles</td>
            {!base && <td>Photo</td>}
            {!base && <td>Status</td>}
            <td>Actions</td>
          </tr>
          </thead>
          <tbody>
          {recipes?.map((r, idx) => {
            const nutrients = r?.nutrients
            const cal = nutrients?.find(m => m.name === 'énergie' && m.unit==='kcal').value
            const options: IOption[] = [
              {
                name: "Editer",
                action: () => navigate(`${RECIPE_URL}${r.id}/edit`)
              },
              {
                name: "Copier",
                action: () => {navigate(`${RECIPE}${r.id}/edit`, {state:{copy: true}})}
              },
              {
                name: "Supprimer",
                action: () => {setShowDeleteModal(r)}
              }
            ]
            if (!base) {
              options.push({
                name: r.status === "published" ? "Mettre en draft" : "Publier",
                action: () => onPublish(r)
              })
            }
            return (
              <tr key={idx}>
                <td style={{width: 400}} className="text-primary"><a target={"_blank"} rel="noopener noreferrer" href={`${RECIPE_URL}${r.id}`}>{r.name}</a> </td>
                {!base && <td style={{width: 300}} className={"truncate"}>
                  {r.description && <CheckIcon color={'success'}/>}
                  {!r.description && <ClearIcon color={'error'}/>}
                </td>}
                <td style={{width: 100}} onClick={() => navigate(`${RECIPE_URL}${r.id}`)}>
                  {nutrients && nutrients?.find(e=> e.name === 'énergie').value !== 0 && <span className="primary-color ">{cal} kcal</span>}
                  {nutrients && nutrients?.find(e=> e.name === 'énergie').value === 0 && <span className="danger-color">Sans ingrédient</span>}
                  {!nutrients && <span className="danger-color ">Ingrédient inconnu</span>}
                </td>
                <td style={{width: 100}} onClick={() => navigate(`${RECIPE_URL}${r.id}`)}>
                  {r.estimatedCost && r.estimatedCost.value !== 0 &&  <span className="primary-color">{`${Number((r?.estimatedCost?.value).toFixed(2))} €`}</span>}
                  {r?.estimatedCost?.value === 0 &&  <span className="danger-color">Sans ingrédient</span>}
                  {!r.estimatedCost && <span className="danger-color ">Sans côte</span>}
                </td>
                <td style={{width: 100}} onClick={() => navigate(`${RECIPE_URL}${r.id}`)}>
                  {r?.tool && r?.tool?.length > 0 && <span className="primary-color"><CheckIcon color={'success'}/></span>}
                  {(!r?.tool || r?.tool?.length === 0) && <ClearIcon color={'error'}/>}
                </td>
                {!base && <td onClick={() => navigate(`${RECIPE_URL}${r.id}`)}>
                  {r.image && r.image[0] && r.image[0].contentUrl && <CheckIcon color={'success'}/>}
                  {(!r.image || !r?.image[0] || !r?.image[0]?.contentUrl) && <ClearIcon color={'error'}/>}
                </td>}
                {!base && <td onClick={() => navigate(`${RECIPE_URL}${r.id}`)}>{r.status === 'published'?(<span className="primary-color">Publiée</span>):(<span className="danger-color">Draft</span>)}</td>}
                <td>
                  <MoreMenu options={options} />
                </td>
              </tr>)
          })
          }
          </tbody>
        </Table>
      </Col>
    </Container>)
}

export default RecipeTable
