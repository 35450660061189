import React, {useEffect, useRef, useState} from 'react'
import {Button, Offcanvas, Row} from 'react-bootstrap'
import RecipeList from '../../recipe/list/RecipeList'
import {IRecipe} from '../../../api/IRecipe'
import {getRecipes, searchRecipes} from '../../../api/api'
import RecipeCardTiny from "./RecipeCardTiny";
import {useDebounce} from "../../../utils/use-debounce";
import {Grid} from "@mui/material";
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import {useSearchParams, useLocation} from "react-router-dom";

interface IStepOneProps {
  selection: IRecipe[]
  setSelection: (l: IRecipe[]) => void
}
const StepOne = ({ selection, setSelection}: IStepOneProps): JSX.Element => {
  const location = useLocation()
  const queryParameters = new URLSearchParams(location.search)

  const [search, setSearch] = useSearchParams(queryParameters);
  const [recipes, setRecipes] = useState<{list:IRecipe[], next: string}>({list: [], next: ""})
  const [error, setError] = useState(null)
  const [page, setPage] = useState(1)
  const [refresh, setRefresh] = useState(false)
  const loader = useRef(null);
  const fetching = useRef(false);
  const debounced = useDebounce(search.toString(), 700);
  const [showSelectedList, setShowSelectedList] = useState(selection.length > 0 ? true : false)
  
  const fetch = async(searchTerms: string) => {
    try {
      fetching.current = true
      if (searchTerms !== '') {
        const foundRecipes = await searchRecipes(searchTerms.trim())
        setRecipes(() => {
          return {list: foundRecipes, next: null}
        })
      } else {
        if(recipes.next == null) {
          return
        }
        let limit = 26
        const mediaQueryMedium = window.matchMedia("(max-width: 768px)")
        if (mediaQueryMedium.matches) {
          limit = 12
        }
        const mediaQueryXS = window.matchMedia("(max-width: 576px)")
        if (mediaQueryXS.matches) {
          limit = 12
        }
        const nextRecipes = await getRecipes(recipes.next, limit, false)
        const newList = recipes.list.concat(nextRecipes.recipes)
        setRecipes(() => {
          return {list: newList, next: nextRecipes.next}
        })
      }
    } catch (err) {
      setError(err)
    } finally {
      fetching.current = false
    }
  }
  
  // here we handle what happens when user scrolls to Load More div
  // in this case we just update page variable
  const handleObserver = (entities) => {
    const target = entities[0];
    if (target.isIntersecting) {
      setPage((p) => p + 1)
    }
  }
  useEffect(() => {
    if (selection?.length > 0) {
      setShowSelectedList( true)
    }
  }, [selection])
  useEffect( () => {
      if (!search || debounced.endsWith('=')) {
        setRecipes(() => {
          return {list: [], next: ""}
        })
        setPage(() => {return 1})
        return
      }
      if (debounced) {
        const words = debounced.split('=')
        let searchWord
        if (words?.length > 1) {
          searchWord = words[1]
        }
        fetch(searchWord)
      }
      
      
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debounced]);
  
  
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "20px",
      threshold: 1.0
    };
    // initialize IntersectionObserver
    // and attaching to Load More div
    const observer = new IntersectionObserver(handleObserver, options);
    if (loader.current) {
      observer.observe(loader.current)
    }
  }, []);
  
  useEffect(() => {
      setRecipes(() => {
        return {list: [], next: ""}
      })
      setPage(() => {return 1})
      setRefresh(false)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refresh])
  
  useEffect(() => {
      if (fetching.current) {
        return
      }
      fetch("")
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page])
  
  
  if (!recipes && !error) {
    return <>Loading</>
  }
  
  const onSelect = (r: IRecipe, add): void => {
    const idx = selection.findIndex(re => re.id === r.id)
    if (add) {
      setSelection([r, ...selection]) // Add
    } else {
      setSelection([...selection.slice(0, idx), ...selection.slice(idx+1)]) // Delete
    }
  }
  
  return (
    <div className="mt-md-3">
      <div className={"footer-sticky"}>
        <div className="icon">
          <Button variant="primary"
                  className={"position-fixed bottom-0 end-0 me-2 mb-2 high-z-index"}
                  onClick={() => setShowSelectedList(!showSelectedList)}>
            {showSelectedList && <KeyboardDoubleArrowDownIcon/>}
            {!showSelectedList && <KeyboardDoubleArrowUpIcon/>}
          </Button>
        </div>
        <Offcanvas
          className="offcanvas-transparent"
          show={showSelectedList}
          placement={"bottom"}
          scroll={true}
          backdrop={false}
          onHide={() => setShowSelectedList(false)}>
          <Offcanvas.Body>
            <div className={"d-flex overflow-scroll"}>
              <Button variant="primary"
                      className={"position-fixed bottom-0 end-0 me-2 mb-2 high-z-index"}
                      onClick={() => setShowSelectedList(!showSelectedList)}>
                {showSelectedList && <KeyboardDoubleArrowDownIcon/>}
                {!showSelectedList && <KeyboardDoubleArrowUpIcon/>}
              </Button>
            {selection && selection.length > 0 && selection.map((r: IRecipe, idx: number) => (
              <RecipeCardTiny
                key={idx}
                className="card-fixed-size recipe-card-title me-1"
                recipe={r}
                onDeselect={(recip) => onSelect(recip, false)}
              />
              ))}
            {!selection || selection.length === 0 && <>Sélection vide!</>}
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
      <>
        <Grid container spacing={{xs: 1}}>
          <RecipeList
            onSelect={(r) => onSelect(r, true)}
            onDeselect={(r) => onSelect(r, false)}
            selection={selection}
            recipes={recipes.list}
            setError={setError}
            setRefresh={setRefresh}
          />
        </Grid>
        <div className="loading" ref={loader}>
          {recipes.next != null  && (<h3>Plus de recettes...</h3>)}
        </div>
      </>
    </div>
  )
}
export default StepOne
