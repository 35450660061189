import React, {ChangeEvent, useContext, useEffect, useState} from 'react'
import {IRecipe, ISuggestion} from '../../../api/IRecipe'
import {useParams} from 'react-router'
import Select from 'react-select'
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  getBaseRecipe,
  saveBaseRecipe,
  getRecipe,
  saveRecipe,
  upload, publishRecipe, unPublishRecipe, baseURL
} from '../../../api/api'
import UserContext from '../../../hooks/UserContext'
import {BASE_RECIPE, BASE_RECIPES, RECIPE, RECIPES} from "../../../routes";
import {capitalize, Container, CircularProgress, Box, Grid, Alert, IconButton, Button} from "@mui/material";
import ActionBar from "../../../components/ActionBar";
import {PageHeader} from "../../../components/PageHeader";
import emptyRecipe from './EmptyRecipe';
import ImageUploading, {ImageListType} from "react-images-uploading";
import {Form} from "../../../components/Form";
import ImgageUploadDisplay from "./ImageUploadDisplay";
import RecipeFormIngredient from "./RecipeFormIngredient";
import RecipeFormInstruction from "./RecipeFormInstruction";
import BaseRecipeModal from "./BaseRecipeModal";
import * as Yup from "yup";
import {useLocation, useNavigate} from "react-router-dom";

import RecipeFormUstencils from "./RecipeFormUstencils";
import TitleTypography from "../../../components/TitleTypography";
import {FormikValues} from "formik";
interface IRecipeViewPageProps {
  base?: boolean
}
interface IRecipeFormValues extends IRecipe{
  images?: ImageListType
}
const validationSchema = Yup.object({
  name: Yup.string().required('Le nom de la recette doit être saisi'),
  //description: Yup.string(),
  // recipeIngredients: Yup.array().of(
  //   Yup.object({
  //     name: Yup.string().required("Le nom de l'ingrédient est requis"),
  //     quantity: Yup.number().required().min(0),
  //     unit: Yup.string().required(),
  //     for: Yup.string().optional()
  //   })
  // )
})

const RecipeFormPage = ({base = false}: IRecipeViewPageProps): JSX.Element => {
  const { recipeId } = useParams();
  const loc = useLocation()
  let isCopy = false
  
  const [recipe, setRecipe] = useState<IRecipe>()
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const [showBaseRecipeModal, setShowBaseRecipeModal] = useState(false)
  const [images, setImages] = React.useState([]);
  const {user} = useContext(UserContext)
  
  const navigate = useNavigate()
  
  useEffect(() => {
    if (loc?.state && loc?.state['copy']) {
      isCopy = true
    }
  }, [])
  
  const onPublish = async (r: IRecipe) => {
    const status = r.status === 'published' ? unPublishRecipe : publishRecipe
    try {
      await status(r.id)
      setRecipe({...recipe, status: r?.status === "published" ? "draft":"published"})
    } catch (err) {
      setError(err)
    }
  }
  
  const onSubmit = async (values: IRecipeFormValues) => {
    try {
      let result
      if (base) {
        console.log(`==> Recette de base`)
        result = await saveBaseRecipe({...recipe, ...values})
        navigate(`${BASE_RECIPE}${result.id}/edit`)
       } else {
        console.log(`==> Recette`)
        result = await saveRecipe({...recipe, ...values})
        navigate(`${RECIPE}${result.id}/edit`)
      }

      //setRecipe({...result})
      let contentUrl = ""
      if (recipe?.image.length > 0) {
        contentUrl = recipe.image[0].contentUrl
      }
      if (images && images.length > 0) {
        const image = document.getElementById('card-img') as HTMLImageElement
        await upload(images[0].file,
          contentUrl,
          recipe.id || result.id,
          '4*3',
          'jpeg',
          image.height,
          image.width,
          'xl').then((response) => {
          console.log(response.data)
        })
      }
      setError(null)
      setSuccess('Votre recette a été enregistrée.')
    } catch (err) {
      setError(err)
      setSuccess(null)
    }
  }
  const imageURL = (recipe?.image?.length > 0) ? `${baseURL}/v1/images/${recipe?.image[0]?.contentUrl}` : "https://via.placeholder.com/800x600"
  const onChange = (
    imageList: ImageListType
  ) => {
    setImages(imageList as never[]);
  };
  
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, [])
  useEffect( () => {
      const fetch = async() => {
        try {
          let fetchedRecipe
          if (base) {
            fetchedRecipe = await getBaseRecipe(recipeId)
          } else {
            fetchedRecipe = await getRecipe(recipeId)
          }
          if (isCopy) {
            fetchedRecipe.id = undefined
            fetchedRecipe.name = `Copie: ${fetchedRecipe.name}`
            fetchedRecipe.image = []
          }
          setRecipe(fetchedRecipe)
        } catch (err) {
          setError(err)
        }
      }
      if (!recipeId) {
        setRecipe({...emptyRecipe, author: {name: `${user.firstname} ${user.lastname}`} })
      } else {
        fetch()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [recipeId, success]);

  if (!recipe && !error) {
    return <></>
  }

  const getRecipesUrl = () => base ? BASE_RECIPES : RECIPES
  const getRecipeUrl = () => base ? BASE_RECIPE : RECIPE
  const breads = [
    {
      href: getRecipesUrl(),
      children: base ? 'Les recettes de base' : 'Les recettes'
    }
  ]
  if (recipe.id) {
    breads.push({
      href: `${getRecipeUrl()}${recipe.id}`,
      children: capitalize(recipe.name)
    })
    breads.push(    {
      href: null,
      children: 'Editer'
    })
  } else {
    breads.push(    {
      href: `${getRecipeUrl()}${recipe.id}/create`,
      children: 'Créer'
    })
  }
  
  const Description = ({recipeDescription, setFieldValue}: {recipeDescription: string, setFieldValue: (v, v2)=>void}) => {
    const [description, setDescription] = useState(recipeDescription)

    return (
      <Form.TextField
        sx={{marginTop: '8px'}}
        size="small"
        fullWidth
        multiline
        id="description"
        name="description"
        label="Description"
        value={description}
        onChange={(v) => {
          setDescription(v.target.value)
        }}
        onBlur={(v) => {
          setFieldValue(`description`, description)
        }}
      />
    )
  }
  const Author = ({recipeAuthor, setFieldValue}: {recipeAuthor: string, setFieldValue: (v, v2)=>void}) => {
    const [author, setAuthor] = useState(recipeAuthor)
    return (
      <Form.TextField
        sx={{marginTop: '8px'}}
        size="small"
        id="author"
        label="Chef"
        name="author.name"
        value={author}
        onChange={(v) => {
          setAuthor(v.target.value)
        }}
        onBlur={(v) => {
          setFieldValue(`author.name`, v.target.value)
        }}
      />
    )}
  
    const Title = ({recipeName, setFieldValue}: {recipeName: string, setFieldValue: (v, v2)=>void}) => {
      const [name, setName] = useState(recipeName)
      return (
        <Form.TextField
          sx={{marginTop: '8px'}}
          size="small"
          id="name"
          label="Nom de la recette"
          name="name"
          autoComplete="name"
          value={name}
          onChange={(v) => {
            setName(v.target.value)
          }}
          onBlur={(v) => {
            setFieldValue(`name`, v.target.value)
          }}
        />
      )
  }
  interface ISuggestionProps {
    values: FormikValues
    setFieldValue: any
  }
  const Suggestions = ({values, setFieldValue}: ISuggestionProps) => {
    const [suggestions, setSuggestions] = useState(values.suggestion)
    const options = [
      { value: 'accompagnement', label: 'accompagnement' },
      { value: 'remplacement', label: 'remplacement' },
      { value: 'vin', label: 'vin' },
      { value: 'secret du chef', label: 'secret du chef' },
    ];
    const onSelectionChange = (idx, v) => {
      suggestions[idx].type = v.label
      setSuggestions([...suggestions.slice(0, idx), suggestions[idx], ...suggestions.slice(idx+1)])
    }
    const onValueChange = (idx, v) => {
      suggestions[idx].value = v
      setSuggestions([...suggestions.slice(0, idx), suggestions[idx], ...suggestions.slice(idx+1)])
    }
    const onAdd = () => {
      setSuggestions([...suggestions, {type: '', value: ''}])
      setFieldValue('suggestion', [...values.suggestion, {type: '', value: ''}])
    }
    const onDelete = (idx) => {
      setSuggestions([...suggestions.slice(0, idx), ...suggestions.slice(idx+1)])
      setFieldValue('suggestion',[...values.suggestion.slice(0, idx), ...values.suggestion.slice(idx+1)])
    }
    return (<Box sx={{margin: '16px 0'}}>
      <TitleTypography>Les suggestions du chef</TitleTypography>
      {suggestions?.map((suggestion, idx) => {
        return (
          <Box key={`suggestion-${idx}`} className="d-inline-flex w-100 mb-2">
            <div className="me-2 w-100 text-muted">
              <Select
                placeholder={"Choisissez..."}
                value={
                  {
                    label: suggestion.type,
                    value: suggestion.type
                  }
                }
                onChange={(v) => onSelectionChange(idx, v)}
                onBlur={() => {
                  setFieldValue('suggestion', suggestions)
                }}
                options={options}
              />
            </div>

            <Form.TextField
              size="small"
              id="suggestion"
              fullWidth
              multiline
              label="La suggestion"
              value={suggestion.value}
              onChange={(v) => {
                onValueChange(idx, v.target.value)
              }}
              onBlur={() => {
                setFieldValue('suggestion', suggestions)
              }}
            />
            
            <IconButton sx={{maxHeight: '35px'}} color="primary" onClick={() => onDelete(idx)} >
              <DeleteOutlineIcon sx={{fontSize: '1.2rem', height: '20px'}}/>
            </IconButton>
            <IconButton sx={{maxHeight: '35px'}}  color="primary" onClick={onAdd}>
              <AddCircleOutlineIcon sx={{fontSize: '1.2rem'}}/>
            </IconButton>
          </Box>
        )
      })}
  
      {!values.suggestion || values.suggestion.length === 0 && (
        <Box sx={{marginLeft: 'auto', textAlign: 'right', marginRight: '40px'}}>
          <Button
            variant="outlined"
            startIcon={<AddCircleOutlineIcon />}
            onClick={onAdd}
            color="primary" aria-label="add">
            Ajouter une suggestion
          </Button>
        </Box>
      )}
    </Box>)
  }
  return (
    <Container>
      <Form.Context<IRecipeFormValues>
        initialValues={{...recipe, images: []}}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={onSubmit}
        render={({values, errors, touched, handleSubmit, isValid, isSubmitting, setFieldValue, handleChange}) => (
          <>
            <ActionBar
              breadcrumbs={breads}
              error={error}
              title={capitalize(values?.name)}
              subtitle={values?.author?.name}
              action={
                <Box sx={{marginLeft: 'auto', textAlign: 'right'}}>
                  <PageHeader.Actions>
                    <PageHeader.ActionItem
                      onClick={() => onPublish(recipe)}
                      variant="outlined">
                      {values.status === "published" ? "Mettre en draft" : "Publier"}
                    </PageHeader.ActionItem>
                    <PageHeader.ActionItem
                      form="recipe-form"
                      type="submit"
                      variant="contained"
                      disabled={!isValid}
                    >
                      {isSubmitting ? <CircularProgress color="inherit" size={24}/> : "Enregistrer"}
                    </PageHeader.ActionItem>
                  </PageHeader.Actions>
                </Box>
              }
            />
            <BaseRecipeModal
              show={showBaseRecipeModal}
              onHide={() => setShowBaseRecipeModal(false)}
              onClick={
                async (baseRecipe) => {
                  console.log(`Add base recipe ${baseRecipe.name} to recipe ${recipe.name}`)
                  const result = await saveRecipe({
                    ...values, ...{
                      name: values.name === "" ? "Ma recette" : values.name,
                      recipeInstructions: [...recipe.recipeInstructions, {
                        name: baseRecipe.name,
                        baseRecipe: baseRecipe.id,
                        when: 'batch'
                      }]
                    }
                  })
                  if(base) {
                    navigate(`${BASE_RECIPE}${result.id}/edit`)
                  } else {
                    navigate(`${RECIPE}${result.id}/edit`)
                  }
                  window.location.reload()
                }
              }
              />
            <Form.Form id="recipe-form" onSubmit={handleSubmit}>
              <Box sx={{mt: 3}}>
                <Grid container spacing={{sm:2}} marginTop={{xs:1, sm:0}}>
                  <Grid item xs={12} sm={6} marginTop={1}>
                    <ImageUploading
                      multiple={false}
                      value={images}
                      resolutionType={"less"}
                      resolutionWidth={805}
                      resolutionHeight={605}
                      maxFileSize={500000}
                      onChange={onChange}
                      onError={(e) => {
                        if (e.resolution) {
                          setError({message: "La resolution de l'image doit être de 800 par 600"})
                        } else if (e.maxFileSize) {
                          setError({message: "La taille maximum est atteinte"})
                        } else {
                          setError({message: "inconnue"})
                        }
                      }}>
                      {ImgageUploadDisplay({imageURL, recipe})}
                    </ImageUploading>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Title recipeName={values.name} setFieldValue={setFieldValue} />
                    <Author recipeAuthor={values.author.name} setFieldValue={setFieldValue} />
                    <Description recipeDescription={values.description} setFieldValue={setFieldValue} />
                    <Suggestions values={values} setFieldValue={setFieldValue} />
                  </Grid>
                </Grid>
                <Grid container spacing={{sm:2}} marginTop={{xs:1, sm:0}}>
                  <Grid item xs={12}>
                    <TitleTypography>Les ingrédients</TitleTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <RecipeFormIngredient
                      errors={errors}
                      values={values}
                      base={base}
                      setFieldValue={setFieldValue}/>
                  </Grid>
                  <RecipeFormUstencils recipeTools={recipe.tool} setFieldValue={setFieldValue} />
                  <RecipeFormInstruction
                    recipe={recipe}
                    base={base}
                    values={values}
                    errors={errors}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    setShowBaseRecipeModal={setShowBaseRecipeModal}/>
                </Grid>
              </Box>
            </Form.Form>
          </>
        )}
      >
      </Form.Context>
    </Container>
  );
}

export default RecipeFormPage
