export const capitalizeAndPunctuation = (s: string, p = '.'): string => {
  if (typeof s !== 'string') return ''
  if (s.length === 0) return ''
  s = s.charAt(0).toUpperCase() + s.slice(1)
  
  if (s.endsWith('.') || s.endsWith(':') || s.endsWith(';') || s.endsWith(',') || s.endsWith('!')){
    return s
  }

  return `${s}${p}`
}

export function isNumber(value: string | number): boolean
{
  return ((value != null) &&
    (value !== '') &&
    !isNaN(Number(value.toString())));
}

export const accentsTidy = (s: string): string => {
  let r = s.toLowerCase();
  r = r.replace(new RegExp(/\s/g),"");
  r = r.replace(new RegExp(/[àáâãäå]/g),"a");
  r = r.replace(new RegExp(/æ/g),"ae");
  r = r.replace(new RegExp(/ç/g),"c");
  r = r.replace(new RegExp(/[èéêë]/g),"e");
  r = r.replace(new RegExp(/[ìíîï]/g),"i");
  r = r.replace(new RegExp(/ñ/g),"n");
  r = r.replace(new RegExp(/[òóôõö]/g),"o");
  r = r.replace(new RegExp(/œ/g),"oe");
  r = r.replace(new RegExp(/[ùúûü]/g),"u");
  r = r.replace(new RegExp(/[ýÿ]/g),"y");
  r = r.replace(new RegExp(/\W/g),"");
  return r;
}
