import {styled} from "@mui/material/styles";
import {Typography} from "@mui/material";

const TitleTypography = styled(Typography)(
  ({theme}) => `
  font-weight: 400;
  font-size: 1.25rem;
  margin-bottom: 1rem
`
);
export default TitleTypography
