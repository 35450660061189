const unitOptions = [
  { value: 'pce', label: 'pce' },
  { value: 'g', label: 'g' },
  { value: 'kg', label: 'kg' },
  { value: 'l', label: 'l' },
  { value: 'cl', label: 'cl' },
  { value: 'ml', label: 'ml' },
  { value: 'brin', label: 'brin' },
  { value: 'pincée', label: 'pincée' },
  { value: "cc", label: "cc"},
  { value: "cs", label: "cs"},
  { value: "verre", label: "verre"},
  { value: "verre à liqueur", label: "verre à liqueur"},
  { value: "pm", label: "pm"},
  { value: "qs", label: "qs"},
  { value: "sachet", label: "sachet"},
  { value: "dose", label: "dose"},
  { value: "branche", label: "branche"},
  { value: "botte", label: "botte"},
  { value: "boîte", label: "boîte"},
  { value: "gousse", label: "gousse"},
  { value: "tranche", label: "tranche"},
];
export default unitOptions
