import React, {useState} from 'react'
import {Button, Container, Offcanvas, Row} from 'react-bootstrap'
import cn from 'classnames'
import ActionBar from "../ActionBar";
import {Alert, AlertTitle} from "@mui/material";
import {LOGIN} from "../../routes";

export interface IStep {
  component: JSX.Element
  nextButtonLabel: string|React.ReactElement
  onNext?: () => void
  previousButtonLabel: string|React.ReactElement
  printButtonLabel?: string|React.ReactElement
  search?: JSX.Element
}

export interface IWizardComponentProps {
  steps: IStep[]
  breadcrumbs?: any[]
  saving?: boolean
  error?: string
}

const Wizard = ({steps, saving, error}: IWizardComponentProps): JSX.Element => {

  const getStepsState = (indx:number): string[] => {
    const styles = []
    for (let i = 0; i < steps.length; i++) {
      if (i < indx) {
        styles.push('complete')
      } else if (i === indx) {
        styles.push('active')
      } else {
        styles.push('todo')
      }
    }
    return styles
  }

  const [stylesState, setStyles] = useState(getStepsState(0))
  const [compState, setComp] = useState(0)

  const setStepState = (indx) => {
    setStyles(getStepsState(indx))
    setComp(indx < steps.length ? indx : compState)
  }

  const next = () => {
    setStepState(compState === steps.length - 1 ? compState : compState + 1)
    if (steps[compState].onNext) {
      steps[compState].onNext()
    }
  }
  const previous = () => setStepState(compState > 0 ? compState - 1 : compState)

  const handleOnClick = evt => {
    if (
      evt.currentTarget.value === steps.length - 1 &&
      compState === steps.length - 1
    ) {
      setStepState(steps.length)
    } else {
      setStepState(evt.currentTarget.value)
    }
  }

  const renderSteps = () =>
    (<ol className={cn('w-50', 'step-indicator')}>
      {steps.map((s, i) => {
        return (
          <li
            className={stylesState[i]}
            onClick={handleOnClick}
            key={i}
            value={i}
          >
            <div className={'step'}>{i+1}</div>
          </li>
        )})}
    </ol>)

  const renderNav = (step: IStep) => {
    return (
      <div className={"text-end ms-auto"}>
        {step.previousButtonLabel && (<Button
          variant={"outline-primary"}
          onClick={()=>{setShow(false);previous()}}
          className="mx-2"
        >
          {step.previousButtonLabel}
        </Button>)}
        {step.printButtonLabel && (<Button
          variant={"outline-primary"}
          onClick={() => setShow(!show)}
          className="mx-2"
        >
          {step.printButtonLabel}
        </Button>)}
        {step.nextButtonLabel && (<Button
          variant={"primary"}
          onClick={()=>{setShow(false);next()}}
          className={cn('mx-2')}
          disabled={saving === true}
        >
          {step.nextButtonLabel}
        </Button>)}
      </div>
    )
  }
  const mediaQueryXS = window.matchMedia("(max-width: 576px)") //mobile
  const [show, setShow] = useState(mediaQueryXS.matches?false:true)

  return (
    <>
      <ActionBar
        action={<div className={"d-flex"}>
          {renderSteps()}
          {steps[compState].search}
          {renderNav(steps[compState])}
        </div>}
      />


      <Container className="mt-lg-4 min-vh-100 mt-2">
        {error && <Alert severity="error">
          <AlertTitle>Cher utilisateur</AlertTitle>
          {error}
          <br/>
        </Alert>}
        {!error && steps[compState].component}
      </Container>
    </>
  )
}

export default Wizard
