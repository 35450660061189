import React, {useContext, useEffect, useState} from 'react'
import {IBatch} from '../../../api/IRecipe'
import {deleteSuggestedBatch, getSuggestedBatches, saveSuggestedBatch} from '../../../api/api'
import {useNavigate} from "react-router-dom";
import {
  CHEF_SUGGESTED_BATCHES,
  CREATE_SUGGESTED_BATCH,
  START_BATCH,
} from "../../../routes";
import ActionBar from "../../../components/ActionBar";
import LoadingSkeleton from "../../../components/LoadingTable";
import {
  Container,
  Box,
  Button,
  Card,
  CardHeader,
  Grid,
} from "@mui/material";
import {IOption} from "../../../components/MoreMenu";
import {PageHeader} from "../../../components/PageHeader";
import UserContext from "../../../hooks/UserContext";
import {SuggestedBatchCard} from "../components/SuggestedBatchCard";
import {EmptySuggestedBatch} from "../components/EmptySuggestedBatch";

const SuggestedBatchListPage = (): JSX.Element => {
  const {user} = useContext(UserContext)
  const [isLoading, setIsLoading] = useState(true)
  const [batches, setBatches] = useState<IBatch[]>([])
  const [error, setError] = useState(null)
  const [refresh, setRefresh] = useState(1)
  const navigate = useNavigate()
  
  const onDelete = async (batchId: string) => {
    try {
      await deleteSuggestedBatch(batchId)
      setRefresh(refresh + 1)
    } catch (err) {
      setError(err)
    }
  }
  const onChange = async (batch: IBatch, status) => {
    try {
      await saveSuggestedBatch({
        ...batch,
        status
      })
      setRefresh(refresh + 1)
    } catch (e) {
      console.log(`Error: ${JSON.stringify(e)}`)
    }
  }
  useEffect( () => {
    const fetch = async() => {
      try {
        const allBatches = await getSuggestedBatches(false)
        setBatches(allBatches)
        setIsLoading(false)
      } catch (err) {
        setError(err)
      }
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
    fetch()
  }, [refresh]);
  
  const navigateToWizard = () => {navigate(START_BATCH, {state:{isSuggestedBatch: true}})}
  if (isLoading) {
    return (<LoadingSkeleton />)
  }
  
  if(batches.length === 0 && !isLoading) {
    return (<Container sx={{marginBottom: '2rem'}}>
      <ActionBar
        error={error}
        action={
          (user.roles.includes('creator') || user.roles.includes('apprentice')) ? (
            <div className={"ms-auto text-end"}>
              <PageHeader.Actions>
                <PageHeader.ActionItem
                  variant="contained"
                  onClick={() => navigate(CREATE_SUGGESTED_BATCH, {state:{isSuggestedBatch: true}})}>
                  Ajouter un batch
                </PageHeader.ActionItem>
              </PageHeader.Actions>
            </div>) : (
            <div className={"ms-auto text-end"}>
            </div>
          )
        }
      />
      <EmptySuggestedBatch />
    </Container>)
  }
 
  return (
      <Container sx={{marginBottom: '2rem'}}>
        <ActionBar
          error={error}
          action={
            (user.roles.includes('creator') || user.roles.includes('apprentice')) ? (
              <div className={"ms-auto text-end"}>
                <PageHeader.Actions>
                  <PageHeader.ActionItem
                    variant="contained"
                    onClick={() => navigate(CREATE_SUGGESTED_BATCH, {state:{isSuggestedBatch: true}})}>
                    Ajouter un batch
                  </PageHeader.ActionItem>
                </PageHeader.Actions>
              </div>) : (
              <div className={"ms-auto text-end"}>
              </div>
            )
          }
        />
        {!error && (
          <Box marginTop={1} maxWidth={'lg'}>
            {!error && (
              <>
                <Grid container spacing={{xs: 1}}>
                  {batches.map((batch, idx) => {
                    const options: IOption[] = [
                      {
                        name: "Voir / Editer",
                        action: () => navigate(`${CHEF_SUGGESTED_BATCHES}/${batch.id}`)
                      },
                      {
                        name: (batch.status === "published" || batch.status === "star") ? "Mettre en draft":"Publier",
                        action: () => onChange(batch, (batch.status === "published" || batch.status === "star") ? "draft":"published")
                      },
                      {
                        name: "Marquer \"batch de la semaine\"",
                        action: () => onChange(batch, 'star')
                      },
                      {
                        name: "Supprimer",
                        action: () => onDelete(batch.id)
                      }
                    ]
                    return (
                      <SuggestedBatchCard key={idx} batch={batch} options={options}/>
                    );
                  })}
                </Grid>
              </>
            )}
          </Box>
        )}
      </Container>
  );
}

export default SuggestedBatchListPage
