import {Container, Row} from 'react-bootstrap'
import React, {useEffect, useState} from 'react'
import {IResource} from '../../../api/IRecipe'
import {getExecutionBatch, getExecutionSuggestedBatch} from '../../../api/api'
import ViewBatchInstructions from './ViewBatchInstructions'

interface IExecutionCardProps {
  batchId: string
  isSuggested?: boolean,
  setError?: ({message}:{message:string}) => void
}

const ExecutionCard = ({batchId, setError, isSuggested = false}: IExecutionCardProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false)
  const [executionBatch, setExecutionBatch] = useState<IResource>()

  useEffect(() => {
    const fetch = async() => {
      const getExecution = isSuggested ? getExecutionSuggestedBatch : getExecutionBatch
      try {
        setIsLoading(true)
        const exeBatch = await getExecution(batchId)
        setExecutionBatch(exeBatch)
        setIsLoading(false)
      } catch (err) {
        setError(err)
      }
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
    fetch()
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [])
  
  if (!executionBatch || isLoading) { // batch is loading
    return (
      <>Loading</>
    )
  }
  
  return (
    <Container fluid className="min-vh-100">
      <Row className="mb-5">
        <ViewBatchInstructions steps={executionBatch?.stepPerSlot[0]} />
      </Row>
    </Container>
  )
}

export default ExecutionCard

