import {Button, Modal} from 'react-bootstrap'
import React, {useState, useEffect} from 'react'
import {IRecipe} from '../../../api/IRecipe'
import {getBaseRecipes} from '../../../api/api'
import Select, {IOption} from 'react-select'

interface IBaseRecipesProps {
  show: boolean
  onHide: () => void
  onClick: (base: IRecipe) => void
}
const  BaseRecipeModal = ({show, onHide, onClick} :IBaseRecipesProps): JSX.Element => {
  const [selectedBaseRecipe, setSelectedBaseRecipe] = useState<IOption>()
  const [baseRecipes, setBaseRecipes] = useState<IRecipe[]>()
  
  useEffect(() => {
    const fetchBaseRecipes = async() => {
      try {
        const bases = await getBaseRecipes()
        console.log(`there are ${bases?.length} base recipes from server`)
        setBaseRecipes(bases)
      } catch (error) {
        console.log(`an error ${error}`)
      }
    }
    fetchBaseRecipes()
  }, [])
  
  
  return <Modal
    size="lg"
    show={show}
    onHide={onHide}
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title>Confirmation</Modal.Title>
    </Modal.Header>
    <Modal.Body className={"m-auto"}>
      <div className="me-2 mb-5">{`Quelle recette de base voulez-vous insérer?`}</div>
      <div className="me-2 mb-5 w-100 text-muted">
        <Select
          placeholder={"Recette de base..."}
          value={selectedBaseRecipe}
          onChange={(v) => {
              setSelectedBaseRecipe(v)
            }
          }
          options={baseRecipes?.map(i => {
            return {
              label:i.name,
              value: i.name.toLowerCase().replace(/\W/g, '')
            }
          })}
        />
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={onHide} variant="light">Annuler</Button>
      <Button onClick={() => {
        console.log(`CLICK`, selectedBaseRecipe.label)
        onClick(baseRecipes.find(v => v.name === selectedBaseRecipe.label))
        onHide()
      }} variant="primary">Ajouter</Button>
    </Modal.Footer>
  </Modal>
}

export default BaseRecipeModal;
