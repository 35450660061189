import { Breadcrumbs as MUIBreadcrumbs, useMediaQuery, useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'

import { ACTIONS_WRAPPER_DISPLAY_NAME } from './const'
import {
  IActionsProps,
  IAlertProps,
  IBreadcrumbItemProps,
  IBreadcrumbsProps,
  IDescriptionProps,
  ITitleProps,
  IWrapperProps,
} from './types'
import { filterActionsChildren, getActionsChildren, isActionsChild } from './utils'
import TitleTypography from "../TitleTypography";

const Wrapper = ({ children }: IWrapperProps) => {
  const removeActionsWrapper = !children.props && children.filter(isActionsChild)[0]

  return (
    <Box
      sx={theme => ({
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
        position: 'sticky',
        zIndex: '1000',
        top: '57px',
        backgroundColor: `${theme.palette.background.default}`,
        // marginLeft: '-1rem',
        // marginRight: '-1rem'
      })}
    >
      <Box sx={{width: '100%'}}>{!children.props ? filterActionsChildren(children) : children}</Box>
      {removeActionsWrapper ? <Box>{!children.props && getActionsChildren(children)}</Box> : ''}
    </Box>
  )
}

const Breadcrumbs = ({ children, maxItems = 5 }: IBreadcrumbsProps) => {
  const { breakpoints } = useTheme()
  const matches = useMediaQuery(breakpoints.up('md'))

  return (
    <Box sx={{ marginBottom: 1, display: 'flex', flexGrow: 1 }}>
      <MUIBreadcrumbs maxItems={matches ? maxItems : 2} aria-label="breadcrumb">
        {children}
      </MUIBreadcrumbs>
    </Box>
  )
}

const BreadcrumbItem = ({ children, href, current }: IBreadcrumbItemProps) =>
  href ? (
    <Link underline="hover" color="inherit" href={href} aria-current={current ? 'page' : 'step'}>
      {children}
    </Link>
  ) : (
    <Typography aria-current={current ? 'page' : 'step'}>{children}</Typography>
  )

const Title = ({ children }: ITitleProps) => {
  return (
    <Box sx={{ display: 'block' }}>
      <TitleTypography sx={{ marginBottom: '0', marginRight: 3 }}>
        {children}
      </TitleTypography>
    </Box>
  )
}

const Description = ({ children }: IDescriptionProps) => {
  return (
    <Box sx={{ display: 'block', marginBottom: 2 }}>
      <Typography variant="body2">{children}</Typography>
    </Box>
  )
}

const Actions = ({ children }: IActionsProps) => {
  return (
    <Box
      sx={{
        gap: 2,
        display: 'flex',
        flex: '1 0 auto',
        justifyContent: 'flex-end'
      }}
    >
      {children}
    </Box>
  )
}

const AlertWrapper = ({ children }: IAlertProps) => {
  return children
}

Wrapper.displayName = 'PageHeaderWrapper'
Title.displayName = 'PageHeaderTitle'
Breadcrumbs.displayName = 'PageHeaderBreadcrumbs'
Actions.displayName = ACTIONS_WRAPPER_DISPLAY_NAME
Description.displayName = 'PageHeaderDescription'
AlertWrapper.displayName = 'PageHeaderAlertWrapper'

const PageHeader = {
  Wrapper,
  Title,
  Breadcrumbs,
  BreadcrumbItem,
  Actions,
  ActionItem: Button,
  Description,
  AlertWrapper,
}

export { PageHeader }
