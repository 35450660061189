import Carousel from 'react-grid-carousel'
import {IRecipe} from "../../api/IRecipe";
import {Box, Button, Grid, Typography} from "@mui/material";
import {LOGIN, RECIPE, START_BATCH} from "../../routes";
import React, {useContext} from "react";
import RecipeCardGallery from "./RecipeCardGallery";
import {useNavigate} from "react-router-dom";
import UserContext from "../../hooks/UserContext";


export const Gallery = ({batch}) => {
  const navigate = useNavigate()
  const { user } = useContext(UserContext);
  
  const recipes: IRecipe[] = Object.values(batch?.recipes || [{id:1, status: "public"}, {id:2, status: "public"}, {id:3, status: "public"}, {id:4, status: "public"}])
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container  sx={{ marginBottom: '2rem'}}>
          <Grid item xs={12} md={7}><Typography sx={{fontSize: '1rem', margin:'1rem 0 '}}>
            {batch?.description || 'Le batch de la semaine est en cours de préparation.'}
          </Typography></Grid>
          <Grid item xs={12} md={5}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                sx={{ height: 'fit-content', marginLeft: 'auto'}}
                color="warning"
                size="large"
                variant={"contained"}
                onClick={() => {
                  if (!user || user.roles.includes("public")) {
                    navigate(LOGIN)
                  } else {
                    navigate(START_BATCH, {state: {suggestedBatch: batch}})
                  }}
                }
              >Je&nbsp;commence&nbsp;mon&nbsp;batch!
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Carousel cols={4} rows={1} loop>
          {recipes?.map((recipe, idx) => (
              <Carousel.Item key={idx}>
                <RecipeCardGallery
                  onClick={() => {
                    navigate(`${RECIPE}${recipe.id}`)
                  }}
                  recipe={recipe}/>
              </Carousel.Item>
            )
          )}
        </Carousel>
      </Grid>
    
    </Grid>
  
  )
}
