import React, {useContext, useEffect, useState} from 'react'
import {IBatch} from '../../../api/IRecipe'
import {getCurrentBatch} from '../../../api/api'
import {MY_BATCHES, START_BATCH} from "../../../routes";
import {useNavigate} from "react-router-dom";
import IngredientList from "../../ingredient/list/IngredientList";
import ExecutionCard from "../execution/ExecutionCard";
import BatchWithRecipes from "../components/BatchWithRecipes";
import {Box, Container, Tabs, Tab} from "@mui/material";
import {EmptyBatch} from "../components/EmptyBatch";
import ActionBar, {IError} from "../../../components/ActionBar";
import {PageHeader} from "../../../components/PageHeader";
import UserContext from "../../../hooks/UserContext";
import {IUser} from "../../../api/IUser";

const WeeklyBatch = ({user, weeklyBatch, setError}:{user: IUser,weeklyBatch?: IBatch, setError?: ({message}:{message:string}) => void}): JSX.Element => {
  const [tabIndex, setTabIndex] = useState(0);
  
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };
  const navigate = useNavigate()
  return (
    <Container sx={{marginBottom: '2rem'}}>
      <ActionBar
        action={
          (user.roles.includes('creator') || user.roles.includes('apprentice')) ? (
            <div className={"ms-auto text-end"}>
              <PageHeader.Actions>
                <PageHeader.ActionItem
                  variant="contained"
                  onClick={() => navigate(`${MY_BATCHES}/create`)}>
                  Ajouter un batch
                </PageHeader.ActionItem>
              </PageHeader.Actions>
            </div>) : (
            <div className={"ms-auto text-end"}>
            </div>
          )
        }
      />
      <Box marginTop={1} maxWidth={'lg'}>
        <>
          <Box>
            <Tabs value={tabIndex} onChange={handleTabChange}>
              <Tab label="Le menu" />
              <Tab label="La feuille de marché" />
              <Tab label="Exécution" />
            </Tabs>
          </Box>
          <Box sx={{ padding: 2 }}>
            {tabIndex === 0 && (
              <BatchWithRecipes batch={weeklyBatch} />
            )}
            {tabIndex === 1 && (
              <Box>
                {weeklyBatch && weeklyBatch.ingredients && <IngredientList ingredients={weeklyBatch.ingredients} />}
              </Box>
            )}
            {tabIndex === 2 && (
              <Box>
                {weeklyBatch && weeklyBatch.id && <ExecutionCard batchId={weeklyBatch.id} setError={setError}/>}
              </Box>
            )}
          </Box>
        </>
      </Box>
    </Container>
  );
}

const MyCurrentBatch = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [weekBatch, setWeekBatch] = useState<IBatch>()
  const navigate = useNavigate()
  
  useEffect( () => {
      const fetch = async() => {
        try {
          setIsLoading(true)
          const batch = await getCurrentBatch()
          setWeekBatch(batch)
          setIsLoading(false)
        } catch (err) {
          setError(err)
          setIsLoading(false)
        }
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      }
      fetch()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
  const {user} = useContext(UserContext)
  if (isLoading) {
    return (<></>)
  }
  
  if(error && !isLoading) {
    return (
      <>
        <Container>
          <ActionBar
            error={(error && error.status_text !== "not found") ? null: error}
            action={
              (user.roles.includes('creator') || user.roles.includes('apprentice')) ? (
                <div className={"ms-auto text-end"}>
                  <PageHeader.Actions>
                    <PageHeader.ActionItem
                      variant="contained"
                      onClick={() => navigate(`${MY_BATCHES}/create`)}>
                      Ajouter un batch
                    </PageHeader.ActionItem>
                  </PageHeader.Actions>
                </div>) : (
                <div className={"ms-auto text-end"}>
                </div>
              )
            }
          />
        </Container>
        <EmptyBatch />
      </>
    )
  }
  
  return (<WeeklyBatch user={user} weeklyBatch={weekBatch} setError={setError}/>)
}
export default MyCurrentBatch
