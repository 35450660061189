import {Accordion} from 'react-bootstrap'
import React, {useState} from 'react'

import {IRecipeInstruction, IStep} from '../../../api/IRecipe'
import {capitalizeAndPunctuation} from '../../../utils/Text'
import ViewIngredients from './ViewMetaIngredient'
import ViewMetaData from './ViewMetaData'
import cn from 'classnames'
import {capitalize} from "@mui/material";
import {BASE_RECIPES, RECIPE} from "../../../routes";

interface IViewInstructionProps {
  steps: IStep[]
}

const ViewBatchInstructions = ({steps}: IViewInstructionProps): JSX.Element => {
  const colors = ["blue", "pink", "purple", "yellow", "primary", "secondary", "primary-over"]
  const associatedRecipeColor = {}
  const hours = Math.floor(steps[steps.length -1]?.end/60);
  const mins = steps[steps.length -1]?.end % 60;
  
  steps.map((step: IStep) => {
    if (!associatedRecipeColor[step.recipeName]) {
      associatedRecipeColor[step.recipeName] = colors.pop()
    }
  })
  
  return (
    <>
      <p className="text-muted">Temps prép. du batch: {`${hours} h ${mins} mn`}</p>
      <Accordion defaultActiveKey={steps.map((_, idx) => idx+'')} alwaysOpen>
      {steps.map((step: IStep, idx: number) => {
        let videoUrl
        if(step.recipeInstruction?.video && step.recipeInstruction?.video[0]) {
          const endUrl = step.recipeInstruction?.video[0].contentUrl?.split('/')[3]
          videoUrl = endUrl ? `https://www.youtube.com/embed/${endUrl}` : undefined
        }
        
        return (
          <Accordion.Item className={`instruction-border-${associatedRecipeColor[step.recipeName]} mt-2`} key={idx} eventKey={idx+''} >
            <>
              {!step.recipeInstruction.recipeInstructions && (
                <>
                  <Accordion.Header className={`instruction-bg-${associatedRecipeColor[step.recipeName]}`} >
                    <div className={"d-flex w-100"}>
                      <div>{`${idx+1} - ${capitalize(step.recipeInstruction.name)}`}</div>
                      <a
                        href={`${RECIPE}${step.recipeId}`}
                        target={"_blank"}
                        rel="noopener noreferrer"
                        className="text-black-50 ms-auto me-2 truncate-xl d-none d-md-block">{`${capitalize(step.recipeName)}`}</a>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <a
                      href={`/nos_suggestions/éditer_recette/${step.recipeId}`}
                      target={"_blank"}
                      rel="noopener noreferrer"
                      className="d-block d-md-none">{`${capitalize(step.recipeName)}`}</a>
                    {step.recipeInstruction.metaIngredients?.length>0 && <div className="ms-auto col"><ViewIngredients ingredients={step.recipeInstruction.metaIngredients} /></div>}
                    <ViewMetaData steps={steps} recipeInstruction={step.recipeInstruction} />
                  </Accordion.Body>
                </>
                )}
              {step.recipeInstruction.recipeInstructions && (
                <>
                  <Accordion.Header className={`instruction-bg-${associatedRecipeColor[step.recipeName]}`} >
                    <div className="d-flex w-100">
                      <div className="recipe-sub-title me-auto">{`${idx+1} - ${capitalize(step.recipeInstruction.name)}`}</div>
                      <a
                        href={`${RECIPE}${step.recipeId}`}
                        target={"_blank"}
                        rel="noopener noreferrer"
                        className="text-black-50 ms-auto me-2 truncate-xl d-none d-md-block">{`${capitalize(step.recipeName)}`}</a>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className={"row"}>
                      <a
                        href={`${RECIPE}${step.recipeId}`}
                        target={"_blank"}
                        rel="noopener noreferrer"
                        className="d-block d-md-none">{`${capitalize(step.recipeName)}`}</a>
                      
                      {step.recipeInstruction.metaIngredients?.length>0 && (
                        <div>
                        <ViewIngredients ingredients={step.recipeInstruction.metaIngredients} />
                        </div>
                      )}
                      <div className={cn("mt-2 col-12")}>
                        {step.recipeInstruction.recipeInstructions.map((ii: IRecipeInstruction, idxx: number) => {
                          return (
                            <div key={idxx} >
                              <div className="recipe-icon recipe-instruction-number-inner">
                                {String.fromCharCode('a'.charCodeAt(null) + idxx)}
                              </div>
                              <div className="recipe-sub-title">{capitalizeAndPunctuation(ii.name)}</div>
                              {ii?.recipeInstructions?.map((iii: IRecipeInstruction, idxxx: number) => {
                                return <div key={idxxx} className="recipe-sub-title">{capitalizeAndPunctuation(iii.name)}</div>
                              })
                              }
                            </div>
                          )
                        })}
                      </div>
                    </div>
                    <ViewMetaData steps={steps} recipeInstruction={step.recipeInstruction} />
                    </Accordion.Body>
                </>
              )}
            </>
          </Accordion.Item>
        )
      })}
      
    </Accordion>
    </>
  )
}

export default ViewBatchInstructions
