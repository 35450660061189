import {Button, Modal} from 'react-bootstrap'
import React from 'react'
import {IRecipe} from '../../api/IRecipe'

const fillContent = (recipe) => {
  return {
    title: `Confirmation`,
    question: `Voulez-vous vraiment supprimer cette recette: ${recipe?.name}?`,
    cancel: 'Annuler',
    confirm: 'Supprimer',
  }
}
interface IContentConfirmationModal {
  title: string
  question: string
  cancel: string
  confirm: string
}
interface IConfirmationModalProps {
  content?: (recipe: IRecipe) => IContentConfirmationModal
  show: boolean
  onHide: () => void
  onClick: () => void
  recipe: IRecipe
}

export const ConfirmationModal = ({content = fillContent, show = false, recipe, onClick, onHide}: IConfirmationModalProps): JSX.Element => {
  return <Modal
    size="lg"
    show={show}
    onHide={onHide}
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title>{content(recipe).title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div>{content(recipe).question}</div>
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={onHide} variant="light">{content(recipe).cancel}</Button>
      <Button onClick={onClick}>{content(recipe).confirm}</Button>
    </Modal.Footer>
  </Modal>
}
