import {IRecipe} from '../../../api/IRecipe'
import {baseURL, deleteRecipe, publishRecipe, unPublishRecipe} from '../../../api/api'
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {Box, capitalize, CardActionArea, lighten} from "@mui/material";
import {ChipCalorie} from "../ChipCalorie";

import {MoreMenu, IOption} from "../../../components/MoreMenu";
import {RECIPE} from "../../../routes";
import {useNavigate} from "react-router-dom";
import {CardOverlayContent} from "../../../components/RecipeCard/CardOverlayContent";
import Ribbon from "../../../components/Ribbon";
import YieldComponent from "../edit/YieldComponent";
import {useContext, useState} from "react";
import UserContext from "../../../hooks/UserContext";

interface IRecipeCardProps {
  recipe: IRecipe
  count?: number
  onClick?: () => void
  onSelect?: (r: IRecipe) => void
  onDeselect?: (r: IRecipe) => void
  setRefresh?: (n:boolean) => void
  withoutMenu?: boolean
}

const TypographyClamp = (line, color = false) => styled(Typography)(
  ({theme}) => `
    display: -webkit-box;
    -webkit-line-clamp: ${line};
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: ${color===true ? theme.palette.primary.main : 'inherit'};
`);
const TypographyClamp3 = TypographyClamp(3)
const TypographyClamp1 = TypographyClamp(1, true)
const TypographyClamp2 = TypographyClamp(2, true)
const RecipeCard = ({ recipe, onClick, onSelect, onDeselect, count, setRefresh, withoutMenu = false}: IRecipeCardProps): JSX.Element => {
  const {user} = useContext(UserContext)
  const navigate = useNavigate()
  let imageURL = "https://via.placeholder.com/800x600"
  if (recipe?.image?.length > 0) {
    if (recipe.image[0].thumbnail?.length > 0) {
      imageURL = recipe.image[0].thumbnail[0]
    } else {
      imageURL = recipe.image[0].contentUrl
    }
    imageURL = `${baseURL}/v1/images/${imageURL}`
  }
  
  const nutrients = recipe?.nutrients
  const cal = nutrients?.find(m => m.name === 'énergie' && m.unit==='kcal').value

  const onDelete = async () => {
    try {
      await deleteRecipe(recipe.id)
      if (setRefresh) {
        setRefresh(true)
      }
    } catch (err) {
      console.log(`ERROR while deleting recipe: ${err}`)
      // setError(err)
      // setSuccess(null)
    }
  }
  const onPublish = async (r: IRecipe) => {
    const status = r.status === "published" ? unPublishRecipe : publishRecipe
    try {
      await status(r.id)
      if (setRefresh) {
        setRefresh(true)
      }
    } catch (err) {
      console.log(`ERROR while publishing recipe: ${err}`)
      //setError(err)
    }
  }
  const onRecipeSelect = (e: React.MouseEvent<HTMLAnchorElement>, r: IRecipe, add = true) => {
    e.stopPropagation()
    if (add && onSelect) {
      onSelect(r)
    } else if (onDeselect) {
      onDeselect(r)
    }
  }
  const options: IOption[] = [
    {
      name: "Ouvrir un onglet",
      action: () => {
        window.open(`${RECIPE}${recipe.id}`)
      }
    }
  ]
  if (user.roles.includes('creator')) {
    options.push({
        name: "Editer",
        action: () => {navigate(`${RECIPE}${recipe.id}/edit`)}
      },
      {
        name: "Copier",
        action: () => {navigate(`${RECIPE}${recipe.id}/edit`, {state:{copy: true}})}
      },
      {
        name: recipe.status === "published" ? "Mettre en draft" : "Publier",
        action: () => onPublish(recipe)
      },
      {
        name: "Supprimer",
        action: onDelete
      })
  }
  const menu = withoutMenu ? <></> : <MoreMenu options={options} />
  return (
    <>
    <Card sx={{ position:'relative'}}>
      {recipe.status === "draft" || !recipe.status && <Ribbon>Draft</Ribbon>}
      <CardHeader
        sx={{minHeight: '58px', padding: '8px'}}
        action={
          options.length === 0 ? <></>: menu
        }
        title={<TypographyClamp2>{capitalize(recipe.name)}</TypographyClamp2>}
      />
      <CardActionArea
        onClick={() => onClick && onClick()}>
      <Box sx={{position: 'relative'}}>
        <CardMedia
          component="img"
          image={imageURL}
          alt={recipe.name}
        />

        {onSelect && (<YieldComponent
          count={count}
          onAdd={(e) => onRecipeSelect(e, recipe, true)}
          onDelete={(e) => onRecipeSelect(e, recipe, false)} />)}

        {nutrients && <ChipCalorie label={`${cal} kcal`} />}
      </Box>
      <CardContent sx={{minHeight: '4rem', padding:{xs: '0 4px', md: '0 8px', lg: '0 16px'}}}>
        <Typography variant="body2" color="text.secondary">
          <TypographyClamp3>{recipe.description}</TypographyClamp3>
        </Typography>
      </CardContent>
      <CardOverlayContent recipe={recipe} />
      </CardActionArea>
    </Card>
    </>
  );
}

export default RecipeCard;
