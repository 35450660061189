import React, {CSSProperties} from "react";
import {Box, Container, useTheme} from "@mui/material";

interface ISectionProps {
  title: string
  subtitle: string
  children: React.ReactNode
  containerStyle?: CSSProperties
}
const Section = ({title, subtitle, children, containerStyle}: ISectionProps) => {
  const theme = useTheme();
  return (
    <Box sx={{...containerStyle, padding: '3rem 0'}}>
      <Container sx={{paddingTop: '3rem'}}>
        <Box sx={{marginBottom: '35px'}}>
          <Box sx={{
            color: `${theme.palette.warning.main}`,
            fontSize: '1.25rem',
            fontWeight: '600',
            textTransform: 'uppercase',
            letterSpacing: '4px',
            marginBottom: '10px'
          }}>{title}</Box>
          <Box sx={{
            tineHeight: '60px',
            fontWeight: '400',
            fontFamily: 'Dancing Script, cursive',
            fontStyle: 'italic',
            fontSize: 'calc(1.325rem + 0.9vw)',
            color: `${theme.palette.primary.main}`,
          }}>{subtitle}</Box>
        </Box>
      </Container>
      <Container>
        {children}
      </Container>
    </Box>
  )
}
export default Section
