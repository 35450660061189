import { useState, useEffect } from 'react';
import {getUser} from '../api/api'
import {IUser} from '../api/IUser'

export interface IUserContext {
  user: IUser
  setUser: (user: IUser) => void
  isLoading: boolean
}

const useFindUser = (): IUserContext => {
    const [user, setUser] = useState<IUser>(null);
    const [isLoading, setLoading] = useState(true);
    const findUser = async () => {
      try {
        const me = await getUser()
        await setUser(me);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    }
    useEffect(() => {
        findUser();
    }, []);
    
    return {
        user,
        setUser,
        isLoading
    }
}

export default useFindUser
