import React from 'react'
import {Badge} from 'react-bootstrap'
import {IRecipeInstruction, IStep} from '../../../api/IRecipe'

interface IViewMetaDataProps {
  recipeInstruction: IRecipeInstruction
  steps: IStep[]
}
const ViewMetaData = ({recipeInstruction, steps}: IViewMetaDataProps): JSX.Element => (
  <div className="d-print-none mt-3 d-flex flex-wrap">
    {recipeInstruction?.meta?.map(e => (
      <>
        {/*<i className="fa fa-clock-o me-2">{e.duration}</i>*/}
        {/*<Badge pill className="me-2" bg={"secondary"}>{e.resource}</Badge>*/}
        <Badge pill className="me-2">{`${e.resource} - ${e.duration} mn - ${e.category}`}</Badge>
      </>
    ))}
    
    <Badge pill bg="dark" className="me-2">{recipeInstruction.dependencies?.map((d) => {
      const found = steps.findIndex((s) => s.recipeInstruction.id === d)
      if (found !== -1) {
        return `Étape - ${found + 1}  `
      } else return ""
    })
    }</Badge>
  </div>
)

export default ViewMetaData
