import {useNavigate} from "react-router-dom";
import {START_BATCH} from "../../../routes";
import {Button, Card, CardActions, CardContent, CardHeader, Container, Typography} from "@mui/material";
import React from "react";

export const EmptyBatch = () => {
  const navigate = useNavigate()
  const navigateToWizard = () => {navigate(START_BATCH)}
  return (
    <Container sx={{marginBottom: '2rem', marginTop:'1rem',textAlign: 'center'}}>
      <Card >
        <CardHeader title={"Je choisis mon lot..."} subheader={"Quel menu pour quelle semaine?"}/>
        <CardContent>
          <Typography>
            {"Je me laisse guider, je choisis le menu, j'essaie de nouvelles idées avec des légumes de saison."}
            <br/>
            {"Je selectionne la semaine, j'imprime le menu et la liste des courses."}
          </Typography>
        
        </CardContent>
        <CardActions sx={{textAlign: 'center', justifyContent: 'space-evenly'}}>
          <Button onClick={navigateToWizard} variant="contained">1, 2, 3... Go!</Button>
        </CardActions>
      </Card>
    </Container>
  )
}
