import {IconButton, lighten, styled} from "@mui/material";

const RoundIconButton = styled(IconButton)(({ theme, color = 'primary' }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: '100%',
  height: '28px',
  margin: '0.5rem',
  color:theme.palette[color].main,
  ':hover': {
    backgroundColor: lighten(theme.palette[color].main, 0.9),
  },
}));
export default RoundIconButton;
