import React from 'react'
import {IRecipe} from '../../../api/IRecipe'
import {BASE_RECIPES, RECIPE} from "../../../routes";
import {Grid} from "@mui/material";
import RecipeCard from "./RecipeCard";
import RecipeTable from "./RecipeTable";
import {useNavigate} from "react-router-dom";

interface IRecipeListProps {
  recipes: IRecipe[]
  onSelect?: (r: IRecipe) => void
  onDeselect?: (r: IRecipe) => void
  setError: (msg: string) => void
  setRefresh?: (n: boolean) => void
  selection?: IRecipe[]
  view?: string,
  base?: boolean
}

const RecipeList = ({recipes, selection, onSelect, onDeselect, setError, setRefresh, view = "RecipeCard", base = false}: IRecipeListProps): JSX.Element => {
  
  const navigate = useNavigate()
  const getCount = (id: string): number => {
    return selection?.filter(r=> r.id === id).length
  }

  return (
    <>
      {view === "RecipeCard" && recipes?.map((recipe, idx) => (
          <Grid item key={idx} xs={6} md={4} lg={3}>
            <RecipeCard
              count={getCount(recipe.id)}
              onSelect={onSelect}
              onDeselect={onDeselect}
              setRefresh={setRefresh}
              onClick={() => {
                navigate(`${RECIPE}${recipe.id}`)
              }}
              recipe={recipe}/>
          </Grid>
        )
      )}
      {view === "RecipeTable" && (
        <RecipeTable
          recipes={recipes}
          setError={setError}
          setRefresh={setRefresh}
          base={base} />
        )
      }
    </>
  );
}
export default RecipeList
