import React, {useEffect, useState} from 'react'
import {Offcanvas, Form} from 'react-bootstrap'
import {INameQuantified, IRecipe, IRecipeInstruction} from '../../../api/IRecipe'
import {ingredientDisplay} from '../utils/Convertion'
import {capitalizeAndPunctuation, isNumber} from '../../../utils/Text'
import {convertIngredientsQuantity} from "../../../utils/convertPayload";

interface IMetaIngredient {
  onInstructionMetaIngredientChange: (ing: INameQuantified[]) => void
  instruction: IRecipeInstruction
  ingredients: any[]
  show: boolean
  setShow: (boolean) => void
}
const MetaIngredient = ({
  onInstructionMetaIngredientChange,
  instruction,
  ingredients,
  show,
  setShow}: IMetaIngredient): JSX.Element => {
  
 const [ingredientsLocal, setIngredientsLocal] = useState<INameQuantified[]>(convertIngredientsQuantity(ingredients))
  useEffect(()=>{
    setIngredientsLocal(convertIngredientsQuantity(ingredients))
  }, [ingredients])
  
  const [checkedItems, setCheckedItems] = useState<INameQuantified[]>(instruction?.metaIngredients || [])
  const groups = [...new Set(ingredientsLocal?.filter(i => i.for !== 'n/a' && i.for !== '').map(i => i.for))]
  
  const onClose = () => {
    setShow(false)
    onInstructionMetaIngredientChange(checkedItems)
  }
  const ingredientEqual = (ingredient1, ingredient2) => {
    return ingredient1.name === ingredient2.name && ingredient1.unit === ingredient2.unit && ingredient1.quantity === ingredient2.quantity && ingredient1.for === ingredient2.for
  }
  return (
    <Offcanvas show={show} placement="end" onHide={onClose}>
    <Offcanvas.Header closeButton>
      <Offcanvas.Title>Choississez les ingrédients associés à cette instruction</Offcanvas.Title>
    </Offcanvas.Header>
    <Offcanvas.Body>
      {ingredientsLocal?.filter(e=>!e.baseRecipe)?.map((ing: INameQuantified, i: number) => {
        return <div key={i}>
            {(ing.for === '' || ing.for === 'n/a') && <div>
              <Form.Check
                className={"ms-auto"}
                checked={checkedItems?.find(elt => ingredientEqual(elt, ing)) !== undefined}
                onChange={() => {
                  const foundIndex = checkedItems?.findIndex(ii => ingredientEqual(ii, ing))
                  
                  if (foundIndex === -1 ) {
                    setCheckedItems([...checkedItems, ing])
                  } else {
                    setCheckedItems([...checkedItems?.slice(0, foundIndex), ...checkedItems?.slice(foundIndex+1)])
                  }
                }}
                inline
                label={ingredientDisplay(ing)}
                type="checkbox"
                id={`checked-${i}`} />
            </div>}
          </div>
        })
      }
      {groups.map((g, i) => (
        <div className="mt-2" key={i}>
          <div className={'fw-bold'}>{capitalizeAndPunctuation(g, ':')}</div>
          {ingredientsLocal?.filter(e=>!e.baseRecipe)?.map((ing: INameQuantified, index: number) => {
            return (
              <>
                {ing.for === g && <div key={index}>
                  <Form.Check
                    className={"ms-auto"}
                    checked={checkedItems?.find(elt => ingredientEqual(elt, ing)) !== undefined}
                    onChange={() => {
                      const foundIndex = checkedItems?.findIndex(ii => ingredientEqual(ii, ing))
                      if (foundIndex === -1) {
                        setCheckedItems([...checkedItems, ing])
                      } else {
                        setCheckedItems([...checkedItems?.slice(0, foundIndex), ...checkedItems?.slice(foundIndex+1)])
                      }
                    }}
                    inline
                    label={ingredientDisplay(ing)}
                    type="checkbox"
                    id={`checked-${index}`} />
        
                </div>}
              </>
            )
          })}
        </div>
  
      ))}
    </Offcanvas.Body>
  </Offcanvas>)
}

export default MetaIngredient;
