import React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import {IRecipe} from '../../../../api/IRecipe'
import cn from 'classnames'
import RecipeCard from "../../../recipe/list/RecipeCard";


interface IColumnItemProps {
  recipe: IRecipe
  index: number
}
const ColumnItem = ({recipe, index}: IColumnItemProps): JSX.Element => {
  return (
    <Draggable draggableId={recipe.id} index={index}>
      {(provided, snapshot) => (
        <div className={cn('column-item', {['column-item-dragging']:snapshot.isDragging })}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
         <RecipeCard recipe={recipe} />
        </div>
      )}
    </Draggable>
  );
}

export default ColumnItem
