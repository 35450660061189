import { FormControl, FormGroup, Modal} from 'react-bootstrap'
import React, {ChangeEvent, useEffect, useState} from 'react'
import {INameQuantified, IRecipe} from '../../../api/IRecipe'
import {getTool} from '../../../api/api'
import CreatableSelect from 'react-select/creatable'
import {Box, Button, Grid, IconButton, Typography} from "@mui/material";
import TitleTypography from "../../../components/TitleTypography";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

interface IRecipeFormUstencilsProps {
  recipeTools: INameQuantified[]
  setFieldValue: (t, t2) => void
}
const RecipeFormUstencils = (
  {recipeTools, setFieldValue}:IRecipeFormUstencilsProps
): JSX.Element => {

  const initial = (s: INameQuantified[]): INameQuantified[] => {
    if (!s || s.length === 0) {
      return [{
        'name': '',
        'quantity': 1
      } as INameQuantified]
    }
    return s
  }
  const [tools, setToolsFromLocalState] = useState<INameQuantified[]>(initial(recipeTools))
   const setTools = (t) => {
     setToolsFromLocalState(t)
     setFieldValue(`tool`, t)
   }
  
  const [optionsTool, setOptionsTool] = useState<INameQuantified[]>()
  useEffect(() => {
    const fetchTools = async() => {
      try {
        const toolsList = await getTool()
        console.log(`there are ${toolsList?.length} ingredients from server`)
        const options = toolsList.map(i => {
          return {
            name:i.name,
            label:i.name,
            value: i.name.toLowerCase().replace(/\W/g, ''),
            quantity: i.quantity
          }
        })
        setOptionsTool(options)
      } catch (error) {
        console.log(`an error ${error}`)
      }
    }
    fetchTools()
  }, [])
  
  const handleChange = (idx: number, v: string) => {
    tools[idx].name = v
    setTools([...tools.slice(0, idx), tools[idx], ...tools.slice(idx+1)])
  }
  const onQuantityChange = (idx, v) => {
    tools[idx].quantity = +v
    setTools([...tools.slice(0, idx), tools[idx], ...tools.slice(idx+1)])
  }
  const onAdd = () => {
    setTools([...tools, {name: '', quantity: 1}])
  }
  const onDelete = (idx) => {
    setTools([...tools.slice(0, idx), ...tools.slice(idx+1)] || [{
      'name': '',
      quantity: 1
    } as INameQuantified])
  }
  const createOption = (label: string) => ({
    label,
    name:label.toLowerCase().replace(/\W/g, ''),
    quantity: 1,
    value: label.toLowerCase().replace(/\W/g, '')
  });
  const handleCreateTool = (idx: number, inputValue: string) => {
    const newOption = createOption(inputValue);
    setOptionsTool([...optionsTool, newOption])
    tools[idx].name = inputValue
    setTools([...tools.slice(0, idx), tools[idx], ...tools.slice(idx+1)])
  }
  
  return (
    <>
      <Grid item xs={12}>
        <TitleTypography>Les ustensiles</TitleTypography>
      </Grid>
      <Grid item xs={12}>
        {tools && tools.length > 0 && tools.map((tool, idx) => {
          return (
            <FormGroup key={`tool-${idx}`} className="d-flex mb-2" controlId="suggestion">
              <FormControl
                placeholder={"Quantité"}
                style={{width:50}}
                className={"me-2"}
                type="number"
                value={tool.quantity}
                name="quantity"
                onChange={(props:ChangeEvent<HTMLInputElement>) => {
                  onQuantityChange(idx, props.target.value)}}
              />
              <CreatableSelect
                className={"me-2 w-100"}
                onChange={(u) => handleChange(idx, u.name)}
                options={optionsTool}
                value={tool.name?createOption(tool.name):createOption('n/a')}
                onCreateOption={(v) => handleCreateTool(idx, v)}
                formatCreateLabel={(v) => `Créer ${v}`}
              />
              <IconButton sx={{maxHeight: '35px'}} color="primary" onClick={() => onDelete(idx)} >
                <DeleteOutlineIcon sx={{fontSize: '1.2rem', height: '20px'}}/>
              </IconButton>
              <IconButton sx={{maxHeight: '35px'}}  color="primary" onClick={onAdd}>
                <AddCircleOutlineIcon sx={{fontSize: '1.2rem'}}/>
              </IconButton>
            </FormGroup>
          )
        })}
        {!tools || tools.length === 0 && (
          <Box sx={{marginLeft: 'auto', textAlign: 'right', marginRight: '40px'}}>
            <Button
              variant="outlined"
              startIcon={<AddCircleOutlineIcon />}
              onClick={onAdd}
              color="primary" aria-label="add">
              Ajouter un ustencile
            </Button>
          </Box>
        )}
      </Grid>
    </>
  )
}
export default RecipeFormUstencils
