import React from 'react'
import {PageHeader, IBreadcrumbItemProps} from '../PageHeader'
import {Alert, Box, Skeleton} from "@mui/material";

export interface IError {
  message: string
}

export interface IHeaderBar {
  error?: IError
  isLoading?: boolean
  action?: JSX.Element
  search?: JSX.Element
  title?: string
  subtitle?: string
  breadcrumbs?: IBreadcrumbItemProps[]
}

const ActionBar = ({error, search, action, title, subtitle, breadcrumbs, isLoading = false}: IHeaderBar): JSX.Element =>{

  if (!error && !breadcrumbs && !action && !search) {
    return <></>
  }

  // TODO error => toast message

  return (<PageHeader.Wrapper>
    {breadcrumbs && (<PageHeader.Breadcrumbs>
      {breadcrumbs.map((b, idx: number) => {
        if (isLoading) {
          return (<PageHeader.BreadcrumbItem current={idx === breadcrumbs.length-1} key={idx} href={b.href}><Skeleton variant="text" width="100px" sx={{ fontSize: '1rem' }} /></PageHeader.BreadcrumbItem>)
        }
        return (<PageHeader.BreadcrumbItem current={idx === breadcrumbs.length-1} key={idx} href={b.href}>{b.children}</PageHeader.BreadcrumbItem>)
      })}
    </PageHeader.Breadcrumbs>)}
    {title && !isLoading && <PageHeader.Title>{title}</PageHeader.Title>}
    {subtitle && !isLoading && <PageHeader.Description>{subtitle}</PageHeader.Description>}
    <Box displayPrint={"none"}>{action}</Box>
    {isLoading && <PageHeader.Title><Skeleton variant="text" sx={{ fontSize: '1rem' }} width={"30vw"}/></PageHeader.Title>}
    {isLoading && <PageHeader.Description><Skeleton variant="text" height={10} width="100%" /><Skeleton variant="text" height={10} width="80%" /></PageHeader.Description>}
    {error && <Alert severity="error">
      Il y a une erreur 🤭: {error?.message}.
    </Alert>}
  </PageHeader.Wrapper>)
}
export default ActionBar
