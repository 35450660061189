import React, { useMemo } from 'react'
import * as Yup from "yup";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import './LoginForm.scss';
import { Form } from "../../components/Form";
import { CircularProgress } from "@mui/material";
import {IRegistrationFormValues} from "./types";

const RegistrationForm = ({handleSubmit}): JSX.Element => {
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string().required("Email is required"),
        password: Yup.string().required("Password is required"),
       }),
    []
  );
  
  return (
    <Form.Context <IRegistrationFormValues>
      initialValues={{}}
      isInitialValid={false}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, isValid, isSubmitting }) => (
        <Form.Form onSubmit={handleSubmit}>
          <Grid sx={{marginTop: '1rem'}} container spacing={2}>
            <Grid item xs={12} md={6}>
              <Form.TextField
                id="firstname"
                label="Firstname"
                name="firstname"
                autoComplete="firstname"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Form.TextField
                id="lastname"
                label="Lastname"
                name="lastname"
                autoComplete="lastname"
              />
            </Grid>
            <Grid item xs={12}>
              <Form.TextField
                id="email"
                label="Email address"
                name="email"
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <Form.TextField
                id="password"
                label="Password"
                name="password"
                type="password"
                autoComplete="password"
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={!isValid}
          >
            {isSubmitting ? <CircularProgress color="inherit" size={24}/> : "S'enregistrer"}
          </Button>
        </Form.Form>
      )}
    </Form.Context>
  );
}
export default RegistrationForm
