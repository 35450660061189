import {Badge, Card} from 'react-bootstrap'
import React from 'react'
import cn from 'classnames'
import {baseURL} from "../../../api/api";
import {IRecipe} from "../../../api/IRecipe";
import {capitalize} from "@mui/material";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

interface IRecipeCardSmallProps {
  recipe: IRecipe,
  className?: string
  onDeselect: (r: IRecipe) => void
}

const RecipeCardTiny = ({recipe, className, onDeselect}: IRecipeCardSmallProps): JSX.Element => {
  let imageURL = "https://via.placeholder.com/800x600"
  if (recipe?.image?.length > 0) {
    if (recipe.image[0].thumbnail?.length > 0) {
      imageURL = recipe.image[0].thumbnail[0]
    } else {
      imageURL = recipe.image[0].contentUrl
    }
    imageURL = `${baseURL}/v1/images/${imageURL}`
  }
  
  return (
      <Card className={cn(className, "cursor-pointer recipe-card")}>
        {recipe.name && (
          <>
            <Badge
              bg="light"
              className="corner-badge small" onClick={() => onDeselect(recipe)}>
              <DeleteOutlineIcon fontSize={'small'}/>
            </Badge>
            <Card.Img variant="top" src={imageURL} />
            <Card.Body style={{padding: 3}} className={"d-flex flex-column"}>
              <div className={"small recipe-card-title text-muted"}>{capitalize(recipe?.name || 'empty')}</div>
            </Card.Body>
          </>)}
      </Card>
  )
}
export default RecipeCardTiny
