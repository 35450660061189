import {IRecipeInstruction} from "../../../api/IRecipe";
import React, {useEffect, useState} from "react";
import {FieldArray} from "formik";
import {Box, Button, Grid, IconButton, TextField} from "@mui/material";
import {InnerNumber} from "../InnerNumber";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const SubInstructions = ({instructions, idx, setFieldValue}: {instructions: IRecipeInstruction[], setFieldValue: any, idx: number}) => {
  const [subInstructions, setSubInstructions] = useState<IRecipeInstruction[]>(instructions[idx].recipeInstructions)
  
  useEffect(() => {
    setSubInstructions(instructions[idx].recipeInstructions)
  }, [instructions])
  
  return (
    <>
      {subInstructions && subInstructions.length > 0 ? (
        <FieldArray
          name="subInstructions"
          render={({}) => ( // sous-instruction
            <Grid item sx={{marginLeft: '40px', marginRight: '40px'}}>
              {subInstructions.map((ii: IRecipeInstruction, idxx: number) => {
                return (
                  <Grid key={`${new Date().toDateString()}-${idxx}`}>
                    <Box display="flex" alignItems="flex-start">
                      <InnerNumber>
                        {String.fromCharCode('a'.charCodeAt(null) + idxx)}
                      </InnerNumber>
                      <TextField
                        sx={{margin: '4px 4px 0 0',  '& legend': { display: 'none' }, '& fieldset': { top: 0 }}}
                        fullWidth
                        multiline
                        minRows={2}
                        size="small"
                        name={`recipeInstructions.${idx}.instructions.${idxx}.name`}
                        value={ii.name}
                        onChange={(e) => {
                          const newInstructions = [...subInstructions.slice(0, idxx), {...subInstructions[idxx], name: e.target.value}, ...subInstructions.slice(idxx+1)]
                          setSubInstructions(newInstructions)
                        }}
                        onBlur={(e) => {
                          setFieldValue(`recipeInstructions.${idx}.recipeInstructions.${idxx}.name`, subInstructions[idxx].name)
                        }}
                      />
                      <IconButton
                        color="primary" onClick={() => {
                        const newArray =  [...subInstructions.slice(0, idxx), ...subInstructions.slice(idxx+1)]
                        console.log(`DELETE SUB INSTRUCTION`, newArray)
                        setSubInstructions(newArray)
                        setFieldValue(`recipeInstructions.${idx}.recipeInstructions`, JSON.parse(JSON.stringify(newArray)))
                      }} aria-label="delete">
                        <DeleteOutlineIcon sx={{fontSize: '1.3rem'}}/>
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          const newArray =  [...subInstructions.slice(0, idxx+1), {name: ''} , ...subInstructions.slice(idxx+1)]
                          console.log(`ADD SUB INSTRUCTION`, newArray)
                          setSubInstructions(newArray)
                          setFieldValue(`recipeInstructions.${idx}.recipeInstructions`, newArray)
                        }}
                        color="primary" aria-label="add">
                        <AddCircleOutlineIcon sx={{fontSize: '1.2rem'}}/>
                      </IconButton>
                    </Box>
                  </Grid>
                )
              })}
            </Grid>)}/>
      ) : (
        <Box sx={{marginLeft: 'auto', textAlign: 'right', marginRight: '40px', marginTop: '8px'}}>
          <Button
            variant="outlined"
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => {
              const newArray =  [{name: ''}]
              console.log(`ADD SUB INSTRUCTION`, newArray)
              setSubInstructions(newArray)
              setFieldValue(`recipeInstructions.${idx}.recipeInstructions`, newArray)
            }}
            color="primary" aria-label="add">
            Ajouter une sous-instruction
          </Button>
        </Box>

      )}
    </>
  )
}

export default SubInstructions
