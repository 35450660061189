import { ReactNode } from 'react'

import { ACTIONS_WRAPPER_DISPLAY_NAME } from './const'

export const getChild = (child: any, displayName: string) =>
  child?.type?.displayName === displayName

// gets children from Actions Wrapper
export const isActionsChild = (child: any) => getChild(child, ACTIONS_WRAPPER_DISPLAY_NAME)

export const getActionsChildren = (children: ReactNode[]) =>
  children.filter(child => isActionsChild(child))

export const filterActionsChildren = (children: ReactNode[]) =>
  children.filter(child => !isActionsChild(child))
