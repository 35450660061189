import { Route, Routes } from 'react-router-dom'
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import './bootstrap-overrides.scss';
import PrivateRoute from './hooks/PrivateRoute';
import UserContext from './hooks/UserContext'
import {
  LOGIN,
  REGISTERED,
  PREFERENCES,
  MY_SPACE,
  MY_BATCHES,
  RECIPES,
  BASE_RECIPES,
  CHEF_SUGGESTED_BATCHES,
  OUR_SUGGESTIONS
} from "./routes";
import useFindUser from "./hooks/useFindUser";
import BaseLayout from "./layouts/BaseLayout";
import SidebarLayout from "./layouts/SidebarLayout";
import LoginPage from "./content/login/LoginPage";
import RecipeListPage from "./content/recipe/list/RecipeListPage";
import RecipeViewPage from "./content/recipe/view/RecipeViewPage";
import RecipeFormPage from "./content/recipe/edit/RecipeFormPage";
import BatchListPage from "./content/our-suggestions/BatchListPage";
import MyCurrentBatch from "./content/batch/view/MyCurrentBatch";
import MyBatchListPage from "./content/batch/list/MyBatchListPage";
import LandPage from "./content/overview/LandPage";
import BaseRecipeListPage from "./content/base-recipe/list/RecipeListPage";
import SuggestedBatchListPage from "./content/batch/list/SuggestedBatchListPage";
import BatchWizardPage from "./content/batch/create/BatchWizardPage";
import PreferencesPage from "./content/login/PreferencesPage";
import SuggestedBatchPage from "./content/batch/view/SuggestedBatchPage";
import BatchDisplayPage from "./content/batch/view/BatchDisplayPage";
import RegisteredPage from "./content/login/RegisteredPage";
import CGU from "./content/overview/CGU";
import PolitiqueDeConfidentialite from "./content/overview/PolitiqueDeConfidentialite";

function App() {
  const { user, setUser, isLoading } = useFindUser();

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline/>
        <UserContext.Provider value={{user, setUser, isLoading}}>
          <Routes>
            <Route path={LOGIN} element={<LoginPage/>}/>
            <Route path={REGISTERED} element={<RegisteredPage/>}/>
            <Route
              path={PREFERENCES}
              element={
                <PrivateRoute>
                  <SidebarLayout>
                    <PreferencesPage/>
                  </SidebarLayout>
                </PrivateRoute>}/>

            <Route
              path={`${OUR_SUGGESTIONS}`}
              element={
                <PrivateRoute>
                  <SidebarLayout>
                    <BatchListPage/>
                  </SidebarLayout>
                </PrivateRoute>
              }
            />
            {/*
             ===> mon_espace => mon batch en cours
             */}
            <Route
              path={MY_SPACE}
              element={<PrivateRoute>
                <SidebarLayout>
                  <MyCurrentBatch/>
                </SidebarLayout>
              </PrivateRoute>}/>

            {/*
             ===> mes_batches
             ===> mes_batches/XXXX
             ===> mes_batches/create
             */}
            <Route path={`${MY_BATCHES}/*`}>
              <Route
                path=""
                element={
                  <PrivateRoute>
                    <SidebarLayout>
                      <MyBatchListPage/>
                    </SidebarLayout>
                  </PrivateRoute>}/>
              <Route
                path={`:batchId`}
                element={
                  <PrivateRoute>
                    <SidebarLayout>
                      <BatchDisplayPage/>
                    </SidebarLayout>
                  </PrivateRoute>}/>
              <Route
                path="create"
                element={
                  <PrivateRoute>
                    <SidebarLayout>
                      <BatchWizardPage/>
                    </SidebarLayout>
                  </PrivateRoute>
                }
              />
            </Route>

            {/*
             ===> les_recettes
             ===> les_recettes/create
             ===> les_recettes/XXXX
             ===> les_recettes/XXXX/edit
             */}
            <Route path={`${RECIPES}/*`}>
              <Route
                path=""
                element={
                  <PrivateRoute>
                    <SidebarLayout>
                      <RecipeListPage view="RecipeCard"/>
                    </SidebarLayout>
                  </PrivateRoute>}/>
              <Route path=":recipeId" element={
                <PrivateRoute>
                  <SidebarLayout>
                    <RecipeViewPage/>
                  </SidebarLayout>
                </PrivateRoute>
              }/>
              <Route path=":recipeId/edit" element={
                <PrivateRoute>
                  <SidebarLayout>
                    <RecipeFormPage/>
                  </SidebarLayout>
                </PrivateRoute>
              }/>
              <Route path="create" element={
                <PrivateRoute>
                  <SidebarLayout>
                    <RecipeFormPage/>
                  </SidebarLayout>
                </PrivateRoute>
              }/>
            </Route>

            {/*
             ===> chef/les_techniques
             ===> chef/les_techniques/create
             ===> chef/les_techniques/XXXX
             ===> chef/les_techniques/XXXX/edit
             */}
            <Route path={`${BASE_RECIPES}/*`}>
              <Route
                path={''}
                element={
                  <PrivateRoute>
                    <SidebarLayout>
                      <BaseRecipeListPage/>
                    </SidebarLayout>
                  </PrivateRoute>}/>
              <Route path="create" element={
                <PrivateRoute>
                  <SidebarLayout>
                    <RecipeFormPage base={true}/>
                  </SidebarLayout>
                </PrivateRoute>
              }/>
              <Route path=":recipeId" element={
                <PrivateRoute>
                  <SidebarLayout>
                    <RecipeViewPage base={true}/>
                  </SidebarLayout>
                </PrivateRoute>
              }/>
              <Route path=":recipeId/edit" element={
                <PrivateRoute>
                  <SidebarLayout>
                    <RecipeFormPage base={true}/>
                  </SidebarLayout>
                </PrivateRoute>
              }/>
            </Route>

            {/*
             ===> chef/batches
             ===> chef/batches/create
             ===> chef/batches/XXXX
             ===> chef/batches/XXXX/edit
             */}
            <Route path={`${CHEF_SUGGESTED_BATCHES}/*`}>
              <Route
                path=""
                element={
                  <PrivateRoute>
                    <SidebarLayout>
                      <SuggestedBatchListPage/>
                    </SidebarLayout>
                  </PrivateRoute>}/>
              <Route path="create" element={
                <PrivateRoute>
                  <SidebarLayout>
                    <BatchWizardPage/>
                  </SidebarLayout>
                </PrivateRoute>
              }/>
              <Route path=":batchId" element={
                <PrivateRoute>
                  <SidebarLayout>
                    <SuggestedBatchPage/>
                  </SidebarLayout>
                </PrivateRoute>
              }/>
              <Route path=":batchId/edit" element={
                <PrivateRoute>
                  <SidebarLayout>
                    <BatchWizardPage/>
                  </SidebarLayout>
                </PrivateRoute>
              }/>
            </Route>


            <Route path={'/'} element={
              <BaseLayout><LandPage/></BaseLayout>
            }/>
            <Route path={'/cgu'} element={
              <BaseLayout><CGU /></BaseLayout>
            }/>
            <Route path={'/pdc'} element={
              <BaseLayout><PolitiqueDeConfidentialite /></BaseLayout>
            }/>
          </Routes>
        </UserContext.Provider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
