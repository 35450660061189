import React from 'react'
import {INameQuantified} from '../../../api/IRecipe'
import {ingredientDisplayStrong} from '../../recipe/utils/Convertion'

const ViewIngredients = ({ingredients}: {ingredients: INameQuantified[]}): JSX.Element => {
  
  return (
    <div className="h-100" style={{wordBreak: 'break-word'}}>
        {ingredients.map((ing: INameQuantified, i: number) => {
          return (
            <span key={i}>
              {ingredientDisplayStrong(ing)}
            </span>
          )
        })}
    </div>
  )
}

export default ViewIngredients
