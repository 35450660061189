import { useContext } from 'react';

import {
  ListSubheader,
  List,
  Button,
  ListItem
} from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
import { SidebarContext } from 'src/contexts/SidebarContext';

import HomeIcon from '@mui/icons-material/Home';
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import BrunchDiningIcon from '@mui/icons-material/BrunchDining';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import TocOutlinedIcon from '@mui/icons-material/TocOutlined';
import AppsOutageOutlinedIcon from '@mui/icons-material/AppsOutageOutlined';
import {
  MY_BATCHES,
  RECIPES,
  BASE_RECIPES,
  CHEF_SUGGESTED_BATCHES,
  MY_SPACE,
  PREFERENCES,
  OUR_SUGGESTIONS
} from "../../../../routes";
import SubMenuWrapper from "./SubMenuWrapper";
import MenuWrapper from "./MenuWrapper";
import UserContext from "../../../../hooks/UserContext";
import {useSearch} from "../../../../components/search/useSearch";

function SidebarMenu() {
  const { closeSidebar } = useContext(SidebarContext);
  const { user } = useContext(UserContext)
  const {setSearch} = useSearch()
  const navigateOut = () => {
    setSearch("name", "")
    closeSidebar()
  }
  return (
    <MenuWrapper>
      <List component="div">
        <SubMenuWrapper>
          <List component="div">
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={navigateOut}
                to="/"
                startIcon={<HomeIcon />}
              >
                Accueil
              </Button>
            </ListItem>
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={navigateOut}
                to={RECIPES}
                startIcon={<BrunchDiningIcon />}
              >
                Recettes
              </Button>
            </ListItem>
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={navigateOut}
                to={OUR_SUGGESTIONS}
                startIcon={<CalendarMonthIcon />}
              >
                Batchs
              </Button>
            </ListItem>
          </List>
        </SubMenuWrapper>
      </List>
      {user && !user.roles.includes("public") && (
        <List
        component="div"
        subheader={
          <ListSubheader component="div" disableSticky>
            Mon Espace
          </ListSubheader>
        }
      >
        <SubMenuWrapper>
          <List component="div">
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={navigateOut}
                to={MY_SPACE}
                startIcon={<CalendarTodayIcon />}
              >
                Mon batch en cours
              </Button>
            </ListItem>
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={navigateOut}
                to={MY_BATCHES}
                startIcon={<AppRegistrationIcon />}
              >
                Mes batchs
              </Button>
            </ListItem>
          </List>
        </SubMenuWrapper>
      </List>)}
      {user?.roles?.includes('creator') && <List
        component="div"
        subheader={
          <ListSubheader component="div" disableSticky>
            L'espace des chefs
          </ListSubheader>
        }
      >
        <SubMenuWrapper>
          <List component="div">
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={navigateOut}
                to={BASE_RECIPES}
                startIcon={<TocOutlinedIcon />}
              >
                Recettes de base
              </Button>
            </ListItem>
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={navigateOut}
                to={CHEF_SUGGESTED_BATCHES}
                startIcon={<AppsOutageOutlinedIcon />}
              >
                Batchs du chef
              </Button>
            </ListItem>
          </List>
        </SubMenuWrapper>
      </List>}
      {/*{user && !user.roles.includes("public") && (<List*/}
      {/*  component="div"*/}
      {/*  subheader={*/}
      {/*    <ListSubheader component="div" disableSticky>*/}
      {/*      Profil utilisateur & Préférences*/}
      {/*    </ListSubheader>*/}
      {/*  }*/}
      {/*>*/}
      {/*  <SubMenuWrapper>*/}
      {/*    <List component="div">*/}
      {/*      /!*<ListItem component="div">*!/*/}
      {/*      /!*  <Button*!/*/}
      {/*      /!*    disableRipple*!/*/}
      {/*      /!*    component={RouterLink}*!/*/}
      {/*      /!*    onClick={navigateOut}*!/*/}
      {/*      /!*    to="/"*!/*/}
      {/*      /!*  >*!/*/}
      {/*      /!*    Mon Profil*!/*/}
      {/*      /!*  </Button>*!/*/}
      {/*      /!*</ListItem>*!/*/}
      {/*      <ListItem component="div">*/}
      {/*        <Button*/}
      {/*          disableRipple*/}
      {/*          component={RouterLink}*/}
      {/*          onClick={navigateOut}*/}
      {/*          to={PREFERENCES}*/}
      {/*        >*/}
      {/*          Mes préférences*/}
      {/*        </Button>*/}
      {/*      </ListItem>*/}
      {/*    </List>*/}
      {/*  </SubMenuWrapper>*/}
      {/*</List>)}*/}
    </MenuWrapper>
  );
}

export default SidebarMenu;
