import React, {useContext, useState} from 'react'
import {Button, Card, Col, Container, Form, OverlayTrigger, Row, Tooltip} from 'react-bootstrap'
import {getUser, updateUserPreferences} from '../../api/api'
import UserContext from '../../hooks/UserContext'
import ImageUploading, {ImageListType, ImageType} from 'react-images-uploading'
import {COMPACT, COMPLETE} from '../../utils/context'
import ActionBar from "../../components/ActionBar";
import Select from 'react-select'

interface IImageUploadDisplayProps {
  imageList: ImageType,
  onImageUpload: () => void,
  dragProps: {
    onDrop: (e) => void;
    onDragEnter: (e) => void;
    onDragLeave: (e) => void;
    onDragOver: (e) => void;
  }
}

const ImageUploadDisplay = ({imageURL}: {imageURL: string}): (type: IImageUploadDisplayProps) => JSX.Element => {
  
  const inner = ({
                   imageList,
                   onImageUpload,
                   dragProps
                 }: IImageUploadDisplayProps): JSX.Element => {
    
    return (
      <div className="upload__image-wrapper">
        <div className="image-item">
          <Card className="recipe-card">
            <Card.Img  {...dragProps} style={{opacity: 50, backgroundColor: '#ced4da'}}
                       src={imageList[0]?.dataURL || imageURL} alt={""}/>
            <Card.ImgOverlay className={'white-opacity-90 position-bottom'}>
              <Button variant="outline-primary mb-2 text-end"
                      onClick={onImageUpload}>
                Choisir une photo...
              </Button>
            </Card.ImgOverlay>
          </Card>
        </div>
      </div>
    )
  }
  
  return ({imageList, onImageUpload, dragProps}) => inner({imageList, onImageUpload, dragProps})
}


const PreferencesPage = ():JSX.Element => {
  const ctx = useContext(UserContext)
  const [success, setSuccess] = useState(null)
  const [impression, setImpression] = useState({label: ctx.user?.preferences?.impression, value: ctx.user?.preferences?.impression})
  const [error, setError] = useState(null)
  
  const onSave = async () => {
    console.log(`SAVING PREFERENCES`)
    try {
     await updateUserPreferences({
        preferences: {
          impression: impression.value
        }
      })
      const u = await getUser()
      ctx.setUser(u)
      setSuccess('Vos préférences ont été enregistrées.')
    } catch (err) {
      setError(err)
      setSuccess(null)
    }
  }
  const breads = [
    {
      href: '/',
      children: 'Accueil'
    },
    {
      href: null,
      children: 'Mes préférences'
    }
  ]
  
  const [images, setImages] = React.useState([]);
  
  const onChange = (
    imageList: ImageListType
  ) => {
    setSuccess('Vous avez fait des modifications.')
    setError(null)
    setImages(imageList as never[]);
  };
  //`${baseURL}/v1/images/${recipe?.image[0]?.contentUrl}`
  const imageURL = "https://via.placeholder.com/200x200"
  
  return (
    <>
      <ActionBar
        error={error}
        breadcrumbs={breads}
        action={<>
          <Button onClick={onSave} className="ms-auto" variant="primary">Enregistrer</Button>
        </>}
        search={<>
          {success === 'Votre recette a été enregistrée.' && (
            <span><i className="text-primary h4 fa fa-check"/>{` ${success}`}</span>)}
          {success !== '' && success !== 'Votre recette a été enregistrée.' && (<span>{success}</span>)}
        </>}
      />
      <Container className="min-vh-100">
        <Row>
          <Col xs={12} lg={4} className="mt-3">
            <ImageUploading
              multiple={false}
              value={images}
              resolutionType={"less"}
              maxFileSize={10000}
              onChange={onChange}
              onError={(e) => {
                if (e.resolution) {
                  setError({message: "La resolution de l'image doit être de 800 par 600"})
                } else if (e.maxFileSize) {
                  setError({message: "La taille maximum est atteinte"})
                } else {
                  setError({message: "inconnue"})
                }
              }}>
              {ImageUploadDisplay({imageURL})}
            </ImageUploading>
            
          </Col>
          <Col xs={12} lg={8} className="mt-3">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                {"Type d'impression"}
                <OverlayTrigger overlay={<Tooltip id="impression">Compacte: sans image, informations essentielles, Complète: avec image et toutes les informations</Tooltip>}>
                  <i className="ms-2 fa fa-info-circle me-3"/>
                </OverlayTrigger>
              </Form.Label>
              <Select
                className="w-50"
                placeholder={"Type d'impression..."}
                value={impression}
                onChange={(v) => {
                  setSuccess('Vous avez fait des modifications.')
                  setError(null)
                  setImpression(v)
                }}
                options={[{label: COMPLETE, value: COMPLETE}, {label: COMPACT, value: COMPACT}]}
              />
            </Form.Group>

          </Col>
        </Row>
      </Container>
    </>
  )
}
export default PreferencesPage
