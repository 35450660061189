import React, {useEffect, useState} from 'react'
import {IBatch} from '../../api/IRecipe'
import {getSuggestedBatches} from '../../api/api'
import {useNavigate} from "react-router-dom";
import {START_BATCH} from "../../routes";
import {BatchCard} from "../batch/components/BatchCard";
import { Container } from '@mui/material';

const BatchListPage = (): JSX.Element => {
  const [batches, setBatches] = useState<IBatch[]>([])
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate();
  useEffect( () => {
    const fetch = async() => {
      try {
        const allBatches = await getSuggestedBatches()
        setBatches(allBatches)
        setIsLoading(false)
      } catch (err) {
        setError(err)
        setIsLoading(false)
      }
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
    fetch()
  }, []);


  if (isLoading) {
    return (
        <Container>
        </Container>
    )
  }

  return (
    <Container sx={{marginBottom: '2rem'}}>
      {batches.map((batch, idx) => (
        <BatchCard key={idx} batch={batch} onClick={() => {navigate(START_BATCH, {state: {suggestedBatch: batch}})}} />
        )
      )}
    </Container>
  )
}

export default BatchListPage
