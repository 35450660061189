import {Box, Skeleton} from "@mui/material";

const LoadingSkeleton = () => (
  <Box>
    {[...Array(15)].map((i) => (
      <Skeleton key={i} variant="rectangular" sx={{ my: 4, mx: 1 }} />
    ))}
  </Box>
);

export default LoadingSkeleton
