import React from 'react'
import { Container } from 'react-bootstrap'
import { IBatch } from '../../api/IRecipe'
import Section from "../overview/Section";
import { Typography } from "@mui/material";
import { Gallery } from "./BatchGallery";

const StarBatchView = ({batch}:{batch: IBatch}): JSX.Element => {
  return (
    <>
      <Section title={"Cette semaine"} subtitle={`Cédric vous propose le batch ${batch?.name || 'de la semaine est en cours de préparation'}`}>
        <Gallery batch={batch}/>
      </Section>
      <Container fluid className="testimonial py-5">
        <Section title={"Nos valeurs"} subtitle={"Le mot du Chef Cédric"}>
          <Typography sx={{fontSize: '1.5rem'}}>
            {"Je veux vous aider à retrouver le plaisir de cuisiner pour mettre de la gastromonie dans vos assiettes."}
          </Typography>
        </Section>

      </Container>
    </>);
}

export default StarBatchView
