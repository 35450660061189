import React, {useContext, useEffect, useState} from 'react'
import {IRecipe} from '../../../api/IRecipe'

import {getBaseRecipes, searchBaseRecipes} from '../../../api/api'
import ActionBar from "../../../components/ActionBar";
import {PageHeader} from "../../../components/PageHeader";
import UserContext from '../../../hooks/UserContext'
import {Container, Box, Grid} from "@mui/material";
import {CREATE_BASE_RECIPE, RECIPES} from "../../../routes";
import RecipeList from "../../recipe/list/RecipeList";
import {Row} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {useDebounce} from "../../../utils/use-debounce";
import LoadingSkeleton from "../../../components/LoadingTable";


const BaseRecipeListPage = (): JSX.Element => {
  const [loading, setLoading] = useState(true)
  const [recipes, setRecipes] = useState<IRecipe[]>([])
  const [error, setError] = useState(null)
  const [search, setSearch] = useState('')
  const {user} = useContext(UserContext)
  const [refresh, setRefresh] = useState(false)
  const navigate = useNavigate()
  const debouncedSearchTerm = useDebounce(search, 700);
  
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, [])
  
  const fetch = async(searchTerms: string) => {
    try {
      let baseRecipes = []
      if (searchTerms !== '') {
        baseRecipes = await searchBaseRecipes(searchTerms.trim())
      } else {
        baseRecipes = await getBaseRecipes()
      }
      setRecipes(baseRecipes)
    } catch (err) {
      setError(err)
    } finally {
      setLoading(false)
    }
  }
  useEffect( () => {
      if (search == '') {
        setRecipes([])
        fetch("")
        return
      }
      if (debouncedSearchTerm) {
        fetch(debouncedSearchTerm)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedSearchTerm]);
  
  useEffect( () => {
    fetch("")
  }, [refresh])
  
  const searchOnChange = (query: string): void => {
    setSearch(query)
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
  
  return (
    <Container>
      <ActionBar
        error={error}
        action={
          (user.roles.includes('creator') || user.roles.includes('apprentice')) ? (
            <Box sx={{
              textAlign: 'right',
              marginLeft: 'auto'
            }}>
              <PageHeader.Actions>
                <PageHeader.ActionItem
                  onClick={() => {window.print()}}
                  variant="text">
                  Imprimer
                </PageHeader.ActionItem>
                <PageHeader.ActionItem
                  variant="contained"
                  onClick={() => navigate(CREATE_BASE_RECIPE)}>
                  Ajouter une recette
                </PageHeader.ActionItem>
              </PageHeader.Actions>
            </Box>
          ) : (
            <Box sx={{
              textAlign: 'right',
              marginLeft: 'auto'
            }}>
              <PageHeader.Actions><PageHeader.ActionItem
                onClick={() => {window.print()}}
                variant="text">
                Imprimer
              </PageHeader.ActionItem></PageHeader.Actions>
            </Box>
          )
        }
      />
      
      <Container className="mb-5 min-vh-100">
        {loading && (
          <div className="mt-2">
            <Row>
              <LoadingSkeleton />
            </Row>
          </div>
        )}
        {!error && !loading && (
          <div className="mt-2">
            <Row>
              <RecipeList
                base={true}
                view={'RecipeTable'}
                recipes={recipes}
                setError={setError}
                setRefresh={setRefresh}
              />
            </Row>
          </div>
        )}
      </Container>
    </Container>
  )
}
export default BaseRecipeListPage
