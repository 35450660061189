import React from 'react';
import {IRecipe} from '../../../api/IRecipe'
import {ImageType} from 'react-images-uploading'
import {Button, Box, Card, CardMedia} from "@mui/material";
import {CardOverlay} from "../../../components/RecipeCard/CardOverlay";
import Ribbon from "../../../components/Ribbon";
import {ChipCalorie} from "../ChipCalorie";

interface IImageUploadDisplayProps {
  imageList: ImageType,
  onImageUpload: () => void,
  dragProps: {
    onDrop: (e) => void;
    onDragEnter: (e) => void;
    onDragLeave: (e) => void;
    onDragOver: (e) => void;
  }
}
const ImageUploadDisplay = ({imageURL, recipe}: {imageURL: string, recipe: IRecipe}): (type: IImageUploadDisplayProps) => JSX.Element => {
  
  const inner = ({
    imageList,
    onImageUpload,
    dragProps
  }: IImageUploadDisplayProps): JSX.Element => {
    
    return (
    <Card sx={{display: 'inline-flex', position: 'relative'}}>
      {recipe.status === "draft" || !recipe.status && <Ribbon>Draft</Ribbon>}
        <CardMedia
          {...dragProps}
          id="card-img"
          component="img"
          image={imageList[0]?.dataURL || imageURL}
          alt={recipe?.image[0]?.caption}
        />
        <CardOverlay>
          <Button variant={"outlined"} onClick={onImageUpload}>Choisir une photo...</Button>
        </CardOverlay>
      {recipe?.nutrients && <ChipCalorie label={`${recipe?.nutrients?.find(m => m.name === 'énergie' && m.unit==='kcal').value} kcal`}/>}
    </Card>
    )
  }
  
  return ({imageList, onImageUpload, dragProps}) => inner({imageList, onImageUpload, dragProps})
}
export default ImageUploadDisplay;
