import React, {useEffect, useState} from 'react'
import {IBatch, INameQuantified} from '../../../api/IRecipe'
import IngredientList from '../../ingredient/list/IngredientList'
import {NotLoggedInWarning} from "../../../components/notloggedin/NotLoggedInWarning";

interface IStepThreeProps {
  batch: IBatch
}
const StepThree = ({batch}: IStepThreeProps):JSX.Element => {
  const [ingredients, setIngredients] = useState<INameQuantified[]>(batch? batch.ingredients: [])
  
  useEffect(() => {
    setIngredients(batch? batch.ingredients: [])
  }, [batch])
  return (
    <div className="mt-lg-3">
      <div className="text-muted">{"Voilà vous êtes prêt à démarrer, mais avant un petit shopping s'impose!"}</div>
        <IngredientList ingredients={ingredients} />
      <NotLoggedInWarning />
    </div>
  )
}
export default StepThree
