import {styled} from "@mui/material/styles";

export const Number = styled('div')(
  ({theme}) => `
  -webkit-box-align: center;
  align-items: center;
  background: ${theme.colors.primary.main};
  border-radius: 50%;
  color: #FFF;
  display: flex;
  float: left;
  font-size: 1.2rem;
  height: 20px;
  -webkit-box-pack: center;
  justify-content: center;
  margin: 0px 10px 0px 0px;
  padding: 15px;
  width: 20px;
`
);
