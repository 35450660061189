import React from 'react';
import {Box, Container, darken, lighten, Typography, useTheme} from "@mui/material";
import Section from "../Section";

export interface IMember {
  name: string
  role: string
  image: string
}

const Member = ({member}: {member: IMember}) => {
   return (
     <div className="col-lg-3 col-md-6 col-sm-6">
       <div className={`team__item set-bg team__member_${member.image}`}>
         <div className="team__item__text">
           <h6>{member.name}</h6>
           <span>{member.role}</span>
           <div className="team__item__social">
             <a href="#"><i className="fa fa-facebook"/></a>
             <a href="#"><i className="fa fa-twitter"/></a>
             <a href="#"><i className="fa fa-instagram"/></a>
             <a href="#"><i className="fa fa-youtube-play"/></a>
           </div>
         </div>
       </div>
     </div>
   )
  }

const Story = () => {
  const theme = useTheme()
  return (<Section
    title={"Quelle est l'histoire de Cuis!nons?"}
    subtitle={"Une initiative familiale"}
    containerStyle={{backgroundColor: `${theme.palette.mode === "light" ? 'whitesmoke' : darken(theme.palette.secondary.dark, 0.7)}`}}>
    <Box sx={{marginBottom: '2.5rem'}}>
      <Typography sx={{fontSize: '1rem'}}>
        {"Le projet Cuisinons est né de la rencontre de deux passions: la cuisine et le développement informatique."}
      </Typography>
      <Typography sx={{fontSize: '1rem'}}>
        {"Cédric, mon cousin est"} <strong>chef cuisinier</strong>{", récemment devenu "}<strong>professeur de
        cuisine</strong>;
        {" avec Fabrice, nous sommes un couple d'ingénieurs en informatique."}
      </Typography>
      <Typography sx={{fontSize: '1rem'}}>
        {"Tout a démarré en mode start-up dans le garage… Euh non, en fait, dans la cuisine. Et puis c'était pas un mode start-up mais plutôt une ambiance familiale. Sur fond de pandémie mondiale, impossible de voir ses amis ou sa famille, impossible de faire de belles randonnées en plein air car la limite des 1km fait barrière… Qu'à cela ne tienne, confinés mais toujours libres de pouvoir cuisiner! Nous utilisons l’outil informatique: visio-conférence, document partagé, chat et site web pour continuer à \"se voir\”."}
      </Typography>
      <Typography sx={{fontSize: '1rem'}}>
        {"L'idée de départ est simple:"} <strong>cuisiner le
        week-end</strong> {"pour préparer la semaine et surtout"}
        <strong>{"apprendre à cuisiner"}</strong> {"en même temps. Cuisiner en batch donc mais pas que… Ici on cuisine "}<strong>diététique</strong>, <strong>gastronomique</strong> et <strong>économique</strong>.
      </Typography>
      <Typography sx={{fontSize: '1rem'}}>
        {"Le projet cuisinong.org est né."}
      </Typography>
    </Box>
  </Section>);
}

const TeamCards = ({members}: { members: IMember[] }) => (
  <Section title={"Qui est derrière Cuis!nons?"} subtitle={"Une équipe de choc"}>
    <Box className={" m-auto h-100 align-items-center justify-content-end"}>
      <div className="row">
        {members.slice(0, 4).map((member: IMember, idx: number) => <Member key={idx} member={member}/>)}
      </div>
      <div className="row">
        {members.slice(4, 8).map((member: IMember, idx: number) => <Member key={idx} member={member}/>)}
      </div>
    </Box>
  </Section>
)
const TeamSection = ({members}: { members: IMember[] }): JSX.Element => (
  <>
    <Story />
    <TeamCards members={members}/>
  </>
);
export default TeamSection;
