import React, {useContext, useEffect, useState} from 'react'
import {IBatch} from '../../../api/IRecipe'
import {getBatches, deleteBatch} from '../../../api/api'
import ActionBar from "../../../components/ActionBar";
import {MY_BATCHES, RECIPE, START_BATCH} from "../../../routes";
import {useNavigate} from "react-router-dom";
import {
  Container,
  Box,
  Grid
} from "@mui/material";
import {PageHeader} from "../../../components/PageHeader";
import UserContext from "../../../hooks/UserContext";
import {IOption, MoreMenu} from "../../../components/MoreMenu";
import {useTheme} from "@mui/styles";
import {BatchCard} from "../components/BatchCard";
import {EmptyBatch} from "../components/EmptyBatch";

const MyBatchListPage = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true)
  const [batches, setBatches] = useState<IBatch[]>([])
  const [error, setError] = useState(null)
  const [refresh, setRefresh] = useState(0)
  const navigate = useNavigate();
  
  const deleteMyBatch = async (id: string) => {
    try {
      await deleteBatch(id)
      const idx = batches.findIndex(b=> b.id===id)
      setBatches([...batches.slice(0, idx), ...batches.slice(idx+1)])
      setRefresh(refresh + 1)
    } catch (e) {
      console.log(`delete failed for batch is ${id}`)
    }
  }
  useEffect( () => {
    const fetch = async() => {
      try {
        setIsLoading(true)
        const allBatches = await getBatches()
        setBatches(allBatches)
        setIsLoading(false)
      } catch (err) {
        setError(err)
      }
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
    fetch()
  }, [refresh]);
  
  const navigateToWizard = () => {navigate(START_BATCH)}
  if (isLoading) {
    return (<>Loading </>)
  }
  const {user} = useContext(UserContext)
  if(batches.length === 0 && !isLoading) {
    return (<>
      <Container>
        <ActionBar
          error={error}
          action={
            (user.roles.includes('creator') || user.roles.includes('apprentice')) ? (
              <div className={"ms-auto text-end"}>
                <PageHeader.Actions>
                  <PageHeader.ActionItem
                    variant="contained"
                    onClick={() => navigate(`${MY_BATCHES}/create`)}>
                    Ajouter un batch
                  </PageHeader.ActionItem>
                </PageHeader.Actions>
              </div>) : (
              <div className={"ms-auto text-end"}>
              </div>
            )
          }
        />
      </Container>
      <EmptyBatch />
    </>);
  }
  
  return (
    <Container sx={{marginBottom: '2rem'}}>
      <ActionBar
        error={error}
        action={
          (user.roles.includes('creator') || user.roles.includes('apprentice')) ? (
            <div className={"ms-auto text-end"}>
              <PageHeader.Actions>
                <PageHeader.ActionItem
                  variant="contained"
                  onClick={() => navigate(`${MY_BATCHES}/create`)}>
                  Ajouter un batch
                </PageHeader.ActionItem>
              </PageHeader.Actions>
            </div>) : (
            <div className={"ms-auto text-end"}>
            </div>
          )
        }
      />
      <Box marginTop={1} maxWidth={'lg'}>
        {!error && (
          <>
            <Grid container spacing={{xs: 1}} >
              {batches.map((batch, idx) => {
                const options: IOption[] = [
                  {
                    name: "Voir le détail",
                    action: () => navigate(`${MY_BATCHES}/${batch.id}`)
                  },
                  {
                    name: "Supprimer",
                    action: () => deleteMyBatch(batch.id)
                  }
                ]
                return (
                  <BatchCard key={idx} batch={batch} options={options} />
                )
              })}
            </Grid>
          </>
        )}
      </Box>
    </Container>)
}
export default MyBatchListPage
